import {combineSignalREvents} from "../redux-signalR";
import * as ActionTypes from "../actions/types"

const chatInitiated = {
  eventName: 'ChatInitiated',
  actionName: ActionTypes.SIGNAL_R_EVENT_CHAT_INITIATED,
};
const chatStarted = {
  eventName: 'ChatStarted',
  actionName: ActionTypes.SIGNAL_R_EVENT_CHAT_STARTED,
};

const chatEnded = {
  eventName: 'ChatEnded',
  actionName: ActionTypes.SIGNAL_R_EVENT_CHAT_ENDED,
};

const chatEndFeelingSent = {
  eventName: 'ChatEndFeelingSent',
  actionName: ActionTypes.SIGNAL_R_EVENT_CHAT_END_FEELING_SENT,
};

const messageSent = {
  eventName: 'MessageSent',
  actionName: ActionTypes.SIGNAL_R_EVENT_CHAT_NEW_MESSAGE,
};

const noteAdded = {
  eventName: 'NoteAdded',
  actionName: ActionTypes.SIGNAL_R_EVENT_NOTE_ADDED,
};

const noteUpdated = {
  eventName: 'NoteUpdated',
  actionName: ActionTypes.SIGNAL_R_EVENT_NOTE_UPDATED,
};

const noteDeleted = {
  eventName: 'NoteDeleted',
  actionName: ActionTypes.SIGNAL_R_EVENT_NOTE_DELETED,
};

const childConnected = {
  eventName: 'ChildConnected',
  actionName: ActionTypes.SIGNAL_R_EVENT_CHILD_CONNECTED,
};

const childDisconnected = {
  eventName: 'ChildDisconnected',
  actionName: ActionTypes.SIGNAL_R_EVENT_CHILD_DISCONNECTED,
};

const signalREventHandlers = combineSignalREvents(
  chatInitiated,chatStarted,chatEnded,
  chatEndFeelingSent,messageSent,
  noteAdded,noteUpdated,noteDeleted,
  childConnected, childDisconnected
  );

export default signalREventHandlers;
