import * as React from 'react';
import { connect } from 'react-redux';
import {sendMessage, unreadChatWasRead} from '../../actions';
import SelectedChat from "../../components/ChatSelected/selectedChat";

class SelectedChatHOC extends React.Component {

  componentDidMount() {
    // when opening a chat it should no longer be considered as unread
    if(this.props.selectedChatId){
      this.removeSelectedChat();
    }
  }

  componentDidUpdate(prevProps) {
    // when opening a chat it should no longer be considered as unread
    if(this.props.selectedChatId){
      this.removeSelectedChat();
    }
  }

  removeSelectedChat = () => {
    this.props.unreadChatWasRead({chatId: this.props.selectedChatId});
  };

  render() {
    return (
      <SelectedChat
        {...this.props}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    fetchingChat: state.chats.fetchingChat,
    selectedChatId: state.chats.selectedChatId,
    chats: state.chats.byId,
    allChatIdsOfCurrentChild: state.chats.allChatIdsOfCurrentChild,
    childUsers: state.childUsers.byId,
  }
};

const mapDispatchToProps = dispatch => ({
  sendMessage: (payload) => {
    dispatch(sendMessage(payload))
  },
  unreadChatWasRead: (payload) => {
    dispatch(unreadChatWasRead(payload))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectedChatHOC);
