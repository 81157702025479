const parseChats = (stateChildUsers, stateChildUsersIds,  data) =>{
    let newChildUsers = {};
    let childUsersIds = stateChildUsersIds.slice();

    data.forEach(item => {
        if (!item) return;
        newChildUsers = {
            ...newChildUsers,
            [item.createdBy.id]: {...newChildUsers[item.createdBy.id], ...{
                    id: item.createdBy.id,
                    age:item.createdBy.age,
                    displayName:item.createdBy.displayName,
                    ipAddress:item.createdBy.ipAddress,
                    applicationUserLogins: item.createdBy.applicationUserLogins,
                },
            },
        };
        if (!childUsersIds.includes(item.createdBy.id)) {
            childUsersIds.push(item.createdBy.id);
        }

    });

    return{
        newChildUsers,
        childUsersIds
    }
};

export default parseChats;
