import React from 'react';
import './ChatTimer.css';

import {Container} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Clock from '@material-ui/icons/AccessTime';

const ChatTimer = (props) => {
    const { timeRemaining } = props;
    return(
        <Container>
            <div className='chatTime'>
                <div className='chatTimeElapsed'>
                    <Clock className='coloredValue'/>
                    <Typography variant='h5' className='coloredValue'> {timeRemaining ? timeRemaining.minutes + ':' + timeRemaining.seconds : ''}</Typography>
                </div>
                <Typography variant='caption' className='chatTimeText' >
                    {props.helpText}
                </Typography>
            </div>
        </Container>
    )
};

export default ChatTimer;
