export const doesChatListContainsUnreadChat = (chatsListIds, unreadChatsIds) => {
    const found = chatsListIds
        .find(chatId => unreadChatsIds.includes(chatId));

    return !!found;
};

export const countTotalUnreadChatsOfChatList = (chatsListIds, unreadChatsIds) => {
    const total = chatsListIds.reduce((acc, current) => {
        if(unreadChatsIds.includes(current)){
            return acc + 1;
        } else {
            return acc;
        }
    }, 0);

    return total;
};