import React, {useEffect, useState} from "react";
import { makeStyles} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import MySnackbarContentWrapper from "../SnackbarContentWrapper/snackbarContentWrapper";
import Snackbar from "@material-ui/core/Snackbar";
import {colors} from "../../helpers/colors";
import {DropzoneArea} from "material-ui-dropzone";
import {convertHtmlToDraftState, readFileAsBase64Async, stripHtml} from "../../helpers/functions";
import {TERMS_OF_USE_TITLE, SOS_LINE_TITLE} from "../../helpers/constants";
import FormHelperText from "@material-ui/core/FormHelperText";
import EditorComponent from "../EditorComponent/EditorComponent";
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import FormLabel from "@material-ui/core/FormLabel";

const useStyles = makeStyles(theme => ({
    form: {
        display: "flex",
        flexDirection: "column",
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {},
    formControlSelect: {
        margin: theme.spacing(2),
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    buttonPrimary: {
        margin: theme.spacing(1),
        backgroundColor: colors.BLUE,
        "&:hover": {
            backgroundColor: colors.BLUE,
        },
        zIndex: 11
    },
    buttonSecondary: {
        margin: theme.spacing(1),
        backgroundColor: colors.ORANGE,
        "&:hover": {
            backgroundColor: colors.ORANGE,
        },
        zIndex: 11
    }
}));

const PageDetailsForm = (props) => {
    const {
        createPage,
        loading,
        updatePage,
        initialDetails,
        isEditing,
        onCancel,
        responseMessage,
        onResponseMessageDismiss,
        pageTypes
    } = props;
    const classes = useStyles();
    const [details, setDetails] = useState({
        id: "",
        title: "",
        body: EditorState.createEmpty(),
        type: "",
        url: "",
        imageEncodedFile: ""
    });
    const [hasError, setHasError] = useState({});

    useEffect(()=>{
        setDetails({
            ...details,
            id:  initialDetails && initialDetails.id ? initialDetails.id : "",
            title: initialDetails && initialDetails.title ? initialDetails.title : "",
            body: initialDetails && initialDetails.body ?
                convertHtmlToDraftState(initialDetails.body) : EditorState.createEmpty(),
            type: initialDetails && initialDetails.type ? initialDetails.type : "",
            url: initialDetails && initialDetails.url ? initialDetails.url : "",
            imageEncodedFile: initialDetails && initialDetails.imageEncodedFile ? initialDetails.imageEncodedFile : "",
        })
    }, [initialDetails]);

    const onSubmit = (evt) => {
        evt.preventDefault();
        const htmlBody = draftToHtml(convertToRaw(details.body.getCurrentContent()));
        const rawText = stripHtml(htmlBody);
        if(!rawText || !rawText.trim()){
            setHasError({...hasError, ['body']: true});
            return;
        }

        if(!details.type){
            setHasError({...hasError, ['type']: true});
            return;
        }

        if(isEditing){
            const sendBody = {
                title: details.title,
                body: htmlBody,
                type: details.type,
                url: details.url ? details.url : null,
                imageEncodedFile: details.imageEncodedFile ? details.imageEncodedFile : null,
            };
            updatePage({sendBody, pageId: details.id});
        } else {
            const sendBody = {
                title: details.title,
                body: htmlBody,
                type: details.type,
                url: details.url ? details.url : null,
                imageEncodedFile: details.imageEncodedFile ? details.imageEncodedFile : null,
            };
            createPage({sendBody});
        }

    };

    return (
        <form className={classes.form} onSubmit={onSubmit} id={"pageDetails"}>
            <TextField
                disabled={(
                    details.title
                    && (
                        details.title.trim().toLowerCase() === TERMS_OF_USE_TITLE.trim().toLowerCase()
                        || details.title.trim().toLowerCase() === SOS_LINE_TITLE.trim().toLowerCase()
                    )
                )}
                required
                label="Title"
                fullWidth
                placeholder="Title"
                className={classes.textField}
                margin="normal"
                value={details.title}
                onChange={(evt) => setDetails({...details, "title": evt.target.value})}
            />
            <FormControl
                error={!!hasError['body']}
                fullwidth="true"
                margin={"normal"}
                required
            >
                <FormLabel>Body</FormLabel>
                <EditorComponent
                    editorState={details.body}
                    onEditorStateChange={(editorState) => {
                        setHasError({});
                        setDetails({...details, body: editorState})
                    }}
                />
                {hasError['body'] && <FormHelperText>Body is required!</FormHelperText>}
            </FormControl>
            <TextField
                label="Url"
                fullWidth
                placeholder="Url"
                className={classes.textField}
                margin="normal"
                value={details.url}
                onChange={(evt) => setDetails({...details, "url": evt.target.value})}
            />
            <FormControl
                error={!!hasError['type']}
                fullwidth="true"
                margin={"normal"}
                required
            >
                <InputLabel htmlFor="select-multiple-chip">Type</InputLabel>
                <Select
                    required
                    value={details.type}
                    onChange={(evt) =>{
                        setHasError({});
                        setDetails({...details, type: evt.target.value})
                    }}
                >
                    {pageTypes && pageTypes.map(type => (
                        <MenuItem key={type.code} value={type.code}>
                            {type.name}
                        </MenuItem>

                    ))}
                </Select>
                {hasError['type'] && <FormHelperText>Type is required!</FormHelperText>}
            </FormControl>
            <DropzoneArea
                filesLimit={1}
                acceptedFiles={['image/*']}
                maxFileSize={10000000}
                showAlerts={false}
                showPreviewsInDropzone={true}
                initialFiles={details.imageEncodedFile ? [details.imageEncodedFile] : []
                }
                onChange={async files => {
                    if(!files || files.length === 0) {
                        setDetails({...details, imageEncodedFile: ""});
                        return;
                    }

                    const encodedFile = await readFileAsBase64Async(files[0]);
                    setDetails({...details, imageEncodedFile: encodedFile})
                }}
            />
            <div>
                <Button
                    form={"pageDetails"}
                    color="primary"
                    type={"submit"}
                    variant="contained"
                    className={classes.buttonPrimary}
                >
                    {isEditing? "Save Changes" : "Create"}
                </Button>
                <Button onClick={onCancel} color="secondary" variant="contained" className={classes.buttonSecondary}>
                    {isEditing? "Go back" : "Cancel"}
                </Button>
                {loading && (
                    <CircularProgress className='spinner' size={30}/>
                )}
            </div>
            {responseMessage && (
                <Snackbar
                    open={true}
                    autoHideDuration={6000}
                    onClose={onResponseMessageDismiss}
                >
                    <MySnackbarContentWrapper
                        onClose={onResponseMessageDismiss}
                        variant="info"
                        message={responseMessage}
                    />
                </Snackbar>
            )}
        </form>
    )
};

export default PageDetailsForm;
