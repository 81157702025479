import React from "react"
import {makeStyles} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import AdminContentRouter from "../../../routers/adminContentRouter";

const useStyles = makeStyles(theme => ({
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(12),
        paddingBottom: theme.spacing(12),
        //paddingRight: DRAWER_WIDTH + theme.spacing(4),
    }
}));


const Content = () => {
    const classes = useStyles();
    return (
        <main className={classes.content}>
            <Container maxWidth="xl" className={classes.container}>
                <AdminContentRouter/>
            </Container>
        </main>

    )
};


export default Content