import React, {Component} from "react";
import "./Statistics.css";
import SectionTitle from "../SectionTitle/SectionTitle";
import Container from "@material-ui/core/Container";
import {Grid, Typography} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import 'chartjs-plugin-datalabels';
import PieChart from "./Charts/PieChart/PieChart";
import HorizontalBarChart from "./Charts/HorizontalBarChart/HorizontalBarChart";
import AvgTime from "./AvgTime/AvgTime";

class Statistics extends Component{
    render() {
        const stats = this.props.stats;
        let statisticsSection = null;
        if(stats) {
            //ages
            const agesStats = stats.ages;
            let ages = [];
            if (agesStats) {
                for (let key in agesStats) {
                    ages.push(agesStats[key]);
                }
            }
            //avgChatTime
            const avgChatTime = stats.averageChatTime;

            //avgWaitTime
            const avgWaitTime = stats.averageWaitTime;


            statisticsSection = (
                <React.Fragment>
                    <Grid container spacing={0} direction="row" justify="center" alignItems="center" >
                        <Grid item sm lg={6}>
                            <Typography variant='subtitle2' component='div' className='statsTitle'>
                                {this.props.loginsCharts.title}
                            </Typography>
                            <PieChart data={this.props.loginsCharts.data} labels={this.props.loginsCharts.dataLabels}/>
                        </Grid>

                        {agesStats && ages.length === 8 ? // 8 ages --> (11-,12,13,14,15,16,17,18+)
                            <Grid item sm lg={6} >
                                <Typography variant='subtitle2' component='div' className='statsTitle'>
                                    {this.props.agesCharts.title}
                                </Typography>
                                <HorizontalBarChart
                                    data={ages}
                                    labels={this.props.agesCharts.dataLabels}
                                    datasetLabel={this.props.agesCharts.datasetLabel}
                                    bgColor={this.props.agesCharts.bgColor}
                                    borderColor={this.props.agesCharts.borderColor}
                                />
                            </Grid>
                            :   <Typography variant="h6" component="h6" align='center'>There is no data about ages.</Typography>}
                    </Grid>

                    {avgChatTime || avgWaitTime ?
                        <Grid container spacing={2} direction="row" justify="center" alignItems="center" className='secondRow'>
                            {avgChatTime ?
                                <Grid item sm lg={6}>
                                    <AvgTime class="chat" title='Avg Chat Time' time={avgChatTime} formatTime='HH:mm' previousTime='12:53'/>

                                </Grid>
                                :null}
                            {avgWaitTime ?
                                <Grid item sm lg={6}>
                                    <AvgTime class="wait" title='Avg Waiting Time' time={avgWaitTime} formatTime='HH:mm' previousTime='07:42'/>
                                </Grid>
                                :null}
                        </Grid>
                        :   <Typography variant="h6" component="h6" align='center'>There is no data about average time.</Typography>}
                </React.Fragment>
            );
        }else{
            if(this.props.loadingStats){
                statisticsSection = <LinearProgress className='spinner'/>
            }else{
                statisticsSection =
                    (<React.Fragment>
                            <Typography variant="h4" component="h4" align='center'>Something went wrong.</Typography>
                            <Typography variant="h5" component="h5" align='center'>There is no statistics.</Typography>
                        </React.Fragment>
                    );
            }
        }
        return (
            <Container>

                <div className='statistics'>
                    <SectionTitle capitalized centered title={this.props.title}/>
                    {statisticsSection}
                </div>

            </Container>
        )
    }
}

export default Statistics;
