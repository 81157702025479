import {headersWithTokenJson} from './headers';
import xs from 'xstream';
import {BASE_URL} from "../helpers/constants";
import * as actions from "../actions";
import * as actionTypes from "../actions/types";
import sampleCombine from "xstream/extra/sampleCombine";
import delay from "xstream/extra/delay";

export function requestReorderPage(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.user.token);
    const request$ = sources.ACTION
        .filter(action => action.type === actionTypes.REORDER_PAGE)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) =>  ({
                url: BASE_URL + `Pages/${action.payload.pageId}/Reorder`,
                category: 'requestReorderPage',
                method: 'PATCH',
                send: action.payload.sendBody,
                headers: headersWithTokenJson(token),
                withCredentials: true
            })
        );

    let httpResponse$ = sources.HTTP
        .select('requestReorderPage')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 204)
        .map(response => actions.reorderPageSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    };
}

export function requestReorderPageFailed(sources) {
    const response$ = sources.HTTP
        .select('requestReorderPage')
        .map(response =>
            response.replaceError(err => xs.of(err)))
        .flatten()
        .filter(response => response.status !== 204);

    const action$ = xs.combine(response$)
        .map(response => actions.reorderPageFailed(response));

    return {
        ACTION: action$
    };
}

export function requestDeletePage(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.user.token);
    const request$ = sources.ACTION
        .filter(action => action.type === actionTypes.DELETE_PAGE)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) =>  ({
                url: BASE_URL + `Pages/${action.payload.pageId}`,
                category: 'requestDeletePage',
                method: 'DELETE',
                send: action.payload.sendBody,
                headers: headersWithTokenJson(token),
                withCredentials: true
            })
        );

    let httpResponse$ = sources.HTTP
        .select('requestDeletePage')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 204)
        .map(response => actions.deletePageSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    };
}

export function requestDeletePageFailed(sources) {
    const response$ = sources.HTTP
        .select('requestDeletePage')
        .map(response =>
            response.replaceError(err => xs.of(err)))
        .flatten()
        .filter(response => response.status !== 204);

    const action$ = xs.combine(response$)
        .map(response => actions.deletePageFailed(response));

    return {
        ACTION: action$
    };
}

export function requestUpdatePage(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.user.token);
    const request$ = sources.ACTION
        .filter(action => action.type === actionTypes.UPDATE_PAGE)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) =>  ({
                url: BASE_URL + `Pages/${action.payload.pageId}`,
                category: 'requestUpdatePage',
                method: 'PATCH',
                send: action.payload.sendBody,
                headers: headersWithTokenJson(token),
                withCredentials: true
            })
        );

    let httpResponse$ = sources.HTTP
        .select('requestUpdatePage')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 204)
        .map(response => actions.updatePageSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    };
}

export function requestUpdatePageFailed(sources) {
    const response$ = sources.HTTP
        .select('requestUpdatePage')
        .map(response =>
            response.replaceError(err => xs.of(err)))
        .flatten()
        .filter(response => response.status !== 204);

    const action$ = xs.combine(response$)
        .map(response => actions.updatePageFailed(response));

    return {
        ACTION: action$
    };
}

export function requestCreatePage(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.user.token);
    const request$ = sources.ACTION
        .filter(action => action.type === actionTypes.CREATE_PAGE)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) =>  ({
                url: BASE_URL + `Pages`,
                category: 'requestCreatePage',
                method: 'POST',
                send: action.payload.sendBody,
                headers: headersWithTokenJson(token),
                withCredentials: true
            })
        );

    let httpResponse$ = sources.HTTP
        .select('requestCreatePage')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 201)
        .map(response => actions.createPageSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    };
}

export function requestCreatePageFailed(sources) {
    const response$ = sources.HTTP
        .select('requestCreatePage')
        .map(response =>
            response.replaceError(err => xs.of(err)))
        .flatten()
        .filter(response => response.status !== 201);

    const action$ = xs.combine(response$)
        .map(response => actions.createPageFailed(response));

    return {
        ACTION: action$
    };
}

export function requestPage(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.user.token);
    const request$ = sources.ACTION
        .filter(action => action.type === actionTypes.FETCH_PAGE)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) =>  ({
                url: BASE_URL + `Pages/${action.payload.pageId}`,
                category: 'requestPage',
                method: 'GET',
                headers: headersWithTokenJson(token),
                withCredentials: true
            })
        );

    let httpResponse$ = sources.HTTP
        .select('requestPage')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.fetchPageSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    };
}

export function requestPageFailed(sources) {
    const response$ = sources.HTTP
        .select('requestPage')
        .map(response =>
            response.replaceError(err => xs.of(err)))
        .flatten()
        .filter(response => response.status !== 200);

    const action$ = xs.combine(response$)
        .map(response => actions.fetchPageFailed(response));

    return {
        ACTION: action$
    };
}

export function requestPages(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.user.token);
    const request$ = sources.ACTION
        .filter(action => action.type === actionTypes.FETCH_PAGES)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) =>  ({
                url: BASE_URL + `Pages` + (
                    action.payload ?
                        "?"
                        + `page=${action.payload.page + 1}`
                        + "&"
                        + `limit=${action.payload.limit}`
                        + "&"
                        + `sort=${action.payload.sort}`
                        :
                        ""
                ),
                category: 'requestPages',
                method: 'GET',
                headers: headersWithTokenJson(token),
                withCredentials: true
            })
        );

    let httpResponse$ = sources.HTTP
        .select('requestPages')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.fetchPagesSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    };
}

export function requestPagesFailed(sources) {
    const response$ = sources.HTTP
        .select('requestPages')
        .map(response =>
            response.replaceError(err => xs.of(err)))
        .flatten()
        .filter(response => response.status !== 200);

    const action$ = xs.combine(response$)
        .map(response => actions.fetchPagesFailed(response));

    return {
        ACTION: action$
    };
}


