export const loginHeader = () => ({
    accept: 'application/json',
    'Content-Type': 'application/json',
});

export const headersWithTokenJson = (token) => {
    return ({
        accept: 'application/json',
        "CONTENT-TYPE": "application/json",
        'Authorization': `Bearer ${token}`,
    });
};
