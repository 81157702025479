import React, {Component} from 'react';
import './ChatSummaryList.css';
import NavigationItem from "../NavigationItem/NavigationItem";
import ChatSummary from "../ChatSummary/ChatSummary";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Typography} from "@material-ui/core";
import _ from 'lodash';

class ChatSummaryList extends Component{
    render() {
        const {unreadChatsIds} = this.props;
        let chats = null;
        if(this.props.chats && this.props.chats.length > 0) {
            chats = _.orderBy(this.props.chats, 'createdDate', 'desc').map((chat, index) => (
                <div
                    className={[
                        'chatSummaryNav',
                        (this.props.selectedChatId === chat.id) ? 'clicked' : null
                    ].join(' ')}
                    key={chat.id} onClick={() => this.props.clicked(chat.id)}>
                    <NavigationItem link={"/chat/" + chat.id} exact >
                        <ChatSummary
                            {...this.props}
                            chatId={chat.id}
                            displayName={chat.childId && this.props.childUsers[chat.childId] && this.props.childUsers[chat.childId].displayName}
                            feeling={chat.startFeeling}
                            subject={chat.subject}
                            startTimestamp={chat.startTimestamp}
                            unread={unreadChatsIds.includes(chat.id)}
                        />
                    </NavigationItem>
                </div>

            ));
        }else{
            if(this.props.loadingActiveChats || this.props.loadingHistoryChats || this.props.loadingPendingChats){
                chats = <div className='centeredContent'><CircularProgress className='spinner'/></div>
            }else{
                chats = (<Typography variant="h5" component="div" align='center' className='centeredContent'>There is no data about chats.</Typography>);
            }
        }
        return(
            <div className={this.props.class}>
                {chats}
            </div>
        )
    }
}

export default ChatSummaryList;
