import {headersWithTokenJson} from './headers';
import xs from 'xstream';
import {BASE_URL} from "../helpers/constants";
import * as actions from "../actions";
import sampleCombine from 'xstream/extra/sampleCombine';
import * as actionTypes from "../actions/types";

export function addNote(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.user.token);
    const selectedChatId$ = state$.map(state => state.chats.selectedChatId);
    const request$ = sources.ACTION
        .filter(action => action.type === actionTypes.ADD_NEW_CHAT_NOTE)
        .compose(sampleCombine(token$, selectedChatId$))
        .map(([action, token, selectedChatId]) =>  ({
                url: BASE_URL + 'notes',
                category: 'addNote',
                method: 'POST',
                send: {
                    chatId: selectedChatId,
                    text: action.payload,
                },
                headers: headersWithTokenJson(token),
                withCredentials: true
            })
        );

    let httpResponse$ = sources.HTTP
        .select('addNote')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 201)
        .map(response => actions.addNewChatNoteSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    };
}

export function addNoteFailure(sources) {
    const response$ = sources.HTTP
        .select('addNote')
        .map(response =>
            response.replaceError(err => xs.of(err)))
        .flatten()
        .filter(response => response.status !== 201);

    const action$ = xs.combine(response$)
        .map(response => actions.addNewChatNoteFailure(response));

    return {
        ACTION: action$
    };
}


export function mapSignalRNoteAddedToFetchChatNotes(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === actionTypes.SIGNAL_R_EVENT_NOTE_ADDED)
    .map(action => actions.fetchChatNotes(action.payload));
  return {
    ACTION: action$
  };
}

export function fetchChatNotes(sources) {
  const state$ = sources.STATE;
  const token$ = state$.map(state => state.user.token);
  const request$ = sources.ACTION
    .filter(action => action.type === actionTypes.FETCH_CHAT_NOTES )
    .compose(sampleCombine(token$))
    .map(([action, token]) => ({
      url: `${BASE_URL}notes?ChatId=${action.payload.ChatId}`,
      category: 'fetchChatNotes',
      method: 'GET',
      headers: headersWithTokenJson(token),
      withCredentials: true
    }));

  let httpResponse$ = sources.HTTP
    .select('fetchChatNotes')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200)
    .map(response => actions.storeChatNotes(response));

  return {
    ACTION: httpResponse$,
    HTTP: request$
  };
}

export function fetchChatNotesFailed(sources) {
  const response$ = sources.HTTP
    .select('fetchChatNotes')
    .map(response =>
      response.replaceError(err => xs.of(err)))
    .flatten()
    .filter(response => response.status !== 200);

  const action$ = xs.combine(response$)
    .map(response => actions.fetchChatNotesFailure(response));

  return {
    ACTION: action$
  };
}

