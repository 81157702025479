import React, { Component } from 'react';
import {connect} from "react-redux";
import Statistics from "../../components/Statistics/Statistics";

class StatisticsHOC extends Component{

    render() {
        return (
            <Statistics
                {...this.props}
                loginsCharts={{title: 'Logins',
                    data: [this.props.stats.returningChats,this.props.stats.newChats],
                    dataLabels: ['Returning','New']}}
                agesCharts={{title: 'Ages',
                    dataLabels :['11-', '12', '13', '14', '15', '16', '17', '18+'],
                    datasetLabel:'Age',
                    bgColor:'#6197e9',
                    borderColor:'#6164ff'}}
            />

        );
    }
}

const mapStateToProps = state => {
    return {
        stats: state.statistics.stats,
        loadingStats: state.statistics.loading
    }
};

export default connect(mapStateToProps)(StatisticsHOC);
