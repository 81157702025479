import React from 'react';
import './chatScreen.css';
import ChatContainer from './chatContainer';
import AutogrowingTextInput from './autogrowingTextInput';
import Warning from "../Warning/warning";
// import Dropzone from "react-dropzone";
import {Send} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";

class SelectedChat extends React.Component {
  constructor(props) {
    super(props);
    //input field related state
    this.state = {
      value: '',
      rows: 1,
      minRows: 1,
      maxRows: 2,
    };
  }
  updateState = (payload) => {
    this.setState(payload)
  };
  sendMessage = (e) => {
    if (((e.keyCode === 13 && !e.shiftKey) || (!e.keyCode && e.button === true)) && this.state.value.trim().length > 0) {
      this.props.sendMessage({body: this.state.value.trimEnd(), chatId: this.props.selectedChatId});
      // I want to clear the textarea around here
      this.setState({value:""})
    }
  };
  render() {
    const { chats, allChatIdsOfCurrentChild, selectedChatId, childUsers } = this.props;
    const userId = chats[selectedChatId] && chats[selectedChatId].childId;
    return (
      <div className="chat">
        { userId && childUsers[userId] && childUsers[userId].connected === false &&
        <Warning text={`Το παιδί ${childUsers[userId].displayName} αυτή τη στιγμή είναι αποσυνδεδεμένο.`} />
        }
        {
          allChatIdsOfCurrentChild && allChatIdsOfCurrentChild.map(chatId =>(
            chats[chatId] &&
            chats[chatId].messages &&
            <ChatContainer key={chatId} messages={chats[chatId].messages || []}/>
          ))
        }

        <div className="writeMessageContainer">
          <AutogrowingTextInput
            {...this.state}
            updateState={this.updateState}
            sendMessage={this.sendMessage}
            selectedChatId={selectedChatId}
            disabled={!chats[selectedChatId] || !(chats[selectedChatId].endTimestamp == null && chats[selectedChatId].startTimestamp != null)}
          />
          {/*<Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)}>*/}
          {/*  {({getRootProps, getInputProps}) => (*/}
          {/*    <section>*/}
          {/*      <div {...getRootProps()}>*/}
          {/*        <input {...getInputProps()} />*/}
          {/*        <AttachFile />*/}
          {/*      </div>*/}
          {/*    </section>*/}
          {/*  )}*/}
          {/*</Dropzone>*/}
          <IconButton color="primary" aria-label="close" onClick={() => this.sendMessage({button: true})}>
            <Send />
          </IconButton>
        </div>
      </div>
    );
  }
};

export default SelectedChat;
