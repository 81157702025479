import React from 'react';
import './Header.css';
import AppBar from "@material-ui/core/AppBar";
import InfoBlock from "../InfoBlock/InfoBlock";
import Status from "../Status/Status";
import AgentMenu from "./agentMenu/agentMenu";

const Header = props => {
    const {stats, info, onOnlineToggle} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenu=(event)=> {
        setAnchorEl(event.currentTarget);
    };

    const handleClose=()=> {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            {stats ?
                <AppBar position="sticky" className='headerAgent'>
                    <InfoBlock styleNumber='h4' number={stats.onHold} text='Chat σε αναμονη σημερα'/>
                    <InfoBlock styleNumber='h4' number={stats.completedToday} text='Chat ολοκληρωθηκαν σημερα'/>
                    <InfoBlock styleNumber='h4' number={stats.completedYesterday} text='Chat ολοκληρωθηκαν χθες'/>
                    <InfoBlock styleNumber='h4' number={stats.completedTotal} text='Chat ολοκληρωθηκαν συνολικα'/>
                    <Status
                        agentName={`${info.firstname} ${info.lastname}`}
                        onOnlineToggle={onOnlineToggle}
                        isAvailable={info.isAvailable}
                    />
                    <AgentMenu
                        handleMenu={handleMenu}
                        handleClose={handleClose}
                        anchorEl={anchorEl}
                        open={open}
                    />
                </AppBar>
                :null}
        </React.Fragment>
    );
}

export default Header;
