import React, {Component} from 'react';
import ChatNotes from "../../components/ChatNotes/ChatNotes";
import {connect} from "react-redux";
import * as actions from '../../actions';

class ChatNotesHOC extends Component{
    render() {
        return(
            <ChatNotes
                {...this.props}
                title='Σημειώσεις'
                textAreaTooltipText='Σημειώσεις σχετικά με το άτομο ή τη συνομιλία. Δεν είναι εμφανή στο χρήστη αυτές οι σημειώσεις'
                textAreaPlaceholder='Εισάγετε μια νέα σημείωση'
                selectedChatId={this.props.selectedChatId}
                addNewNote={this.props.addNewNote}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        selectedChatId: state.chats.selectedChatId,
        chats: state.chats.byId,
        allChatIdsOfCurrentChild: state.chats.allChatIdsOfCurrentChild,
        addNewNoteStatus: state.chats.addNewNoteStatus
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addNewNote: (payload) => dispatch(actions.addNewChatNote(payload)),
        clearAddNewNoteStatus: (payload) => dispatch(actions.clearAddNewChatNoteStatus(payload))
    }
};
export default connect(mapStateToProps,mapDispatchToProps)(ChatNotesHOC);