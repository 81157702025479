import React from "react"
import Paper from "@material-ui/core/Paper";
import {makeStyles, useMediaQuery, useTheme} from "@material-ui/core";
import TableList from "./TableList";
import AccordionList from "./AccordionList";
import TablePagination from "@material-ui/core/TablePagination";
import {ROWS_PER_PAGE_OPTIONS} from "../../helpers/constants";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from '@material-ui/core/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    title: {
        flex: '0 0 auto',
    },
    searchField: {
    },
    toolbar: {
        display: "flex",
        justifyContent: "space-between"
    }
}));

const CustomTable = (props) => {
    const {
        totalRecords,
        paginated,
        page,
        rowsPerPage,
        onChangePage,
        onChangeRowsPerPage,
        title,
        filterable,
        searchFieldValue,
        onSearchFieldChange,
    } = props;
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Paper className={classes.root}>
            <Toolbar className={classes.toolbar}>
                {title && (
                    <div className={classes.title}>
                        <Typography variant="h6" >{title}</Typography>
                    </div>
                )}
                {filterable && (
                    <TextField
                        className={classes.searchField}
                        placeholder={"search"}
                        value={searchFieldValue}
                        onChange={onSearchFieldChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            </Toolbar>
            {matches?
                <AccordionList {...props}/>
                :
                <TableList
                    {...props}
                />
            }
            {paginated && (
                <TablePagination
                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                    component="div"
                    count={totalRecords}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{'aria-label': 'previous page',}}
                    nextIconButtonProps={{'aria-label': 'next page',}}
                    onChangePage={onChangePage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                />
            )}
        </Paper>
    )
};

CustomTable.propTypes = {
    /** Boolean indicating that the table rows are selectable */
    selectable: PropTypes.bool,
    /** Array with the selected table rows indexes */
    selected: PropTypes.array,
    /** Callback invoked on select all rows */
    onSelectAllClick: PropTypes.func,
    /** Callback invoked on select a single table row. It will be invoked with the selected table row index */
    onSelectRowClick: PropTypes.func,
    /** The sort order enum */
    order: PropTypes.oneOf(["asc", "desc"]),
    /** The column id to sort by */
    orderBy: PropTypes.string,
    /** Callback invoked on sort change. Will be invoked with the column id passed as first argument */
    onSort: PropTypes.func,
    /** boolean indicating that the table is sortable */
    sortable: PropTypes.bool,
    /** boolean indicating that the table is searchable */
    filterable: PropTypes.bool,
    /** The controlled searchField value */
    searchFieldValue: PropTypes.string,
    /** The  callback invoked on searchFieldValue input change*/
    onSearchFieldChange: PropTypes.func,
    /** The data that should be displayed. Can by an array of objects of any type and shape */
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    /** The columns of the list - table. The propertyName is used to match the desired property of the data objects.
     * eg: the data object {email : "user@test.io"} will be matched by the column: {propertyName: "email", header:
     * "Email!"}. If you wish to access nested properties from the data you should specify them again in the
     * propertyName separated with a dot "." eg: data: {user: {email : "user@test.io"}}, then the property name will be
     * propertyName: "user.email". The id property of is used for sorting. So it must match the correct orderBy key.
     * The formatter is a callback used to format the data as you wish. It is invoked with the datum. For example
     * formatting timestamp dates.*/
    columns: PropTypes.arrayOf(PropTypes.shape({
        propertyName: PropTypes.string.isRequired,
        header: PropTypes.string.isRequired,
        id: PropTypes.string,
        formatter: PropTypes.func,
    })).isRequired,
    /** The current page of the pager */
    page: PropTypes.number,
    /** Callback invoked on page change */
    onChangePage: PropTypes.func,
    /** Callback invoked on rows per page change */
    onChangeRowsPerPage: PropTypes.func,
    /** Total rows per page */
    rowsPerPage: PropTypes.number,
    /** The total records */
    totalRecords: PropTypes.number,
    /** The table title */
    title: PropTypes.string,
};

export default CustomTable