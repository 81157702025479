const IMAGES = {
    HAPPY: require('./feelings/happy.svg'),
    GREAT: require('./feelings/great.svg'),
    GOOD: require('./feelings/good.svg'),
    VERYEXCITED: require('./feelings/veryexcited.svg'),
    SAD: require('./feelings/sad.svg'),
    ENERGETIC: require('./feelings/energetic.svg'),
    DONTKNOW: require('./feelings/dontknow.svg'),
    ALONE: require('./feelings/alone.svg'),
    DISAPPOINTED: require('./feelings/dissapointd.svg'),
    ANNOYED: require('./feelings/annoyed.svg'),
    ANGRY: require('./feelings/angry.svg'),
    SCARED: require('./feelings/scared.svg'),
    WORRIED: require('./feelings/worried.svg'),
    ANXIOUS: require('./feelings/anxious.svg'),
    RELIEVED: require('./feelings/relieved.svg'),
    TIRED: require('./feelings/tired.svg'),
    CONFUSED: require('./feelings/confused.svg')
};

export default IMAGES;