import React from 'react';

import RightInfoStats from "../../components/RightInfoStats/RightInfoStats";

const InfoHOC = (props) => {
  return (
      <RightInfoStats title='Agent Stats'/>
  )
};

export default InfoHOC;