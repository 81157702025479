import React from 'react';
import  './CloseButton.css';

import NavigationItem from "../NavigationItem/NavigationItem";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';

const CloseButton = (props) => {
    return(
        <div className='activeChatClose '>
            <NavigationItem link={props.link}>
                <IconButton color="secondary" aria-label="close" onClick={props.chatSelectedClosed}>
                    <CloseIcon />
                </IconButton>
            </NavigationItem>
        </div>
    )
};

export default CloseButton;