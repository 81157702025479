import React from 'react';
import {Route, Switch} from "react-router";
import SelectedChatHOC from "../containers/SelectedChatHOC/SelectedChatHOC";
import StatisticsHOC from "../containers/StatisticsHOC/StatisticsHOC";
import ApplicationUsersHOC from "../containers/ApplicationUsersHOC/ApplicationUsersHOC";
import ApplicationUserDetailsHOC from "../containers/ApplicationUserDetailsHOC/ApplicationUserDetailsHOC";
import ChatEndedSummaryPrintHOC from "../containers/ChatEndedSummaryPrintHOC/ChatEndedSummaryPrintHOC";

const ContentRouter = (props) => {

    return (
        <Switch>
            <Route exact path='/users/:id' component={ApplicationUserDetailsHOC}/>
            <Route path='/users'  component={ApplicationUsersHOC}/>
            <Route path='/chat/:chatId/end' component={ChatEndedSummaryPrintHOC}/>
            <Route path='/chat/:chatId' component={SelectedChatHOC}/>
            <Route path="/"  render={() => <StatisticsHOC title={"Stats"}/>}/>
        </Switch>
    );

};

export default ContentRouter;
