import React, { Component } from 'react';
import * as actions from "../../actions";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import ChatsTabs from "../../components/ChatsTabs/ChatsTabs";
import * as constants from "../../helpers/constants";

class ChatsTabsHOC extends Component{
    componentDidMount() {
        this.props.fetchPendingChats();
    }

    actionDependingToSelectedTab = (value) => {
        switch (value) {
            case constants.PENDING_TAB:
                this.props.fetchPendingChats();
                break;
            case constants.ACTIVE_TAB:
                this.props.fetchActiveChats();
                break;
            case constants.HISTORY_TAB:
                this.props.fetchHistoryChats({
                    sendBody: {
                        page: 1
                    }});
                break;
            default:
                break;
        }
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.selectedTab !== prevProps.selectedTab){
            this.actionDependingToSelectedTab(this.props.selectedTab)
        }
    }

    handleChange = (event,newValue) => {
        event.preventDefault();
        this.actionDependingToSelectedTab(newValue);
        this.props.setActiveTab(newValue)
    };

    handleScroll = (e) => {
      if (this.props.historyTotalPages <= this.props.historyPage) return;
        if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
          this.props.fetchHistoryChats({
          sendBody: {
              page: this.props.historyPage + 1
          }});
      }
    };

    render() {
        return (
            <ChatsTabs
                {...this.props}
                handleChange= {this.handleChange}
                handleScroll = {this.handleScroll}
                selectedTab = {this.props.selectedTab}
                toggleChatStatisticsVisibility = {this.props.toggleChatStatisticsVisibility}
                selectedChatId={this.props.selectedChatId}
                showStats={this.props.showStats}
                helpTextOpenStats='Δείτε τα στατιστικά'
                helpTextCloseStats='Κλείστε τα στατιστικά'
                feelingsCodes = {this.props.feelingsCodes}
                userOnline={this.props.userOnline}
            />
        );
    }
}

const mapStateToProps = state =>{
    return{
        chats: state.chats.byId,
        selectedTab: state.chats.selectedTab,
        pendingChatsIds: state.chats.pendingChatsIds,
        activeChatsIds: state.chats.activeChatsIds,
        historyChatsIds: state.chats.historyChatsIds,
        loadingActiveChats: state.chats.loadingActiveChats,
        loadingHistoryChats: state.chats.loadingHistoryChats,
        loadingPendingChats: state.chats.loadingPendingChats,
        selectedChatId: state.chats.selectedChatId,
        childUsers: state.childUsers.byId,
        historyPage: state.chats.historyPage,
        historyTotalPages: state.chats.historyTotalPages,
        showStats: state.statistics.show,
        pendingChatsShouldUpdate: state.chats.pendingChatsShouldUpdate,
        historyChatsShouldUpdate: state.chats.historyChatsShouldUpdate,
        activeChatsShouldUpdate: state.chats.activeChatsShouldUpdate,
        unreadChatsIds: state.chats.unreadChatsIds,
        feelingsCodes:state.general.feelings,
        userOnline: state.user.info.isAvailable,
    }
};

const mapDispatchToProps = dispatch => {
    return{
        fetchActiveChats: (payload) => dispatch(actions.fetchActiveChats(payload)),
        fetchPendingChats: (payload) => dispatch(actions.fetchPendingChats(payload)),
        fetchHistoryChats: (payload) => dispatch(actions.fetchHistoryChats(payload)),
        selectChat: (payload) => dispatch(actions.chatSelected(payload)),
        setActiveTab: (payload) => dispatch(actions.setActiveTab(payload)),
        toggleChatStatisticsVisibility: (payload) => dispatch(actions.toggleChatStatisticsVisibility(payload))
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(ChatsTabsHOC));
