import React from 'react';
import './Status.css';
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import StatusToggleButtonAgent from "../StatusToggleButton/StatusToggleButton";

const Status = (props) => {

    return(
        <div className='statusAgent'>
            <Grid
                container spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Grid item xs={3} className='custom-text'>
                    <Typography variant="subtitle1">
                        Your Status
                    </Typography>
                </Grid>
                <Grid item xs={4} className='onlineStatus'>
                    <StatusToggleButtonAgent labelOn='Online' labelOff='Offline' {...props} />
                </Grid>
                <Grid item xs={4} className='welcome-text'>
                    <Typography variant="subtitle1">
                        Καλως ήρθες, {props.agentName}
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
};

export default Status;
