import React from 'react';

import {Pie} from "react-chartjs-2";

const PieChart = (props) => {

    const dataPie = {
        labels: props.labels,
        datasets: [
            {
                label: 'Data',
                data: props.data,
                backgroundColor:['#6197e9','#83d0ab']
            }
        ]
    };

    return (
        <Pie
            data={dataPie}
            options={{
                maintainAspectRatio : false,
                legend:{
                    "display": true,
                    "position": "bottom",
                    "reverse": true,
                },
                animation:{
                    animateScale: true
                },
                plugins: {
                    datalabels: {
                        display: false
                    }
                }
            }}
        />
    )
};

export default PieChart;