import * as React from 'react';

class AutogrowingTextInput extends React.Component {
  handleChange = (event) => {
    const textareaLineHeight = 18;
    const { minRows, maxRows } = this.props;

    const previousRows = event.target.rows;
    event.target.rows = minRows; // reset number of rows in textarea

    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.scrollTop = event.target.scrollHeight;
    }

    this.props.updateState({
      value: event.target.value,
      rows: currentRows < maxRows ? currentRows : maxRows,
    });
  };
  render() {
    return (
      <textarea
        rows={this.props.rows}
        value={this.props.value}
        placeholder={'Γράψε το μήνυμα σου...'}
        className={'chatTextInput'}
        onChange={this.handleChange}
        onKeyUp={this.props.sendMessage}
        disabled={this.props.disabled}
      />
    );
  }
}

export default AutogrowingTextInput;
