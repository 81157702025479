import React, {Component} from "react";
import {connect} from "react-redux";
import * as actions from '../../actions';
import Login from "../../components/Login/Login";

class LoginHOC extends Component {
    constructor(props){
        super(props);
        this.state = {
            username: '',
            password: ''
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleInputChange = function (event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    handleSubmit = function (event) {
        event.preventDefault();
        this.props.onAuthAgentStart({
            sendBody: {
                username: this.state.username,
                password: this.state.password
            }
        });
    };

    render (){
        return (
         <Login
             {...this.props}
             handleSubmit={this.handleSubmit}
             handleInputChange={this.handleInputChange}
         />
        );
    }
}
const mapStateToProps = state => {
    return {
        loading: state.user.loading,
        error: state.user.error,
        isAuthenticated: state.user.token !== null
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onAuthAgentStart: (payload) => dispatch(actions.authAgentStart(payload))
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(LoginHOC);
