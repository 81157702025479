import React, {Component} from "react";
import './Login.css';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Person from '@material-ui/icons/PersonOutlineOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Redirect} from "react-router";

class Login extends Component {
    render() {
        let errorMessage = null;
        if(this.props.error){
            errorMessage = ( <h4 className='errorMessageForm'>{this.props.error}</h4>)
        }
        let form = (
            <form onSubmit={this.props.handleSubmit}>
                <Avatar className='avatar'>
                    <Person />
                </Avatar>
                {errorMessage}
                <TextField
                    onChange={this.props.handleInputChange}
                    className='input-login'
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    autoComplete="username"
                    autoFocus
                />
                <TextField
                    onChange={this.props.handleInputChange}
                    className='input-login'
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className='submit'
                >
                    Sign In
                </Button>
            </form>
        );

        if(this.props.loading){
            form= <CircularProgress className='spinner'/>
        }

        let authRedirect = null;
        if(this.props.isAuthenticated){
            authRedirect = <Redirect to="/" />
        }
        return (
            <div className='agent-login-page'>
                {authRedirect}
                {form}
            </div>
        );
    }
}

export default Login;