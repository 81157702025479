import React from "react";
import './RightInfoActiveChats.css';

import SectionTitle from "../../components/SectionTitle/SectionTitle";
import CloseButton from "../../components/CloseButton/CloseButton";
import ActiveChat from "../ActiveChat/ActiveChat";
import ChatTimer from "../ChatTimer/ChatTimer";
import ChatButtonHOC from "../../containers/ChatButtonHOC/ChatButtonHOC";

const RightInfoActiveStats = (props) => {
    return(
        <div className='agentStats'>
            {/*close*/}
            <CloseButton link="/" chatSelectedClosed={props.chatSelectedClosed}/>

            {/*title*/}
            <SectionTitle capitalized centered title={props.title}/>

            {/* active chat details -- details + subjects + notes  */}
            <ActiveChat {...props}/>

            {/* bottomBlock */}
            <div className='bottomBlock'>
                {/* chatTimer */}
              {props.chatInfo.endTimestamp == null && props.chatInfo.startTimestamp != null  &&
                <ChatTimer
                  timeRemaining={props.timeRemaining}
                  helpText='ο χρόνος συζήτησης που απομένει'
                />
              }
                {/* chatBtn [start|stop] */}
                <ChatButtonHOC
                    chatId={props.chatInfo.id}
                    online={props.isAgentOnline}
                    helpTextDisabled='Πρέπει να μπείτε online για να ξεκινήσετε τη συζήτηση'
                    helpText='Ξεκινήστε τη συζήτηση'
                    helpEnabledChatText='Τερματίστε τη συζήτηση'
                    startLabel='Start Chat'
                    endLabel='End Chat'
                />
            </div>
        </div>
    )
};


export default RightInfoActiveStats;
