import moment from 'moment';

const getTimeRemaining = (endtime) => {
  const now = new moment().unix();
  const t =  endtime - now;

  if (t <= 0) {
    return {
      'total': 0,
      'days': 0,
      'hours': 0,
      'minutes': '00',
      'seconds': '00'
    }
  }
  var seconds = Math.floor( (t) % 60 );
  var minutes = Math.floor( (t/60) % 60 );
  var hours = Math.floor( (t/(60*60)) % 24 );
  var days = Math.floor( t/(60*60*24) );

  return {
    'total': t,
    'days': days,
    'hours': hours,
    'minutes': minutes < 10? '0' + minutes : minutes,
    'seconds': seconds < 10 ? '0' + seconds : seconds
  }
};

export default getTimeRemaining;
