import React from 'react';
import './chatTitle.css';
import moment from 'moment';

const ChatTitle = (props) => {
  const {
    createdDate,
  } = props;
  const friendlyTimestamp = moment(createdDate).format('dddd, DD MMM YYYY, HH:mm');
  return (
    <div className={'chatTitle'}>
      {friendlyTimestamp}
    </div>
  );
};

export default ChatTitle;
