import * as actionTypes from '../actions/types';
import { updateObject } from "../shared/utility";

const initialState = {
    stats:  {
        onHold: 0,
        completedToday: 0,
        completedYesterday: 0,
        completedTotal: 0,
        returningChats: 0,
        newChats: 0,
        averageChatTime: 0,
        averageWaitTime: 0,
        ages: {
        }
    },
    show: false,
    loading: false,
};

const fetchChatStatistics = (state, action) => {
    return updateObject(state, { loading: true });
};

const storeChatStatistics = (state, action) => {
    let statistics = null;
    if(action.payload && action.payload.body && action.payload.body.result){
        statistics = action.payload.body.result;
    }

    return updateObject(state, { stats:statistics, loading: false });
};

const fetchChatStatisticsFailed = (state, action) => {
    return updateObject(state, { loading: false });
};

function logout() {
    return initialState;
}

const toggleChatStatisticsVisibility = (state, action) => {
    return updateObject(state, { show: action.payload.value});
};

const hideChatStatistics = (state, action) => {
    return updateObject(state, { show: false});
};

const updateChatsOnHoldStatistic = (state, action) => {
    return updateObject(
        state,
        {
            stats: {
                ...state.stats,
                onHold: state.stats.onHold + 1
            }
        });
};

const updateChatsOnHoldAfterChatStarted = (state, action) => {
    return updateObject(
        state,
        {
            stats: {
                ...state.stats,
                onHold: state.stats.onHold > 0 ? (state.stats.onHold  - 1) : state.stats.onHold
            }
        });
};

const updateChatsStatisticsAfterChatEnded = (state, action) => {
    return updateObject(
        state,
        {
            stats: {
                ...state.stats,
                completedToday: state.stats.completedToday + 1,
                completedTotal: state.stats.completedTotal + 1,
            }
        });
};

export const statistics = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_CHAT_STATISTICS: return fetchChatStatistics(state,action);
        case actionTypes.STORE_CHAT_STATISTICS: return storeChatStatistics(state,action);
        case actionTypes.FETCH_CHAT_STATISTICS_FAILED: return fetchChatStatisticsFailed(state,action);
        case actionTypes.TOGGLE_CHAT_STATISTICS_VISIBILITY: return toggleChatStatisticsVisibility(state,action);
        case actionTypes.CHAT_SELECTED: return hideChatStatistics(state,action);
        case actionTypes.LOGOUT: return logout(state,action);
        case actionTypes.SIGNAL_R_EVENT_CHAT_INITIATED: return updateChatsOnHoldStatistic(state, action);
        case actionTypes.CHAT_STARTED: return updateChatsOnHoldAfterChatStarted(state, action);
        case actionTypes.SIGNAL_R_EVENT_CHAT_STARTED: return updateChatsOnHoldAfterChatStarted(state, action);
        case actionTypes.SIGNAL_R_EVENT_CHAT_ENDED: return updateChatsStatisticsAfterChatEnded(state, action);
        default:
            return state;

    }
};
