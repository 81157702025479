import React, {Component} from 'react';
import RightInfoActiveStats from "../../components/RightInfoActiveChats/RightInfoActiveChats";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import getTimeRemaining from '../../helpers/countdownTimer';
import * as actions from "../../actions";
import _ from "lodash";

class InfoActiveStatsHOC extends Component{
    constructor(props) {
        super(props);
        this.state = {
            timeRemaining: undefined,
        };
    }
    componentDidMount() {
        if(this.props.selectedChatId) {
            this.HANDLE = setInterval(this.updateTime, 1000);
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!this.HANDLE && this.props.selectedChatId){
            this.HANDLE = setInterval(this.updateTime, 1000);
        }
    }

    updateTime = () => {
        if(this.props.chats && this.props.chats[this.props.selectedChatId] && this.props.chats[this.props.selectedChatId].expiryTimestamp ){
            const timeRemaining = getTimeRemaining(this.props.chats[this.props.selectedChatId].expiryTimestamp);
            this.setState({
                timeRemaining
            });
        }
    };
    componentWillUnmount() {
        clearInterval(this.HANDLE);
    }

    render() {
        let userInfo = null;
        let userInfoAgeDisplayed = null;
        let chatInfo = null;
        let chatStartFeelingInfo = null;
        let chatEndFeelingInfo = null;
        if( this.props.selectedChatId !== null && this.props.chats !== null && this.props.childUsers && this.props.chats[this.props.selectedChatId] && this.props.childUsers[this.props.chats[this.props.selectedChatId].childId]) {
            userInfo = this.props.childUsers[this.props.chats[this.props.selectedChatId].childId];
            userInfoAgeDisplayed = _.find(this.props.agesCodes, function(c) { return  c.code === userInfo.age });

            chatInfo = this.props.chats[this.props.selectedChatId];
            chatStartFeelingInfo = _.find(this.props.feelingsCodes, function(c) { return  c.code === chatInfo.startFeeling });
            chatEndFeelingInfo = _.find(this.props.feelingsCodes, function(c) { return  c.code === chatInfo.endFeeling });
            return (
                <RightInfoActiveStats
                    {...this.props}
                    timeRemaining={this.state.timeRemaining}
                    chatInfo={chatInfo}
                    userInfo={userInfo}
                    userInfoAge={userInfoAgeDisplayed ? userInfoAgeDisplayed.name : ""}
                    chatStartFeelingInfo={chatStartFeelingInfo}
                    chatEndFeelingInfo={chatEndFeelingInfo}
                    title='Active Chat Details'
                    onHoldChats={this.props.stats.onHold}
                    chatSelectedClosed={this.props.chatSelectedClosed}
                />
            );
        }else{
            return null;
        }
    }
}

const mapStateToProps = state => {
  return {
      selectedChatId: state.chats.selectedChatId,
      chats: state.chats.byId,
      childUsers: state.childUsers.byId,
      stats: state.statistics.stats,
      isAgentOnline: state.user.info.isAvailable,
      feelingsCodes: state.general.feelings,
      agesCodes:state.general.ages
  }
};

const mapDispatchToProps = dispatch => ({
    chatSelectedClosed: (payload) => {dispatch(actions.chatSelectedClosed(payload))},
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InfoActiveStatsHOC))
