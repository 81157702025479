import React, {useState} from "react"
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from 'prop-types';
import {makeStyles} from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ExitToApp from "@material-ui/icons/ExitToApp";
import ListItemText from "@material-ui/core/ListItemText";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import GeneralDialog from "../../../GeneralDialog/GeneralDialog";
import AdminInfoModalContainer from "../../../../containers/AdminInfoHOC/AdminInfoHOC";

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1
    },
    userMenu: {
        float: "right",
        marginRight: 15
    }
}));


const UserMenu = (props) => {
    const {
        handleMenu,
        handleClose,
        logoutAction,
        anchorEl,
        open,
    } = props;
    const classes = useStyles();
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <div className={classes.grow}>
            <GeneralDialog
                content={
                    <AdminInfoModalContainer
                        onCancel={() => setIsModalOpen(false)}
                    />
                }
                fullWidth
                maxWidth={"sm"}
                open={isModalOpen}
                handleClose={() => setIsModalOpen(false)}
                title={"Password change"}
            />
            <div className={classes.userMenu}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                >
                    <AccountCircle/>
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => setIsModalOpen(true)}>
                        <ListItemIcon>
                            <VpnKeyIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Password change" />
                    </MenuItem>
                    <MenuItem onClick={logoutAction}>
                        <ListItemIcon>
                            <ExitToApp fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </MenuItem>
                </Menu>
            </div>
        </div>
    )
};

export default UserMenu;

UserMenu.propTypes = {
    handleMenu: PropTypes.func,
    handleClose: PropTypes.func,
    logoutAction: PropTypes.func,
    anchorEl: PropTypes.object,
    open: PropTypes.bool,

};
