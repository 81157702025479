import React, {Component} from 'react';
import {connect} from "react-redux";
import MainGrid from "../../components/MainGrid/MainGrid";


class MainGridHOC extends Component{

    render() {
        return(
            <MainGrid
                leftContent={this.props.leftContent}
                centerContent={this.props.centerContent}
                rightContent={this.props.rightContent}
                showStats={this.props.showStats}
                selectedChatId={this.props.selectedChatId}
            />
        )
    }
}

const mapStateToProps = state => {
    return{
        showStats: state.statistics.show,
        selectedChatId: state.chats.selectedChatId
    }
};

export default connect(mapStateToProps)(MainGridHOC);