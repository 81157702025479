import React, {Component} from 'react';
import './ReportChats.css';
import SectionTitle from "../SectionTitle/SectionTitle";
import {Container} from "@material-ui/core";
import ChatsReportOptionsHOC from "../../containers/ChatsReportHOC/ChatsReportHOC";

class ReportChats extends Component{
    render() {
        // console.log(this.props);
        return (
            <div className='printChats'>
                <Container>
                    {/*title*/}
                    <SectionTitle title={this.props.title}/>

                    <ChatsReportOptionsHOC />
                </Container>
            </div>
        )
    }
}

export default ReportChats;