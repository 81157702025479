import React, {Component} from 'react';
import Header from '../../components/Header/Header';
import * as actions from "../../actions";
import {connect} from "react-redux";
import {signalRConnect, signalRDisconnect} from "../../redux-signalR/actions";
import {withRouter} from "react-router";

class HeaderHOC extends Component {
  componentDidMount() {
    this.props.fetchChatStatistics(this.props.token);
    this.props.connectToSignalR(this.props.token);
    this.props.fetchFeelings(this.props.token);
    this.props.fetchAges(this.props.token);
    if(this.props.location && this.props.location.pathname ){
      this.selectChatDependingOnUrl();
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.location && this.props.location.pathname &&
      prevProps.location && prevProps.location.pathname &&
      this.props.location.pathname !==prevProps.location.pathname
    ){
      this.selectChatDependingOnUrl();
    }
  }
  componentWillUnmount() {

    // if(this.props.token == null){
      this.props.signalRDisconnect();
    // }
  }

  selectChatDependingOnUrl = () => {
    let chatId = this.props.location.pathname.split("chat/")[1];
    if(chatId && chatId.includes("/")){
      chatId = chatId.split("/")[0];
    }
    if(chatId && chatId.length > 0 && chatId !== this.props.selectedChatId){
      this.props.selectChat(chatId);
    }
  };

  render() {
    return(
      <Header {...this.props} />
    );
  }

}

const mapStateToProps = state => {
  return{
    stats: state.statistics.stats,
    selectedChatId: state.chats.selectedChatId,
    token: state.user.token,
    info: state.user.info,
  }
};

const mapDispatchToProps = dispatch => {
  return{
    fetchChatStatistics: (payload) => dispatch(actions.fetchChatStatistics(payload)),
    connectToSignalR: (payload) => dispatch(signalRConnect(payload)),
    signalRDisconnect: (payload) => dispatch(signalRDisconnect(payload)),
    fetchFeelings: (payload) => dispatch(actions.fetchFeelings(payload)),
    fetchAges: (payload) => dispatch(actions.fetchAges(payload)),
    selectChat: (payload) => dispatch(actions.chatSelected(payload)),
    onOnlineToggle: (payload) => dispatch(actions.authOnlineToggle(payload)),
  }
};

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(HeaderHOC));
