import React, {Component} from 'react';
import './FeelingChatEnded.css';
import _ from "lodash";
import IMAGES from "../../../assets/images";
import {Typography} from "@material-ui/core";

class FeelingChatEnded extends Component {
    render() {
        let feelingEndSection = null;

        if(this.props.chat && this.props.chat.endFeeling) {
            const {feelingsCodes} = this.props;
            const feeling = this.props.chat.endFeeling;
            const feelingInfo = _.find(feelingsCodes, function(c) { return  c.code === feeling });


            if(feelingInfo) {
                    feelingEndSection = (<div>
                        <Typography variant="h6" component='div'>
                            Ο χρήστης μετά τη συνομιλία δήλωσε ότι νιώθει
                        </Typography>
                        <Typography variant="h6" component='div' className='endFeelingTypo' p={1}>
                            <img src={IMAGES[feelingInfo.code.toUpperCase()]} alt={feelingInfo.name}/> {feelingInfo.name}
                        </Typography>
                    </div>)
            }
        }
        return (
            <div className='endFeeling'>
                {feelingEndSection}
            </div>
        )
    }
}

export default FeelingChatEnded;