import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from "react-router-dom";
import {Provider} from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import store, {persistor, history} from "./store/store";
import { ConnectedRouter } from 'connected-react-router'

const app = (
    <Provider store={store}>

      <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
              <BrowserRouter>
                  <App/>
              </BrowserRouter>
          </ConnectedRouter>
      </PersistGate>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
