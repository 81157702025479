import * as actionTypes from './types';
import {actionCreator} from "../shared/utility";

// individual chat
export const chatSelected = actionCreator(actionTypes.CHAT_SELECTED);
export const chatSelectedClosed = actionCreator(actionTypes.CHAT_SELECTED_CLOSED);
export const fetchChatMessages = actionCreator(actionTypes.FETCH_CHAT_MESSAGES);
export const fetchChatMessagesSuccess = actionCreator(actionTypes.FETCH_CHAT_MESSAGES_SUCCESS);
export const fetchChatMessagesFailure = actionCreator(actionTypes.FETCH_CHAT_MESSAGES_FAILURE);
export const fetchChatMessagesAfterSignalR = actionCreator(actionTypes.FETCH_CHAT_MESSAGES_AFTER_SIGNAL_R);
export const fetchChatMessagesAfterSignalRSuccess = actionCreator(actionTypes.FETCH_CHAT_MESSAGES_AFTER_SIGNAL_R_SUCCESS);
export const fetchChatMessagesAfterSignalRFailure = actionCreator(actionTypes.FETCH_CHAT_MESSAGES_AFTER_SIGNAL_R_FAILURE);
export const getSingleChat = actionCreator(actionTypes.GET_SINGLE_CHAT);
export const getSingleChatSuccess = actionCreator(actionTypes.GET_SINGLE_CHAT_SUCCESS);
export const getSingleChatFailure = actionCreator(actionTypes.GET_SINGLE_CHAT_FAILURE);

// pending chats
export const fetchPendingChats = actionCreator(actionTypes.FETCH_PENDING_CHATS);
export const storePendingChats = actionCreator(actionTypes.STORE_PENDING_CHATS);
export const fetchPendingChatsFailed = actionCreator(actionTypes.FETCH_PENDING_CHATS_FAILED);

// active - ongoing chats
export const fetchActiveChats = actionCreator(actionTypes.FETCH_ACTIVE_CHATS);
export const storeActiveChats = actionCreator(actionTypes.STORE_ACTIVE_CHATS);
export const fetchActiveChatsFailed = actionCreator(actionTypes.FETCH_ACTIVE_CHATS_FAILED);

// history chats
export const fetchHistoryChats = actionCreator(actionTypes.FETCH_HISTORY_CHATS);
export const storeHistoryChats = actionCreator(actionTypes.STORE_HISTORY_CHATS);
export const fetchHistoryChatsFailed = actionCreator(actionTypes.FETCH_HISTORY_CHATS_FAILED);

// all child chats
export const fetchChildChats = actionCreator(actionTypes.FETCH_CHILD_CHATS);
export const fetchChildChatsSuccess = actionCreator(actionTypes.FETCH_CHILD_CHATS_SUCCESS);
export const fetchChildChatsFailure = actionCreator(actionTypes.FETCH_CHILD_CHATS_FAILURE);

// active tab
export const setActiveTab = actionCreator(actionTypes.SET_ACTIVE_TAB);

// send chats in email
export const sendChatsReport = actionCreator(actionTypes.SEND_CHATS_REPORT);
export const sendChatsReportSuccess = actionCreator(actionTypes.SEND_CHATS_REPORT_SUCCESS);
export const sendChatsReportFailure = actionCreator(actionTypes.SEND_CHATS_REPORT_FAILURE);
export const clearSendChatsReportStatus = actionCreator(actionTypes.CLEAR_SEND_CHATS_REPORT_STATUS);

// unread chats
export const addNewUnreadChat = actionCreator(actionTypes.ADD_NEW_UNREAD_CHAT);
export const unreadChatWasRead = actionCreator(actionTypes.UNREAD_CHAT_WAS_READ);