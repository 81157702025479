import * as actionTypes from '../actions/types';
import {updateObject} from "../shared/utility";
import parseChatsChildUsers from "../helpers/parseChatsChildUsers";

const initialState = {
    byId: {},
    allIds:[],
    loading: false
};

const fetchChildUsers = (state, action) => {
    return updateObject(state, { loading: true });
};

const storeChildUsers = (state, action) => {
    let parsedChildUsers= null;
    if(action.payload && action.payload.body && action.payload.body.results){
        parsedChildUsers = parseChatsChildUsers(state.byId, state.allIds, action.payload.body.results);
    }
    return updateObject(state, {
        loading: false,
        byId: {
            ...state.byId,
            ...parsedChildUsers.newChildUsers
        },
        allIds: parsedChildUsers.childUsersIds,
    });
};

const storeChildUserFromSingleChatFetched = (state, action) => {
    let ids = state.allIds;
    if (!ids.includes(action.payload.body.result.createdBy.id)) {
        ids.push(action.payload.body.result.createdBy.id);
    }

    return updateObject(state, {
        loading: false,
        byId: {
            ...state.byId,
            [action.payload.body.result.createdBy.id]: {
                ...state.byId[action.payload.body.result.createdBy.id],
                id: action.payload.body.result.createdBy.id,
                age: action.payload.body.result.createdBy.age,
                displayName: action.payload.body.result.createdBy.displayName,
                ipAddress: action.payload.body.result.createdBy.ipAddress,
                applicationUserLogins: action.payload.body.result.createdBy.applicationUserLogins,
            }
        },
        allIds: ids,
    });
};

const childConnected = (state, action) => {
    return updateObject(state, {
        byId: {
            ...state.byId,
            [action.payload.UserId]: {
                ...state.byId[action.payload.UserId],
                connected: true
            }
        },
    });
};

const childDisconnected = (state, action) => {
    return updateObject(state, {
        byId: {
            ...state.byId,
            [action.payload.UserId]: {
                ...state.byId[action.payload.UserId],
                connected: false
            }
        },
    });
};

function logout() {
    return initialState;
}


export const childUsers = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PENDING_CHATS:
        case actionTypes.FETCH_ACTIVE_CHATS:
        case actionTypes.FETCH_HISTORY_CHATS: return fetchChildUsers(state,action);
        case actionTypes.GET_SINGLE_CHAT_SUCCESS: return storeChildUserFromSingleChatFetched(state,action);
        case actionTypes.STORE_PENDING_CHATS:
        case actionTypes.STORE_ACTIVE_CHATS:
        case actionTypes.STORE_HISTORY_CHATS: return storeChildUsers(state,action);
        case actionTypes.SIGNAL_R_EVENT_CHILD_CONNECTED: return childConnected(state,action);
        case actionTypes.SIGNAL_R_EVENT_CHILD_DISCONNECTED: return childDisconnected(state,action);
        case actionTypes.LOGOUT: return logout(state,action);
        default:
            return state;
    }
};
