import React, {Component} from 'react';
import {connect} from "react-redux";
import AgentInfoForm from "../../components/AgentInfo/AgentInfoForm";
import * as actions from "../../actions";

class AgentInfoModalContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
    }

    onResponseMessageDismiss = () => {
        this.props.changeUserState({key: 'updateResponseMessage', value: null})
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(
            prevProps.updatingAgentInfo && !this.props.updatingAgentInfo
            && !this.props.updateResponseMessage
        ){
            this.props.onCancel();
        }

    }

    render() {
        return (
            <AgentInfoForm
                {...this.props}
                onCancel={this.props.onCancel}
                responseMessage={this.props.updateResponseMessage}
                onResponseMessageDismiss={this.onResponseMessageDismiss}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        updatingAgentInfo: state.user.updatingCurrentUserInfo,
        updateResponseMessage: state.user.updateResponseMessage
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateAgentInfo: (payload) => {
            dispatch(actions.updateCurrentUserInfo(payload))
        },
        changeUserState: (payload) => {
            dispatch(actions.changeUserState(payload))
        }
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(AgentInfoModalContainer);