import React from 'react';
import './ChatButton.css';

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

const ChatButton = (props) => {

    const button =
      <span>
            <Button variant="contained"
                    disabled={!props.isAvailable}
                    onClick={props.clicked}
                    className={[props.enabledChat ? 'enabled' : null].join(' ')}>
                {!props.enabledChat ? props.startLabel : props.endLabel }
            </Button>
        </span>;
    return(
        <div className='chatBtn' >
            {!props.isAvailable ?
                <Tooltip title={props.helpTextDisabled} placement="top" interactive>
                    {button}
                </Tooltip>
                :
                <Tooltip title={!props.enabledChat? props.helpText : props.helpEnabledChatText} placement="top">
                    {button}
                </Tooltip>
            }
        </div>
    )
};

export default ChatButton;
