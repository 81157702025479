import * as actionTypes from '../actions/types';
import { updateObject } from "../shared/utility";
import {parseError} from "../helpers/functions";

const initialState = {
    token: null,
    userId: null,
    error: null,
    loading: false,
    stats: {
        totalChatDuration: 0,
        averageChatTime: 0
    },
    info: {},
    fetchingInfo: false,
    updatingCurrentUserInfo: false,
    updateResponseMessage: null,
};

const authAgentStart = (state, action) => {
    return updateObject(state, { error: null, loading: true});
};

const authAgentSuccess = (state, action) => {
    return updateObject(state, {
        // userId: action.payload.body.result.id,
        token: action.payload.body,
        error: null,
        loading: false});
};
const authAgentFail = (state, action) => {

    let errorMsg = 'Something went wrong, please try again later.';
    if(action.payload && action.payload.response && action.payload.response.body && action.payload.response.body.errors[0] && action.payload.response.body.errors[0].message) {
        errorMsg = action.payload.response.body.errors[0].message; // error msg from api
    }

    return updateObject(state, {error: errorMsg, loading: false});
};

const fetchUserStatistics = (state, action) => {
    return updateObject(state, { loading: true });
};

const storeUserStatistics = (state, action) => {
    let statistics = null;
    if(action.payload && action.payload.body && action.payload.body.result){
        statistics = action.payload.body.result;
    }

    return updateObject(state, { stats:statistics, loading: false });
};

const fetchUserStatisticsFailed = (state, action) => {
    return updateObject(state, { loading: false });
};

function logout() {
    return initialState;
}

const fetchCurrentUserInfo = (state, action) => {
    return updateObject(state, {fetchingInfo: true})
};

const fetchCurrentUserInfoSuccess = (state, action) => {
    try{
        return updateObject(state, {
            fetchingInfo: false,
            info: {
                ...action.payload.body.result,
                roleCode: action.payload.body.result.roles[0].code
            }
        })
    } catch (e) {
        return updateObject(state, {fetchingInfo: false})
    }
};

const fetchCurrentUserInfoFailed = (state, action) => {
    return updateObject(state, {fetchingInfo: false})
};

const updateCurrentUserInfo = (state, action) => {
    return updateObject(state, {updatingCurrentUserInfo: true})
};

const updateCurrentUserInfoSuccess = (state, action) => {
    return updateObject(state, {updatingCurrentUserInfo: false})
};

const updateCurrentUserInfoFailed = (state, action) => {
    try{
        let parsedError =  parseError(action.payload);
        return updateObject(state, {
            updatingAgentInfo: false,
            updateResponseMessage: parsedError ? parsedError : "Failed to change password"
        });
    } catch (e) {
        return updateObject(state, {updatingCurrentUserInfo: false})
    }
};

const changeUserState = (state, action) => {
    return updateObject(state, {[action.payload.key]: action.payload.value})
};

export const user = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authAgentStart(state,action);
        case actionTypes.AUTH_SUCCESS: return authAgentSuccess(state,action);
        case actionTypes.AUTH_FAIL: return authAgentFail(state,action);
        case actionTypes.FETCH_USER_STATISTICS: return fetchUserStatistics(state,action);
        case actionTypes.STORE_USER_STATISTICS: return storeUserStatistics(state,action);
        case actionTypes.FETCH_USER_STATISTICS_FAILED: return fetchUserStatisticsFailed(state,action);
        case actionTypes.FETCH_CURRENT_USER_INFO: return fetchCurrentUserInfo(state,action);
        case actionTypes.FETCH_CURRENT_USER_INFO_SUCCESS: return fetchCurrentUserInfoSuccess(state,action);
        case actionTypes.FETCH_CURRENT_USER_INFO_FAILED: return fetchCurrentUserInfoFailed(state,action);
        case actionTypes.LOGOUT: return logout(state,action);
        case actionTypes.UPDATE_CURRENT_USER_INFO: return updateCurrentUserInfo(state,action);
        case actionTypes.UPDATE_CURRENT_USER_INFO_SUCCESS: return updateCurrentUserInfoSuccess(state,action);
        case actionTypes.UPDATE_CURRENT_USER_INFO_FAILED: return updateCurrentUserInfoFailed(state,action);
        case actionTypes.CHANGE_USER_STATE: return changeUserState(state,action);
        default:
            return state;

    }
};
