//PAGE TYPES
export const FETCH_PAGE_TYPES = 'FETCH_PAGE_TYPES';
export const FETCH_PAGE_TYPES_SUCCESS = 'FETCH_PAGE_TYPES_SUCCESS';
export const FETCH_PAGE_TYPES_FAILED = 'FETCH_PAGE_TYPES_FAILED';

//PAGES
export const REORDER_PAGE = 'REORDER_PAGE';
export const REORDER_PAGE_SUCCESS = 'REORDER_PAGE_SUCCESS';
export const REORDER_PAGE_FAILED = 'REORDER_PAGE_FAILED';
export const DELETE_PAGE = 'DELETE_PAGE';
export const DELETE_PAGE_SUCCESS = 'DELETE_PAGE_SUCCESS';
export const DELETE_PAGE_FAILED = 'DELETE_PAGE_FAILED';
export const UPDATE_PAGE = 'UPDATE_PAGE';
export const UPDATE_PAGE_SUCCESS = 'UPDATE_PAGE_SUCCESS';
export const UPDATE_PAGE_FAILED = 'UPDATE_PAGE_FAILED';
export const CREATE_PAGE = 'CREATE_PAGE';
export const CREATE_PAGE_SUCCESS = 'CREATE_PAGE_SUCCESS';
export const CREATE_PAGE_FAILED = 'CREATE_PAGE_FAILED';
export const FETCH_PAGE = 'FETCH_PAGE';
export const FETCH_PAGE_SUCCESS = 'FETCH_PAGE_SUCCESS';
export const FETCH_PAGE_FAILED = 'FETCH_PAGE_FAILED';
export const CHANGE_PAGES_STATE = 'CHANGE_PAGES_STATE)';
export const FETCH_PAGES = 'FETCH_PAGES';
export const FETCH_PAGES_SUCCESS = 'FETCH_PAGES_SUCCESS';
export const FETCH_PAGES_FAILED = 'FETCH_PAGES_FAILED';

//ROLES
export const FETCH_ROLES = 'FETCH_ROLES';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAILED = 'FETCH_ROLES_FAILED';

//APPLICATION USERS
export const DELETE_APPLICATION_USER = 'DELETE_APPLICATION_USER';
export const DELETE_APPLICATION_USER_SUCCESS = 'DELETE_APPLICATION_USER_SUCCESS';
export const DELETE_APPLICATION_USER_FAILED = 'DELETE_APPLICATION_USER_FAILED';
export const UPDATE_APPLICATION_USER = 'UPDATE_APPLICATION_USER';
export const UPDATE_APPLICATION_USER_SUCCESS = 'UPDATE_APPLICATION_USER_SUCCESS';
export const UPDATE_APPLICATION_USER_FAILED = 'UPDATE_APPLICATION_USER_FAILED';
export const CREATE_APPLICATION_USER = 'CREATE_APPLICATION_USER';
export const CREATE_APPLICATION_USER_SUCCESS = 'CREATE_APPLICATION_USER_SUCCESS';
export const CREATE_APPLICATION_USER_FAILED = 'CREATE_APPLICATION_USER_FAILED';
export const CHANGE_APPLICATION_USERS_STATE = 'CHANGE_APPLICATION_USERS_STATE';
export const FETCH_APPLICATION_USER = 'FETCH_APPLICATION_USER';
export const FETCH_APPLICATION_USER_SUCCESS = 'FETCH_APPLICATION_USER_SUCCESS';
export const FETCH_APPLICATION_USER_FAILED = 'FETCH_APPLICATION_USER_FAILED';
export const FETCH_APPLICATION_USERS = 'FETCH_APPLICATION_USERS';
export const FETCH_APPLICATION_USERS_SUCCESS = 'FETCH_APPLICATION_USERS_SUCCESS';
export const FETCH_APPLICATION_USERS_FAILED = 'FETCH_APPLICATION_USERS_FAILED';

// AUTH
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_ONLINE_TOGGLE = 'AUTH_ONLINE_TOGGLE';
export const AUTH_ONLINE_TOGGLE_SUCCESS = 'AUTH_ONLINE_TOGGLE_SUCCESS';
export const AUTH_ONLINE_TOGGLE_FAILED = 'AUTH_ONLINE_TOGGLE_SUCCESS_FAILED';

// USER
export const CHANGE_USER_STATE = 'CHANGE_USER_STATE';
export const UPDATE_CURRENT_USER_INFO = 'UPDATE_CURRENT_USER_INFO';
export const UPDATE_CURRENT_USER_INFO_SUCCESS = 'UPDATE_CURRENT_USER_INFO_SUCCESS';
export const UPDATE_CURRENT_USER_INFO_FAILED = 'UPDATE_CURRENT_USER_INFO_FAILED';
export const FETCH_CURRENT_USER_INFO = 'FETCH_CURRENT_USER_INFO';
export const FETCH_CURRENT_USER_INFO_SUCCESS = 'FETCH_CURRENT_USER_INFO_SUCCESS';
export const FETCH_CURRENT_USER_INFO_FAILED = 'FETCH_CURRENT_USER_INFO_FAILED';
export const USER_IS_UNAUTHORIZED = 'USER_IS_UNAUTHORIZED';
export const LOGOUT = 'LOGOUT';
export const FETCH_USER_STATISTICS = 'FETCH_USER_STATISTICS';
export const STORE_USER_STATISTICS = 'STORE_USER_STATISTICS';
export const FETCH_USER_STATISTICS_FAILED = 'FETCH_USER_STATISTICS_FAILED';

// SELECTED CHAT
export const CHAT_SELECTED = 'CHAT_SELECTED';
export const CHAT_SELECTED_CLOSED = 'CHAT_SELECTED_CLOSED';

// CHAT STATISTICS
export const FETCH_CHAT_STATISTICS = 'FETCH_CHAT_STATISTICS';
export const STORE_CHAT_STATISTICS = 'STORE_CHAT_STATISTICS';
export const FETCH_CHAT_STATISTICS_FAILED = 'FETCH_CHAT_STATISTICS_FAILED';
export const TOGGLE_CHAT_STATISTICS_VISIBILITY = 'TOGGLE_CHAT_STATISTICS_VISIBILITY';

// PENDING CHATS
export const FETCH_PENDING_CHATS = 'FETCH_PENDING_CHATS';
export const STORE_PENDING_CHATS = 'STORE_PENDING_CHATS';
export const FETCH_PENDING_CHATS_FAILED = 'FETCH_PENDING_CHATS_FAILED';

// ACTIVE CHATS
export const FETCH_ACTIVE_CHATS = 'FETCH_ACTIVE_CHATS';
export const STORE_ACTIVE_CHATS = 'STORE_ACTIVE_CHATS';
export const FETCH_ACTIVE_CHATS_FAILED = 'FETCH_ACTIVE_CHATS_FAILED';

// HISTORY CHATS
export const FETCH_HISTORY_CHATS = 'FETCH_HISTORY_CHATS';
export const STORE_HISTORY_CHATS = 'STORE_HISTORY_CHATS';
export const FETCH_HISTORY_CHATS_FAILED = 'FETCH_HISTORY_CHATS_FAILED';

// CHAT LIFECYCLE
export const START_CHAT = 'START_CHAT';
export const CHAT_STARTED = 'CHAT_STARTED';
export const START_CHAT_FAILURE = 'START_CHAT_FAILURE';
export const END_CHAT = 'END_CHAT';
export const CHAT_ENDED = 'CHAT_ENDED';
export const END_CHAT_FAILURE = 'END_CHAT_FAILURE';


export const GET_SINGLE_CHAT = 'GET_SINGLE_CHAT';
export const GET_SINGLE_CHAT_SUCCESS = 'GET_SINGLE_CHAT_SUCCESS';
export const GET_SINGLE_CHAT_FAILURE = 'GET_SINGLE_CHAT_FAILURE';

// ALL CHILD CHATS
export const FETCH_CHILD_CHATS = 'FETCH_CHILD_CHATS';
export const FETCH_CHILD_CHATS_SUCCESS = 'FETCH_CHILD_CHATS_SUCCESS';
export const FETCH_CHILD_CHATS_FAILURE = 'FETCH_CHILD_CHATS_FAILURE';

// SIGNALR
export const SIGNAL_R_EVENT_CHAT_INITIATED = 'SIGNAL_R_EVENT_CHAT_INITIATED';
export const SIGNAL_R_EVENT_CHAT_STARTED = 'SIGNAL_R_EVENT_CHAT_STARTED';
export const SIGNAL_R_EVENT_CHAT_ENDED = 'SIGNAL_R_EVENT_CHAT_ENDED';
export const SIGNAL_R_EVENT_CHAT_END_FEELING_SENT = 'SIGNAL_R_EVENT_CHAT_END_FEELING_SENT';
export const SIGNAL_R_EVENT_CHAT_NEW_MESSAGE = 'SIGNAL_R_EVENT_CHAT_NEW_MESSAGE';
export const SIGNAL_R_EVENT_NOTE_ADDED = 'SIGNAL_R_EVENT_NOTE_ADDED';
export const SIGNAL_R_EVENT_NOTE_UPDATED = 'SIGNAL_R_EVENT_NOTE_UPDATED';
export const SIGNAL_R_EVENT_NOTE_DELETED = 'SIGNAL_R_EVENT_NOTE_DELETED';
export const SIGNAL_R_EVENT_CHILD_DISCONNECTED = 'SIGNAL_R_EVENT_CHILD_DISCONNECTED';
export const SIGNAL_R_EVENT_CHILD_CONNECTED = 'SIGNAL_R_EVENT_CHILD_CONNECTED';

// Redirects
export const REDIRECT_TO_LIST = 'REDIRECT_TO_LIST';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';

// CHAT MESSAGES
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILURE = 'SEND_MESSAGE_FAILURE';
export const STORE_MESSAGE = 'STORE_MESSAGE';
export const STORE_MESSAGES = 'STORE_MESSAGES';
export const GET_MESSAGES_FAILURE = 'GET_MESSAGES_FAILURE';


// individual chat messages
export const FETCH_CHAT_MESSAGES = 'FETCH_CHAT_MESSAGES';
export const FETCH_CHAT_MESSAGES_SUCCESS = 'FETCH_CHAT_MESSAGES_SUCCESS';
export const FETCH_CHAT_MESSAGES_FAILURE = 'FETCH_CHAT_MESSAGES_FAILURE';
export const FETCH_CHAT_MESSAGES_AFTER_SIGNAL_R = 'FETCH_CHAT_MESSAGES_AFTER_SIGNAL_R';
export const FETCH_CHAT_MESSAGES_AFTER_SIGNAL_R_SUCCESS = 'FETCH_CHAT_MESSAGES_AFTER_SIGNAL_R_SUCCESS';
export const FETCH_CHAT_MESSAGES_AFTER_SIGNAL_R_FAILURE = 'FETCH_CHAT_MESSAGES_AFTER_SIGNAL_R_FAILURE';

// CHAT CONSTANTS
export const FETCH_FEELINGS = 'FETCH_FEELINGS';
export const STORE_FEELINGS = 'STORE_FEELINGS';
export const FETCH_FEELINGS_FAILURE = 'FETCH_FEELINGS_FAILURE';
export const FETCH_AGES = 'FETCH_AGES';
export const STORE_AGES = 'STORE_AGES';
export const FETCH_AGES_FAILURE = 'FETCH_AGES_FAILURE';

// NOTES
export const FETCH_CHAT_NOTES = 'FETCH_CHAT_NOTES';
export const STORE_CHAT_NOTES = 'STORE_CHAT_NOTES';
export const FETCH_CHAT_NOTES_FAILURE = 'FETCH_CHAT_NOTES_FAILURE';
export const ADD_NEW_CHAT_NOTE = 'ADD_NEW_CHAT_NOTE';
export const ADD_NEW_CHAT_NOTE_SUCCESS = 'ADD_NEW_CHAT_NOTE_SUCCESS';
export const ADD_NEW_CHAT_NOTE_FAILURE = 'ADD_NEW_CHAT_NOTE_FAILURE';
export const CLEAR_ADD_NEW_CHAT_NOTE_STATUS = 'CLEAR_ADD_NEW_CHAT_NOTE_STATUS';

// SEND CHATS REPORT via MAIL
export const SEND_CHATS_REPORT = 'SEND_CHATS_REPORT';
export const SEND_CHATS_REPORT_SUCCESS = 'SEND_CHATS_REPORT_SUCCESS';
export const SEND_CHATS_REPORT_FAILURE = 'SEND_CHATS_REPORT_FAILURE';
export const CLEAR_SEND_CHATS_REPORT_STATUS = 'CLEAR_SEND_CHATS_REPORT_STATUS';

// UNREAD CHATS
export const ADD_NEW_UNREAD_CHAT = 'ADD_NEW_UNREAD_CHAT';
export const UNREAD_CHAT_WAS_READ = 'UNREAD_CHAT_WAS_READ';
