import React from "react";
import EditIcon from '@material-ui/icons/Edit';
import CustomTable from "../CustomTable/CustomTable";
import _ from "lodash";
import {withRouter} from "react-router";
import GeneralDialog from "../GeneralDialog/GeneralDialog";
import ApplicationUserDetailsForm from "../ApplicationUserDetails/ApplicationUserDetailsForm";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Grid} from "@material-ui/core";
import FabAdd from "../General/fabAdd";
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteUserConfirmation from "./DeleteUserConfirmation";
import Button from "@material-ui/core/Button";

const columns = [
    {propertyName: "userName", header: "Username", id: "UserName" },
    {propertyName: "email", header: "Email", id: "Email" },
    {propertyName: "firstname", header: "First Name", id: "Firstname" },
    {propertyName: "lastname", header: "Last Name", id: "Lastname" },
    {
        propertyName: "role",
        header: "Role",
        formatter: (datum) => {
            try {
                return datum.roles[0].name
            } catch (e) {
                return ""
            }
        }},
];


const ApplicationUsers = (props) => {
    const {
        applicationUsersById,
        page,
        totalRecords,
        rowsPerPage,
        onChangePage,
        onChangeRowsPerPage,
        history,
        createApplicationUser,
        rolesById,
        loadingApplicationUsers,
        posting,
        isModalOpen,
        setIsModalOpen,
        responseMessage,
        onMessageResponseDismiss,
        orderBy,
        order,
        onSort,
        setIsDeleteModalOpen,
        isDeleteModalOpen,
        deleting,
        onDeleteResponseMessageDismiss,
        deleteResponseMessage,
        deleteApplicationUser,
        selectedUser,
        currentUserInfo
    } = props;
    const applicationUsers = _.map(applicationUsersById, e => e);
    const roles = _.map(rolesById, e => e);

    const actions = [
        {
            label: "Edit",
            callback: (row) => history.push(`/users/${row.id}`),
            icon:  <EditIcon/>
        },
        {
            shouldDisplay: (row) => row.id !== currentUserInfo.id,
            label: "Delete",
            callback: (row) => setIsDeleteModalOpen(true, row),
            icon:  <DeleteIcon/>
        },
    ];

    if(loadingApplicationUsers){
        return (
            <Grid
                container spacing={1}
                direction="row"
                justify={"center"}
            >
                <CircularProgress className='spinner'/>
            </Grid>

        )
    }

    return (
        <div>
            <GeneralDialog
                content={
                    <DeleteUserConfirmation
                        onDeleteResponseMessageDismiss={onDeleteResponseMessageDismiss}
                        deleteResponseMessage={deleteResponseMessage}
                        selectedUser={selectedUser}
                    />
                }
                actions={
                    <React.Fragment>
                        {deleting && (
                            <CircularProgress className='spinner' size={30}/>
                        )}
                        <Button  color="primary" onClick={() => deleteApplicationUser({applicationUserId: selectedUser.id})}>
                            Yes
                        </Button>
                        <Button color="secondary" onClick={() => setIsDeleteModalOpen(false)}>
                            Cancel
                        </Button>
                    </React.Fragment>
                }
                fullWidth
                maxWidth={"sm"}
                open={isDeleteModalOpen}
                handleClose={() => setIsDeleteModalOpen(false)}
                title={"Delete User"}
            />
            <GeneralDialog
                content={
                    <ApplicationUserDetailsForm
                        onResponseMessageDismiss={onMessageResponseDismiss}
                        responseMessage={responseMessage}
                        loading={posting}
                        roles={roles}
                        createApplicationUser={createApplicationUser}
                        onCancel={() => setIsModalOpen(false)}
                    />
                }
                fullWidth
                maxWidth={"md"}
                open={isModalOpen}
                handleClose={() => setIsModalOpen(false)}
                title={"Create User"}
            />
            <FabAdd
                addIconAction={() => setIsModalOpen(true)}
                title={"Create User"}
                placement={"top"}
            />
            <CustomTable
                orderBy={orderBy}
                order={order}
                sortable
                onSort={onSort}
                accordionItemHeaderFormatter={datum => datum.email}
                actions={actions}
                title={"Application Users"}
                paginated
                columns={columns}
                data={applicationUsers}
                page={page}
                totalRecords={totalRecords}
                rowsPerPage={rowsPerPage}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
            />
        </div>
    )
};

export default withRouter(ApplicationUsers);