import * as actionTypes from '../actions/types';
import { updateObject } from "../shared/utility";
import _ from "lodash";
import {DEFAULT_LIST_SIZE} from "../helpers/constants";
import {parseError} from "../helpers/functions";

const initialState = {
    byId: {},
    allIds:[],
    page: 0,
    totalRecords: 0,
    loading: false,
    rowsPerPage: DEFAULT_LIST_SIZE,
    posting: false,
    fetchingUser: false,
    fetchedUser: {},
    updating: false,
    responseMessage: null,
    orderBy: 'Email',
    order: 'asc',
    deleting: false,
    deleteResponseMessage: null
};

const fetchApplicationUsers = (state, action) => {
    return updateObject(state, { loading: true });
};

const fetchApplicationUsersSuccess = (state, action) => {
    try {
        const allIds = action.payload.body.results.map(e => e.id);
        const byId = _.keyBy(action.payload.body.results, "id");

        return updateObject(state, {
            loading: false,
            allIds,
            byId,
            totalRecords: action.payload.body.totalCount
        });
    } catch (e) {
        return updateObject(state, {loading: false });
    }
};

const fetchApplicationUsersFailed = (state, action) => {
    return updateObject(state, { loading: false });
};

const fetchApplicationUser = (state, action) => {
    return updateObject(state, { fetchingUser: true });
};

const fetchApplicationUserSuccess = (state, action) => {
    try {
        return updateObject(state, {
            fetchingUser: false,
            fetchedUser: action.payload.body.result
        });
    } catch (e) {
        return updateObject(state, {
            fetchingUser: false
        });
    }
};

const fetchApplicationUserFailed = (state, action) => {
    return updateObject(state, { fetchingUser: false });
};

const changeApplicationUsersState = (state, action) => {
    return updateObject(state, {
        [action.payload.key]: action.payload.value
    });
};

const createApplicationUser = (state, action) => {
    return updateObject(state, { posting: true, responseMessage: null });
};

const createApplicationUserSuccess = (state, action) => {
    return updateObject(state, { posting: false });
};

const createApplicationUserFailed = (state, action) => {
    try{
        return updateObject(state, {
            posting: false,
            responseMessage: parseError(action.payload)
        });
    } catch (e) {
        return updateObject(state, { posting: false });
    }
};

const updateApplicationUser = (state, action) => {
    return updateObject(state, { updating: true });
};

const updateApplicationUserSuccess = (state, action) => {
    return updateObject(state, {
        updating: false,
        responseMessage: "Updated Successfully"
    });
};

const updateApplicationUserFailed = (state, action) => {
    try{
        return updateObject(state, {
            updating: false,
            responseMessage: parseError(action.payload)
        });
    } catch (e) {
        return updateObject(state, { updating: false });
    }
};

const deleteApplicationUser = (state, action) => {
    return updateObject(state, { deleting: true });
};

const deleteApplicationUserSuccess = (state, action) => {
    return updateObject(state, {deleting: false});
};

const deleteApplicationUserFailed = (state, action) => {
    let message = '';
    try {
        message =  parseError(action.payload);
    } catch (e) {
        message = 'Failed to delete user.';
    }
    return updateObject(state, {
        deleting: false,
        deleteResponseMessage: message
    });
};

export const applicationUsers = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_APPLICATION_USERS: return fetchApplicationUsers(state,action);
        case actionTypes.FETCH_APPLICATION_USERS_SUCCESS: return fetchApplicationUsersSuccess(state,action);
        case actionTypes.FETCH_APPLICATION_USERS_FAILED: return fetchApplicationUsersFailed(state,action);
        case actionTypes.FETCH_APPLICATION_USER: return fetchApplicationUser(state,action);
        case actionTypes.FETCH_APPLICATION_USER_SUCCESS: return fetchApplicationUserSuccess(state,action);
        case actionTypes.FETCH_APPLICATION_USER_FAILED: return fetchApplicationUserFailed(state,action);
        case actionTypes.CHANGE_APPLICATION_USERS_STATE: return changeApplicationUsersState(state,action);
        case actionTypes.CREATE_APPLICATION_USER: return createApplicationUser(state,action);
        case actionTypes.CREATE_APPLICATION_USER_SUCCESS: return createApplicationUserSuccess(state,action);
        case actionTypes.CREATE_APPLICATION_USER_FAILED: return createApplicationUserFailed(state,action);
        case actionTypes.UPDATE_APPLICATION_USER: return updateApplicationUser(state,action);
        case actionTypes.UPDATE_APPLICATION_USER_SUCCESS: return updateApplicationUserSuccess(state,action);
        case actionTypes.UPDATE_APPLICATION_USER_FAILED: return updateApplicationUserFailed(state,action);

        case actionTypes.DELETE_APPLICATION_USER: return deleteApplicationUser(state,action);
        case actionTypes.DELETE_APPLICATION_USER_SUCCESS: return deleteApplicationUserSuccess(state,action);
        case actionTypes.DELETE_APPLICATION_USER_FAILED: return deleteApplicationUserFailed(state,action);
        default:
            return state;

    }
};
