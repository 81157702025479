const globalConfig = require('../assets/globalConfig');

export const BASE_URL = globalConfig.baseUrl;
export const SIGNAL_R_URL = globalConfig.signalRUrl;

export const ROWS_PER_PAGE_OPTIONS = [10, 20, 50, 100];

export const DEFAULT_LIST_SIZE = 20;

export const DRAWER_WIDTH = 240;

export const PENDING_TAB = 'pending';
export const ACTIVE_TAB = 'active';
export const HISTORY_TAB = 'history';

export const DROPZONE_ACCEPTED_FILES = '.pdf, .doc, .docx, .xls, .xlsx, .jpg, image/jpeg, image/jpg, .msg, .bmp, .png, .txt, .eml, .dot' ;

export const TERMS_OF_USE_TITLE = 'Κανόνες Λειτουργίας';
export const SOS_LINE_TITLE = 'Τι είναι η γραμμή SOS 1056?';