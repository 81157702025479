import React, {useCallback} from "react";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import Typography from "@material-ui/core/Typography";
import UserMenu from "./userMenu/userMenu";
import AppBar from "@material-ui/core/AppBar";
import {makeStyles, fade, useTheme} from "@material-ui/core/styles";
import {withRouter} from "react-router-dom";
import {DRAWER_WIDTH} from "../../../helpers/constants";
import {useDispatch} from "react-redux";
import * as action from "../../../actions";
import "./navigationBar.css"
import {colors} from "../../../helpers/colors";

const useStyles = makeStyles(theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: colors.BLUE,
        maxHeight: "80px"
    },
    grow: {
        flexGrow: 1
    },
    appBarShift: {
        marginLeft: DRAWER_WIDTH,
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    title: {
        cursor: 'pointer'
    },
    date:{
      marginLeft: 30
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(12),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
    },
}));


const NavigationBar = (props) =>{
    const {
        isMenuOpen,
        sidebarHandler,
        handleMenu,
        handleClose,
        anchorEl,
        open,
        history,
        closeNotificationList,
        openNotificationList,
        openNotification,
        anchorElNotification,
    } = props;
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const logoutAction = useCallback(
        (payload) => dispatch(action.logout(payload)),
        [dispatch]
    );

    return(
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: isMenuOpen,
            })}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => sidebarHandler()}
                    edge="start"
                    className={clsx(classes.menuButton, {
                        [classes.hide]: isMenuOpen
                    })}
                >
                    <MenuIcon/>
                </IconButton>
                <Typography variant="h6" className={classes.title} noWrap onClick={() => history.push("/")}>
                    {"Chat1056 Admin Panel"}
                </Typography>

                <UserMenu
                    handleMenu={handleMenu}
                    handleClose={handleClose}
                    logoutAction={logoutAction}
                    anchorEl={anchorEl}
                    open={open}
                    closeNotificationList={closeNotificationList}
                    openNotificationList={openNotificationList}
                    openNotification={openNotification}
                    anchorElNotification={anchorElNotification}
                />
            </Toolbar>
        </AppBar>

    )
};


export default withRouter(NavigationBar);