import React, {Component} from 'react';
import './ChatsTabs.css';
import ChatSummaryList from "../ChatSummaryList/ChatSummaryList";
import _ from "lodash";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from '@material-ui/core/Button';
import PieChartIcon from "@material-ui/icons/PieChartOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import TabLabelContent from "../TabLabelContent/tabLabelContent";

class ChatsTabs extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.selectedTab === "pending" && !prevProps.pendingChatsShouldUpdate && this.props.pendingChatsShouldUpdate ){
      this.props.fetchPendingChats();
    } else if(this.props.selectedTab === "active" && !prevProps.activeChatsShouldUpdate && this.props.activeChatsShouldUpdate ){
      this.props.fetchActiveChats();
    } else if(this.props.selectedTab === "history" && !prevProps.historyChatsShouldUpdate && this.props.historyChatsShouldUpdate ){
      this.props.fetchHistoryChats();
    }
  }

  render() {
      const {unreadChatsIds} = this.props;
      const activeChatsIds = this.props.activeChatsIds ? this.props.activeChatsIds : [];
      const pendingChatsIds = this.props.pendingChatsIds ? this.props.pendingChatsIds : [];
      const historyChatsIds = this.props.historyChatsIds ? this.props.historyChatsIds : [];
        let chatsSummaryList = null;
        if(this.props.chats) {

          const viewableChats = () => {
            switch (this.props.selectedTab) {
              case "pending":
                return _.filter(this.props.chats, function (c) {
                  return pendingChatsIds.includes(c.id)
                });
              case "active":
                return _.filter(this.props.chats, function (c) {
                  return activeChatsIds.includes(c.id)
                });
              case "history":
                return _.filter(this.props.chats, function (c) {
                  return historyChatsIds.includes(c.id)
                });
              default:
                return false;
            }
          };
          chatsSummaryList = (<ChatSummaryList
            {...this.props}
            clicked={this.props.selectChat}
            selectedChat={this.props.selectedChatId}
            class='historyTab'
            chats={viewableChats()}
          />);
        }

        let onScrollHistoryList = null;
        if(this.props.selectedTab === 'history'){ onScrollHistoryList = this.props.handleScroll };

        return (
            <React.Fragment>
                <div className='tabsContent' onScroll={onScrollHistoryList}>
                    {chatsSummaryList}
                </div>
                <AppBar position="sticky" color="inherit" className='bottomTabs'>
                    <Tabs value={this.props.selectedTab} onChange={this.props.handleChange}>
                        <Tab
                            value="pending"
                            label={(
                                <TabLabelContent
                                    chatListIds={pendingChatsIds}
                                    unreadChatsIds={unreadChatsIds}
                                    label={"Pending"}
                                />
                            )}
                            wrapped
                        />
                        <Tab
                            value="active"
                            wrapped
                            label={(
                                <TabLabelContent
                                    chatListIds={activeChatsIds}
                                    unreadChatsIds={unreadChatsIds}
                                    label={"Active"}
                                />
                            )}
                        />
                        <Tab value="history" label="History" wrapped />
                    </Tabs>
                    {/*statistics button*/}
                    {this.props.selectedChatId  ? null :
                        <Tooltip title={ !this.props.showStats ? this.props.helpTextOpenStats : this.props.helpTextCloseStats} placement="top">
                            <Button
                                className={['statistics',(this.props.showStats) ? 'opened' : null].join(' ')}
                                onClick={() => this.props.toggleChatStatisticsVisibility({value:!this.props.showStats})}
                            >
                                <PieChartIcon/>  Statistics
                            </Button>
                        </Tooltip>
                    }
                </AppBar>
            </React.Fragment>
        );
    }
}

export default ChatsTabs;
