import React, {Component} from 'react';
import './ChatsReport.css';

import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SendReportEmail from "./SendReportEmail/SendReportEmail";
import PrintReport from "./PrintReport/PrintReport";
import moment from 'moment';
import _ from "lodash";
import Box from "@material-ui/core/Box";


class ChatsReport extends Component{
    constructor(props) {
        super(props);
        this.state = {
            printChat: false,
            printAge: false,
            printIP: false,
            printId: false,
            printNotes: false,
            startDate: new Date(),
            endDate: new Date()
        };
    }

    handleChangeOption = (event) => {
        this.setState({...this.state, [event.target.value]: event.target.checked});
    };

    handleChangeDate(e, type) {
        const nextState = {};
        nextState[type] = e;
        this.setState(nextState);
    }

    report(){
        const {startDate,endDate,printId,printAge,printChat,printNotes, printIP} = this.state;
        const {selectedChatId,chats,agesCodes,childInfo,allChatIdsOfCurrentChild} = this.props;

        // chatId
        let reportId = null;
        if(printId){
            reportId = ({
                style: 'tableChatId',
                table: {
                    widths: ['auto', '*'],
                    body: [
                        ['ChatId:', selectedChatId],
                    ]
                },
                layout: 'noBorders'
            });
        }

        // age
        let childInfoAgeDisplayed = null;
        const childAge = childInfo[chats[selectedChatId].childId].age;
        childInfoAgeDisplayed = _.find(agesCodes, function (c) {
            return c.code === childAge
        });
        let age = null;
        if(printAge && childInfoAgeDisplayed){
            age = ({
                style: 'tableAgeId',
                table: {
                    widths: ['auto', '*'],
                    body: [
                        ['Age:', childInfoAgeDisplayed.name ],
                    ]
                },
                layout: 'noBorders'
            });
        }

        let ip = null;
        if(printIP){
            const childIP = (
                selectedChatId
                && chats
                && chats[selectedChatId]
                && chats[selectedChatId].childId
                && childInfo
                && childInfo[chats[selectedChatId].childId]
                && childInfo[chats[selectedChatId].childId].ipAddress
            )  ? childInfo[chats[selectedChatId].childId].ipAddress : '';
            ip = ({
                table: {
                    style: 'tableAgeId',
                    widths: ['auto', '*'],
                    body: [
                        ['IP:', childIP ],
                    ]
                },
                layout: 'noBorders'
            });
        }

        // chats
        let chatsTitle = [];
        let messages = [];
        if(printChat){
            chatsTitle = ({text: 'Chats', style: 'subheader'});

            //messages of each chat
            if(allChatIdsOfCurrentChild) {
                allChatIdsOfCurrentChild.map(chatId => {
                   chats[chatId] &&
                   chats[chatId].messages && chats[chatId].messages.length > 0 ?
                       chats[chatId].messages.map(message => {
                           if (moment(message.createdDate).isSameOrAfter(startDate, 'days') &&
                               moment(message.createdDate).isSameOrBefore(endDate, 'days')) {
                               if (message === chats[chatId].messages[0]) {
                                   messages.push(
                                       {
                                           text: moment(chats[chatId].startTimestamp).format('dddd, DD MMM YYYY'),
                                           style: 'subheaderDate'
                                       }
                                   )
                               }
                               messages.push(
                                   {
                                       style: message.isAgent ? 'tableChatsAgent' : 'tableChatsChild',
                                       table: {
                                           widths: ['*'],
                                           body: [
                                               [message.body],
                                               [moment(message.createdDate).format('HH:mm')]
                                           ]
                                       },
                                       layout: {
                                           hLineWidth: function (i, node) {
                                               return (i === 0 || i === node.table.body.length) ? 2 : 1;
                                           },
                                           vLineWidth: function (i, node) {
                                               return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                                           },
                                           hLineColor: function (i, node) {
                                               return (i === 0 || i === node.table.body.length) ? 'black' : message.isAgent ? '#d6ebf5' : 'white';
                                           },
                                           vLineColor: function (i, node) {
                                               return (i === 0 || i === node.table.widths.length) ? 'black' : message.isAgent ? '#d6ebf5' : 'white';
                                           },
                                       },
                                       unbreakable: true
                                   }
                               )
                           }
                       })
                   : messages.push(
                       {
                           text: moment(chats[chatId].createdDate).format('dddd, DD MMM YYYY'),
                           style: 'subheaderDate'
                       },
                       {text: 'Δεν υπάρχουν μηνύματα.', style: 'subContent'}
                   )
                });
            }
            if(messages.length <= 0){
                messages.push(
                    {text: 'Δεν υπάρχουν μηνύματα.', style: 'subContent'}
                )
            }
        }

        // notes
        let notesTitle = [];
        let notes = [];

        if(printNotes){
            notesTitle = ({text: 'Notes', style: 'subheader'});

            //notes of each chat
            if(allChatIdsOfCurrentChild) {
                allChatIdsOfCurrentChild.map(chatId => {
                    chats[chatId] &&
                    chats[chatId].notes && chats[chatId].notes.length > 0 ?
                        chats[chatId].notes.map((note,index) => {
                            if (moment(note.createdDate).isSameOrAfter(startDate, 'days') &&
                                moment(note.createdDate).isSameOrBefore(endDate, 'days')) {
                                if (note === chats[chatId].notes[0]) {
                                    notes.push(
                                        {
                                            text: 'Chat '+moment(chats[chatId].startTimestamp).format('dddd, DD MMM YYYY'),
                                            style: 'subheaderDate'
                                        }
                                    )
                                }
                                notes.push(
                                    {
                                        style: 'tableNotes',
                                        table: {
                                            widths: ['*','auto'],
                                            body: [
                                                [
                                                    {text:'Σημείωση #'+(index+1)},
                                                    {
                                                        text: moment(note.createdDate).format('dddd, DD MMM YYYY HH:mm'),
                                                        style: 'noteDate'
                                                    }
                                                ],
                                                [
                                                    {
                                                        text: note.text,
                                                        colSpan: 2
                                                    }
                                                ]
                                            ]
                                        },
                                        layout: {
                                            fillColor: function (rowIndex, node, columnIndex) {
                                                return (rowIndex % 2 === 0) ? '#6197e9' : null;
                                            },
                                            color: function (rowIndex, node, columnIndex) {
                                                return (rowIndex % 2 === 0) ? 'white' : 'red';
                                            },
                                            vLineColor: function (i, node) {
                                                return (i === 1 ) ? '#6197e9' : null;
                                            },
                                        },
                                        unbreakable: true
                                    }
                                )
                            }
                        })
                        : notes.push(
                            {
                                text: 'Chat '+moment(chats[chatId].createdDate).format('dddd, DD MMM YYYY'),
                                style: 'subheaderDate'
                            },
                            {text: 'Δεν υπάρχουν σημειώσεις.', style: 'subContent'}
                        )
                });
            }
            if(notes.length <= 0){
                notes.push(
                    {text: 'Δεν υπάρχουν σημειώσεις.', style: 'subContent'}
                )
            }
        }
        // pdf range date
        let rangeDate = null;
        if(moment(startDate).isSame(endDate)){
            rangeDate = moment(startDate).format('dddd, DD MMM YYYY')
        }else{
            rangeDate = moment(this.state.startDate).format('dddd, DD MMM YYYY')+' - '+moment(this.state.endDate).format('dddd, DD MMM YYYY')
        }

        const report =
            { content:
                [
                    {text: 'Chat Summary', style: 'header'},
                    {text: rangeDate, style: 'subheader'},

                    reportId,
                    age,
                    ip,
                    chatsTitle,
                    messages,
                    notesTitle,
                    notes,
                ],
            styles:
                {
                    header: {
                        fontSize: 18,
                        bold: true,
                        margin: [0, 0, 0, 0],
                        alignment: 'center'
                    },
                    subheader: {
                        fontSize: 15,
                        bold: true,
                        margin: [0, 5, 0, 10],
                        alignment: 'center'
                    },
                    subheaderDate: {
                        fontSize: 10,
                        margin: [0, 5, 0, 10],
                        alignment: 'center',
                        color:'#999'
                    },
                    subContent: {
                        fontSize: 12,
                        margin: [0, 5, 0, 10],
                        alignment: 'center'
                    },
                    tableChatId: {
                        margin: [0, 5, 0, 15]
                    },
                    tableAge: {
                        margin: [0, 5, 0, 15]
                    },
                    tableChatsChild:{
                        margin: [0, 2, 0, 2],
                        cellBorder: 0
                    },
                    tableChatsAgent:{
                        margin: [0, 2, 0, 2],
                        cellBorder: 0,
                        alignment: 'right',
                        fillColor: '#d6ebf5'
                    },
                    tableNotes:{
                        margin: [0, 2, 0, 2],
                        cellBorder: 0
                    },
                    noteDate:{
                        fontSize: 9
                    }
                },
            }
        ;
        return report;
    };

    render() {
        const {printChat,printId, printAge, printNotes, startDate, endDate, printIP} = this.state;
        const actionsDisabled = !printChat && !printId && !printAge && !printNotes && !printIP;
        return (
            <div className='printChatsOptions' >

                <Box display={"flex"} flexDirection={"column"}>
                   <Box display={"flex"}>
                       <Grid item xs={4}>
                           <DatePicker
                               dateFormat="dd/MM/yyyy"
                               selected={startDate}
                               onChange={e => this.handleChangeDate(e, 'startDate')}
                               selectsStart
                               startDate={startDate}
                               endDate={endDate}
                           />
                       </Grid>
                       <Grid item xs={4}>
                           <DatePicker
                               dateFormat="dd/MM/yyyy"
                               selected={endDate}
                               onChange={e => this.handleChangeDate(e, 'endDate')}
                               selectsEnd
                               endDate={endDate}
                               minDate={startDate}
                           />
                       </Grid>
                   </Box>
                   <Box display={"flex"} flexDirection={"row"}>
                       <Grid item xs={4}>
                           <FormGroup row>
                               <FormControlLabel
                                   control={
                                       <Checkbox
                                           checked={printChat}
                                           onChange={this.handleChangeOption}
                                           value="printChat"
                                           color="primary"
                                       />
                                   }
                                   label="Chat"
                               />
                           </FormGroup></Grid>
                       <Grid item xs={4}>
                           <FormControlLabel
                               control={
                                   <Checkbox
                                       checked={printAge}
                                       onChange={this.handleChangeOption}
                                       value="printAge"
                                       color="primary"
                                   />
                               }
                               label="Age"
                           />
                       </Grid>
                       <Grid item xs={4}>
                           <FormControlLabel
                               control={
                                   <Checkbox
                                       checked={printIP}
                                       onChange={this.handleChangeOption}
                                       value="printIP"
                                       color="primary"
                                   />
                               }
                               label="IP"
                           />
                       </Grid>
                   </Box>
                   <Box display={"flex"} flexDirection={"row"}>
                       <Grid item xs={4}>
                           <FormControlLabel
                               control={
                                   <Checkbox
                                       checked={printId}
                                       onChange={this.handleChangeOption}
                                       value="printId"
                                       color="primary"
                                   />
                               }
                               label="Id"
                           />
                       </Grid>
                       <Grid item xs={4}>
                           <FormGroup row>
                               <FormControlLabel
                                   control={
                                       <Checkbox
                                           checked={printNotes}
                                           onChange={this.handleChangeOption}
                                           value="printNotes"
                                           color="primary"
                                       />
                                   }
                                   label="Notes"
                               />
                           </FormGroup>
                       </Grid>
                   </Box>
                    <Box display={"flex"} flexDirection={"row"}>
                        <Grid item xs={4}>
                            <PrintReport
                                disabled={actionsDisabled}
                                chatId={this.props.selectedChatId}
                                reportContent={this.report()}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <SendReportEmail
                                sendEmail={this.props.sendEmail}
                                disabled={actionsDisabled}
                                chatId={this.props.selectedChatId}
                                reportContent={this.report()}
                                emailStatus={this.props.sendEmailStatus}
                                textSendEmailSuccess='To email στάλθηκε επιτυχώς.'
                                textSendEmailFailure='To email δεν στάλθηκε επιτυχώς. Προσπαθήστε ξανά.'
                                clearSendEmailStatus={this.props.clearSendEmailStatus}
                            />
                        </Grid>
                    </Box>
                </Box>
            </div>
        );
    }
}

export default ChatsReport;