import React, {Component} from 'react';
import ChatsReport from "../../components/ChatsReport/ChatsReport";
import {connect} from "react-redux";
import * as actions from "../../actions";

class ChatsReportHOC extends Component{
    render() {
        return (
            <ChatsReport {...this.props}  />
        )
    }
}

const mapStateToProps = (state) => ({
    childInfo: state.childUsers.byId,
    agesCodes: state.general.ages,
    selectedChatId: state.chats.selectedChatId,
    chats: state.chats.byId,
    allChatIdsOfCurrentChild: state.chats.allChatIdsOfCurrentChild,
    sendEmailStatus: state.chats.sendEmailStatus,
});

const mapDispatchToProps = dispatch => {
    return {
        sendEmail: (payload) => {dispatch(actions.sendChatsReport(payload))},
        clearSendEmailStatus: (payload) => {dispatch(actions.clearSendChatsReportStatus(payload))}
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(ChatsReportHOC);