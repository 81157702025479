import React from 'react';
import Message from './message';
import ChatTitle from "./chatTitle";
import _ from "lodash";


class ChatContainer extends React.Component {
  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  };

  render() {
    //TODO: don't know why ordering them on the reducer is not enough
    const { messages } = this.props;
    return (
      <div className="chatContentContainer">
        <div
          className="chatContent"
        >
          {messages[0] && messages[0].createdDate &&
            <ChatTitle createdDate={messages[0].createdDate}/>
          }
          {_.orderBy(messages, 'createdDate', 'asc').map(message => (
            <Message
              key={message.id}
              createdDate={message.createdDate}
              body={message.body}
              isMine={message.isAgent}
              startsSequence={true}
              endsSequence={true}
              showTimestamp={true}
              sendingInProgress={message.sendingInProgress}
            />))}
        </div>
        <div
          style={{ float:"left", clear: "both" }}
          ref={(el) => { this.messagesEnd = el; }}>
        </div>
      </div>
    );
  }
}

export default ChatContainer;
