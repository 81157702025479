import React, {Component} from 'react';
import './SendReportEmail.css';

import {Modal} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import moment from 'moment';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class SendReportEmail extends Component{
    constructor(props) {
        super(props);
        this.state = {
            modalVisibility: false,
            email: '',
            subject: '',
            message: '',
            valid: ''
        };
    }

    handleModalVisibility = () => {
        this.setState({...this.state, modalVisibility: !this.state.modalVisibility});
        // dispatch action to null sendEmailStatus
        if(this.props.emailStatus !== null && this.state.modalVisibility) {
            this.props.clearSendEmailStatus();
        }
    };

    changeFormValue(e, type) {
        const value = e.target.value;
        const nextState = {};
        nextState[type] = value;
        this.setState(nextState);
    }

    sendEmail = (email,subject,message,chatId,emailContent) => {
        // createPdf
        const docDefinition = {
            info: {
                title: 'Chat Report '+moment().format("DD-MM-YYYY"),
                author: 'Chat1056'
            },
            content: emailContent.content,
            styles: emailContent.styles
        };

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        let pdfBase64 = null;
        pdfDocGenerator.getBase64(function(encodedString) {
            pdfBase64 = encodedString;

            // dispatch action
            this.props.sendEmail({
                chatId:chatId,
                body:{
                    "encodedAttachment": pdfBase64,
                    "recipient": email,
                    "subject": subject,
                    "body": message
                }
            });
        }.bind(this));
    };

    render() {
        const {email,subject,message,modalVisibility} = this.state;

        let modalContent = null;
        if (!this.props.emailStatus){
            modalContent = (
                <div className='email-form'>
                    <Typography variant='h5' align={"center"} component='div'>
                        Συμπληρώστε την παρακάτω φόρμα
                    </Typography>
                    <ValidatorForm
                        onSubmit={()=>this.sendEmail(email,subject,message,this.props.chatId,this.props.reportContent)}
                    >
                        <TextValidator
                            ref={(email) => { this.email = email; }}
                            fullWidth
                            margin="dense"
                            variant="filled"
                            color="primary"
                            label="To"
                            onChange={e => this.changeFormValue(e, 'email')}
                            name="email"
                            value={email}
                            validators={['required', 'isEmail']}
                            errorMessages={['this field is required', 'email is not valid']}
                        />
                        <TextValidator
                            ref={(subject) => { this.subject = subject; }}
                            fullWidth
                            margin="dense"
                            variant="filled"
                            color="primary"
                            label="Subject"
                            onChange={e => this.changeFormValue(e, 'subject')}
                            name="subject"
                            value={subject}
                            validators={['required']}
                            errorMessages={['this field is required']}
                        />
                        <TextValidator
                            ref={(message) => { this.message = message; }}
                            fullWidth
                            margin="dense"
                            variant="filled"
                            color="primary"
                            multiline
                            rows="4"
                            label="Message"
                            onChange={e => this.changeFormValue(e, 'message')}
                            name="message"
                            value={message}
                            validators={['required']}
                            errorMessages={['this field is required']}
                        />
                        <Grid container alignItems="center" justify="center" direction="row">
                            <Button type="submit"
                                color='primary'>
                                Αποστολη
                            </Button>
                        </Grid>
                    </ValidatorForm>
                </div>
            )
        }
        if (this.props.emailStatus === 'loading'){
            modalContent = (
                <div className='sendEmail-loading'>
                    <Typography variant='h5' align={"center"} component='div'>
                        <CircularProgress className='spinner'/>
                    </Typography>
                </div>
            )
        }else if(this.props.emailStatus === 'success'){
            modalContent = (
                <div className='sendEmail-success'>
                    <Typography variant='h5' align={"center"} component='div'>
                        {this.props.textSendEmailSuccess}
                    </Typography>
                    <Button aria-label="close" color='secondary' onClick={this.handleModalVisibility}>
                        Κλεισιμο
                    </Button>
                </div>
            )
        }else if(this.props.emailStatus === 'failure'){
            modalContent = (
                <div className='sendEmail-failure'>
                    <Typography variant='h5' align={"center"} component='div'>
                        {this.props.textSendEmailFailure}
                    </Typography>
                    <Button aria-label="close" color='secondary' onClick={this.handleModalVisibility}>
                        Κλεισιμο
                    </Button>
                </div>
            )
        }

        return(
            <div>
                <Button
                    disabled={this.props.disabled}
                    onClick={this.handleModalVisibility} color='primary'>
                    Αποστολη
                </Button>
                <Modal open={modalVisibility} onClose={this.handleModalVisibility} className='modal-email'>
                        {modalContent}
                </Modal>
            </div>
        )
    }
}

export default SendReportEmail;