import * as actionTypes from './types';
import {actionCreator} from "../shared/utility";

export const authAgentStart = actionCreator(actionTypes.AUTH_START);

export const authAgentSuccess = actionCreator(actionTypes.AUTH_SUCCESS);

export const authAgentFail = actionCreator(actionTypes.AUTH_FAIL);

export const authOnlineToggle = actionCreator(actionTypes.AUTH_ONLINE_TOGGLE);
export const authOnlineToggleSuccess = actionCreator(actionTypes.AUTH_ONLINE_TOGGLE_SUCCESS);
export const authOnlineToggleFailed = actionCreator(actionTypes.AUTH_ONLINE_TOGGLE_FAILED);

export const userIsUnauthorized = actionCreator(actionTypes.USER_IS_UNAUTHORIZED);

export const logout = actionCreator(actionTypes.LOGOUT);

export const fetchUserStatistics = actionCreator(actionTypes.FETCH_USER_STATISTICS);
export const fetchUserStatisticsFailed = actionCreator(actionTypes.FETCH_USER_STATISTICS_FAILED);
export const storeUserStatistics = actionCreator(actionTypes.STORE_USER_STATISTICS);

export const fetchCurrentUserInfo = actionCreator(actionTypes.FETCH_CURRENT_USER_INFO);
export const fetchCurrentUserInfoSuccess = actionCreator(actionTypes.FETCH_CURRENT_USER_INFO_SUCCESS);
export const fetchCurrentUserInfoFailed = actionCreator(actionTypes.FETCH_CURRENT_USER_INFO_FAILED);

export const updateCurrentUserInfo = actionCreator(actionTypes.UPDATE_CURRENT_USER_INFO);
export const updateCurrentUserInfoSuccess = actionCreator(actionTypes.UPDATE_CURRENT_USER_INFO_SUCCESS);
export const updateCurrentUserInfoFailed = actionCreator(actionTypes.UPDATE_CURRENT_USER_INFO_FAILED);

export const changeUserState = actionCreator(actionTypes.CHANGE_USER_STATE);