import React from "react";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../SnackbarContentWrapper/snackbarContentWrapper";

const DeleteUserConfirmation = (props) => {
    const {deleteResponseMessage, onDeleteResponseMessageDismiss, selectedUser} = props;

    return (
        <div>
            <Typography variant={"body1"}>
                {`Are you sure you want to delete the selected user (email: ${selectedUser.email}) ?`}
            </Typography>
            {deleteResponseMessage && (
                <Snackbar
                    open={true}
                    autoHideDuration={6000}
                    onClose={onDeleteResponseMessageDismiss}
                >
                    <MySnackbarContentWrapper
                        onClose={onDeleteResponseMessageDismiss}
                        variant="info"
                        message={deleteResponseMessage}
                    />
                </Snackbar>
            )}
        </div>
    )
};

export default DeleteUserConfirmation;