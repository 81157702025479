import * as actionTypes from './types';
import {actionCreator} from "../shared/utility";

// add note
export const addNewChatNote = actionCreator(actionTypes.ADD_NEW_CHAT_NOTE);
export const addNewChatNoteSuccess = actionCreator(actionTypes.ADD_NEW_CHAT_NOTE_SUCCESS);
export const addNewChatNoteFailure = actionCreator(actionTypes.ADD_NEW_CHAT_NOTE_FAILURE);
export const clearAddNewChatNoteStatus = actionCreator(actionTypes.CLEAR_ADD_NEW_CHAT_NOTE_STATUS);

// fetch notes
export const fetchChatNotes = actionCreator(actionTypes.FETCH_CHAT_NOTES);
export const storeChatNotes = actionCreator(actionTypes.STORE_CHAT_NOTES);
export const fetchChatNotesFailure = actionCreator(actionTypes.FETCH_CHAT_NOTES_FAILURE);