import React, {Component} from 'react';
import ChatsHistoryNotes from "../../components/ChatsHistoryNotes/ChatsHistoryNotes";

class ChatsHistoryNotesHOC extends Component{
    render() {
        const { chats, allChatIdsOfCurrentChild } = this.props;
        return (
            <div className='historyNotesSection'>
            {
                allChatIdsOfCurrentChild && allChatIdsOfCurrentChild.map(chatId =>(
                    chats[chatId] &&
                    chats[chatId].notes && chats[chatId].notes.length > 0 &&
                    <ChatsHistoryNotes {...this.props} key={chatId} notes={chats[chatId].notes} chatId={chatId} />
                ))
            }
            </div>
        )
    }
}

export default ChatsHistoryNotesHOC;
