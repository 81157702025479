import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from "lodash";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import IconButton from "@material-ui/core/IconButton";
import Grid from '@material-ui/core/Grid';
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

const AccordionList = (props) => {
    const {
        data,
        columns,
        actions,
        accordionItemHeaderFormatter,
        selectable,
        onSelectRowClick,
        selected
    } = props;
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const hasActions = !!actions;

    return (
        <div className={classes.root}>
            {data.map((datum, index) => (
                <ExpansionPanel expanded={expanded === index} onChange={handleChange(index)} key={index}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography className={classes.heading}>
                            {
                                accordionItemHeaderFormatter ?
                                    accordionItemHeaderFormatter(datum) : `Item #${index + 1}`
                            }
                        </Typography>
                        <Typography className={classes.secondaryHeading}></Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div style={{flexGrow: 1}}>
                            {columns.map((column, index) => {
                                return (
                                    <Grid container spacing={3} direction={"row"} key={index}>
                                        <Grid item sm={4}>
                                            <Typography variant={"body1"}>
                                                {column.header}
                                            </Typography>
                                        </Grid>
                                        <Grid item sm={8}>
                                            <Typography variant={"body2"}>
                                                {
                                                    column.formatter ?
                                                        column.formatter(datum)
                                                        :
                                                        _.get(datum, column.propertyName)
                                                }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                            {selectable && (
                                <Checkbox
                                    checked={selectable ? selected.includes(index) :  false}
                                    onClick={() => onSelectRowClick(index)}
                                />
                            )}
                        </div>
                    </ExpansionPanelDetails>
                    {hasActions && (
                        <ExpansionPanelActions>
                            {
                                actions.map((action, index) => (
                                    <IconButton onClick={() => action.callback(datum)} key={index}>
                                        {action.icon}
                                    </IconButton>
                                ))
                            }
                        </ExpansionPanelActions>
                    )}

                </ExpansionPanel>
            ))}
        </div>
    )
};

export default AccordionList;