import React from "react";
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState } from 'draft-js';

export const parseError = (error) => {
    if(
        error
        && error.length > 0
        && error[0].response
        && error[0].response.body
        && error[0].response.body.errors
        && error[0].response.body.errors.length > 0
    ){
        return (
            <ul>
                {error[0].response.body.errors.map((e, index) => <li key={index}>{e}</li>)}
            </ul>
        )
    }

    if(
        error
        && error.length > 0
        && error[0].response
        && error[0].response.body
        && error[0].response.body.message
    ){
        return error[0].response.body.message
    }

    if(
        error
        && error.response
        && error.response.body
        && error.response.body.message
    ){
        return error.response.body.message
    }

    if(
        error
        && error.message
    ){
        return error.message
    }

    return "";
};

export const readFileAsBase64Async = (file) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result.substring(reader.result.indexOf("base64,") + 7));
        };

        reader.onerror = reject;
    });
};

export const convertHtmlToDraftState = (htmlString) => {
    const contentBlock = htmlToDraft(htmlString);
    if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        return editorState
    }
    else {
        return EditorState.createEmpty()
    }
};

export const stripHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const rawText = doc.body.textContent || "";
    return rawText;
};
