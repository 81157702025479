import {headersWithTokenJson, loginHeader} from './headers';
import xs from 'xstream';
import {BASE_URL} from "../helpers/constants";
import * as actions from "../actions";
import * as actionTypes from "../actions/types";
import sampleCombine from "xstream/extra/sampleCombine";
import {push} from "connected-react-router";
import delay from "xstream/extra/delay";

export function requestUpdateCurrentUserInfo(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.user.token);
    const request$ = sources.ACTION
        .filter(action => action.type === actionTypes.UPDATE_CURRENT_USER_INFO)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) =>  ({
                url: BASE_URL + `ApplicationUsers/me`,
                category: 'requestUpdateCurrentUserInfo',
                method: 'PATCH',
                send: action.payload.sendBody,
                headers: headersWithTokenJson(token),
                withCredentials: true
            })
        );

    let httpResponse$ = sources.HTTP
        .select('requestUpdateCurrentUserInfo')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 204)
        .map(response => actions.updateCurrentUserInfoSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    };
}

export function requestUpdateCurrentUserInfoFailed(sources) {
    const response$ = sources.HTTP
        .select('requestUpdateCurrentUserInfo')
        .map(response =>
            response.replaceError(err => xs.of(err)))
        .flatten()
        .filter(response => response.status !== 204);

    const action$ = xs.combine(response$)
        .map(response => actions.updateCurrentUserInfoFailed(response));

    return {
        ACTION: action$
    };
}

export function redirectAfterLogout(sources) {
    const action$ = sources.ACTION
        .filter(action => action.type === actionTypes.LOGOUT)
        .map(action => push('/'));

    return {
        ACTION: action$,
    }
}

export function requestCurrentUserInfoAfterLogin(sources) {
    const action$ = sources.ACTION
        .filter(action => action.type === actionTypes.AUTH_SUCCESS)
        .map(response => actions.fetchCurrentUserInfo(response));
    return {
        ACTION: action$,
    };
}

export function requestCurrentUserInfo(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.user.token);
    const request$ = sources.ACTION
        .filter(action => action.type === actionTypes.FETCH_CURRENT_USER_INFO)
        .compose(sampleCombine(token$))
        .map(([action, token]) =>  ({
                url: BASE_URL + `ApplicationUsers/me`,
                category: 'requestCurrentUserInfo',
                method: 'GET',
                headers: headersWithTokenJson(token),
                withCredentials: true
            })
        );

    let httpResponse$ = sources.HTTP
        .select('requestCurrentUserInfo')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.fetchCurrentUserInfoSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    };
}

export function requestCurrentUserInfoFailed(sources) {
    const response$ = sources.HTTP
        .select('requestCurrentUserInfo')
        .map(response =>
            response.replaceError(err => xs.of(err)))
        .flatten()
        .filter(response => response.status !== 200);

    const action$ = xs.combine(response$)
        .map(response => actions.fetchCurrentUserInfoFailed(response));

    return {
        ACTION: action$
    };
}

export function login(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === actionTypes.AUTH_START)
        .map((action) => ({
            url: BASE_URL + 'ApplicationUsers/login',
            category: 'ApplicationUsers',
            method: 'POST',
            headers: loginHeader(),
            send: action.payload.sendBody,
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('ApplicationUsers')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.authAgentSuccess(response))
    ;

    return {
        ACTION: httpResponse$,
        HTTP: request$
    };
}

export function loginFailed(sources) {
    let httpResponse$ = sources.HTTP
        .select('ApplicationUsers')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.authAgentFail(response));
    return {
        ACTION: httpResponse$
    }
}

export function fetchUserStatistics(sources) {
  const state$ = sources.STATE;
  const token$ = state$.map(state => state.user.token);
  const request$ = sources.ACTION
    .filter(action => action.type === actionTypes.FETCH_USER_STATISTICS)
    .compose(sampleCombine(token$))
    .map(([action, token]) =>  ({
        url: BASE_URL + `ApplicationUsers/agents/me/ChatStatistics`,
        category: 'fetchUserStatistics',
        method: 'GET',
        headers: headersWithTokenJson(token),
        withCredentials: true
      })
    );

  let httpResponse$ = sources.HTTP
    .select('fetchUserStatistics')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200)
    .map(response => actions.storeUserStatistics(response));

  return {
    ACTION: httpResponse$,
    HTTP: request$
  };
}

export function fetchUserStatisticsFailed(sources) {
  const response$ = sources.HTTP
    .select('fetchUserStatistics')
    .map(response =>
      response.replaceError(err => xs.of(err)))
    .flatten()
    .filter(response => response.status !== 200);

  const action$ = xs.combine(response$)
    .map(response => actions.fetchUserStatisticsFailed(response));

  return {
    ACTION: action$
  };
}

export function changeUserAvailability(sources) {
  const state$ = sources.STATE;
  const user$ = state$.map(state => state.user);
  const request$ = sources.ACTION
    .filter(action => action.type === actionTypes.AUTH_ONLINE_TOGGLE)
    .compose(sampleCombine(user$))
    .map(([action, user]) =>  ({
        url: BASE_URL + `ApplicationUsers/agents/ChangeAvailability`,
        category: 'changeUserAvailability',
        method: 'PATCH',
        headers: headersWithTokenJson(user.token),
        send: {isAvailable: action.payload.isAvailable},
        withCredentials: true
      })
    );

  let httpResponse$ = sources.HTTP
    .select('changeUserAvailability')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 204)
    .map(response => actions.authOnlineToggleSuccess(response));

  return {
    ACTION: httpResponse$,
    HTTP: request$
  };
}

export function changeUserAvailabilityFailed(sources) {
  const response$ = sources.HTTP
    .select('changeUserAvailability')
    .map(response =>
      response.replaceError(err => xs.of(err)))
    .flatten()
    .filter(response => response.status !== 204);

  const action$ = xs.combine(response$)
    .map(response => actions.authOnlineToggleFailed(response));

  return {
    ACTION: action$
  };
}


export function mapAuthOnlineToggleSuccessToFetchMe(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === actionTypes.AUTH_ONLINE_TOGGLE_SUCCESS)
    .map(response => actions.fetchCurrentUserInfo(response));

  return {
    ACTION: action$
  };
}


