import React from 'react';
import './ChatDetails.css';
import Grid from "@material-ui/core/Grid";
import AvatarInitials from "../AvatarInitials/AvatarInitials";
import {Typography} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Cake from '@material-ui/icons/Cake';
import Connection from '@material-ui/icons/CompareArrows';
import MarkerIp from '@material-ui/icons/PlaceOutlined';
import PersonId from '@material-ui/icons/PersonOutline';
import Subject from '@material-ui/icons/SubtitlesOutlined';
import SectionTitle from "../SectionTitle/SectionTitle";
import Moment from 'react-moment';
import 'moment/locale/el';
import Feeling from "../Feeling/Feeling";
import moment from "moment";

const ChatDetails = (props) => {
  return (
    <div className='chatDetails'>
      <div className='basicInfo'>
        <Container>
          <Grid container spacing={0}>
            <Grid item xs={3} >
              <AvatarInitials name={props.userInfo.displayName} maxInitials={2} bgColor="#556080"/>
            </Grid>
            <Grid item xs={8}>
              <Typography variant='subtitle1' component='div' className='userName'>{props.userInfo.displayName}</Typography>

            </Grid>
          </Grid>
        </Container>
      </div>
      <div className='detailedInfo'>
        <Container>
          <SectionTitle title='Σχετικά'/>
          {/*age*/}
          <Grid container spacing={1} className='detailsGrid'>
            <Grid item xs={1}>
              <Cake/>
            </Grid>
            <Grid item xs={10}>
              <Typography  variant="caption" component='div' className='details'>
                Ηλικία {props.userInfoAge}
              </Typography>
            </Grid>
          </Grid>
          {/*feeling*/}
          <Feeling withIconText feelingInfo={props.chatStartFeelingInfo} when="start"/>
          <Feeling withIconText feelingInfo={props.chatEndFeelingInfo} when="end"/>
          {/*last active*/}
          <Grid container spacing={1} className='detailsGrid'>
            <Grid item xs={1}>
              <Connection/>
            </Grid>
            <Grid item xs={10}>
              {props.userInfo && props.userInfo.applicationUserLogins && props.userInfo.applicationUserLogins.length > 0 ?
                <Typography  variant="caption" component='div' className='details'>
                  Τελευταία σύνδεση στις
                  {moment(props.userInfo.applicationUserLogins[props.userInfo.applicationUserLogins.length - 1].timestamp).format('DD/MM/YY, HH:mm')}
                </Typography>
                : null}
              {props.userInfo && props.userInfo.applicationUserLogins && props.userInfo.applicationUserLogins.length > 1 ?
                <Typography  variant="caption" component='div' className='details'>
                  Προηγούμενη σύνδεση στις
                  {moment(props.userInfo.applicationUserLogins[props.userInfo.applicationUserLogins.length - 2].timestamp).format('DD/MM/YY, HH:mm')}
                </Typography>
                : null}
            </Grid>
          </Grid>
          {/*ipAddress*/}
          <Grid container spacing={1} className='detailsGrid'>
            <Grid item xs={1}>
              <MarkerIp/>
            </Grid>
            <Grid item xs={10}>
              <Typography  variant="caption" component='div' className='details'>
                Η ip του χρήστη είναι {props.userInfo.ipAddress}
              </Typography>
            </Grid>
          </Grid>
          {/*userId*/}
          <Grid container spacing={1} className='detailsGrid'>
            <Grid item xs={1}>
              <PersonId/>
            </Grid>
            <Grid item xs={10}>
              <Typography  variant="caption" component='div' className='details'>
                Το ID του χρήστη είναι <span className="coloredValue">{props.userInfo.id}</span>
              </Typography>
            </Grid>
          </Grid>
          {/*subject*/}
          <Grid container spacing={1} className='detailsGrid'>
            <Grid item xs={1}>
              <Subject/>
            </Grid>
            <Grid item xs={10}>
              <Typography  variant="caption" component='div' className='details'>
                Θέμα: {props.chatInfo.subject}
              </Typography>
            </Grid>
          </Grid>

        </Container>
      </div>
    </div>
  )
};

export default ChatDetails;
