import {headersWithTokenJson} from './headers';
import xs from 'xstream';
import {BASE_URL} from "../helpers/constants";
import * as actions from "../actions";
import * as actionTypes from "../actions/types";

export function fetchChatStatistics(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === actionTypes.FETCH_CHAT_STATISTICS)
      .map((action) =>  ({
          url: BASE_URL + 'chats/statistics',
          category: 'fetchChatStatistics',
          method: 'GET',
          headers: headersWithTokenJson(action.payload),
          withCredentials: true
        })
      );

    let httpResponse$ = sources.HTTP
        .select('fetchChatStatistics')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.storeChatStatistics(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    };
}

export function fetchChatStatisticsFailed(sources) {
    const response$ = sources.HTTP
        .select('fetchChatStatistics')
        .map(response =>
            response.replaceError(err => xs.of(err)))
        .flatten()
        .filter(response => response.status !== 200);

    const action$ = xs.combine(response$)
        .map(response => actions.fetchChatStatisticsFailed(response));

    return {
        ACTION: action$
    };
}
