import React from 'react';
import {Redirect, Route, Switch} from "react-router";
import ApplicationUsersHOC from "../containers/ApplicationUsersHOC/ApplicationUsersHOC";
import ApplicationUserDetailsHOC from "../containers/ApplicationUserDetailsHOC/ApplicationUserDetailsHOC";
import PagesHOC from "../containers/PagesHOC/PagesHOC";
import PageDetailsHOC from "../containers/PageDetailsHOC/PageDetailsHOC";

const AdminContentRouter = (props) => {

    return (
        <Switch>
            <Route exact path='/pages/:id' component={PageDetailsHOC}/>
            <Route path='/pages' component={PagesHOC}/>
            <Route exact path='/users/:id' component={ApplicationUserDetailsHOC}/>
            <Route path='/users'  component={ApplicationUsersHOC}/>/>
            <Route path='/'  render={() => <Redirect to={'/users'} />}/>/>
        </Switch>
    );

};

export default AdminContentRouter;
