import React from 'react';
import './SectionTitle.css';

import Typography from "@material-ui/core/Typography";

const SectionTitle = (props) => {
  return(
      <Typography  variant="caption" component='div' className={['sectionTitle', props.centered ? 'centered' : null, props.capitalized ? 'capitalized' : null ].join(' ')}>
          {props.title}
      </Typography>
  )
};

export default SectionTitle;