import React   from 'react';

import MainGrid from "../MainGrid/MainGrid"; //when this gets removed the ui breaks (!)
import ChatsTabsHOC from "../../containers/ChatsTabsHOC/ChatsTabsHOC";
import HeaderHOC from "../../containers/HeaderHOC/HeaderHOC";
import ContentRouter from "../../routers/contentRouter";
import SidebarRouter from "../../routers/sidebarRouter";
import {connect} from "react-redux";
import MainGridHOC from "../../containers/MainGridHOC/MainGridHOC";

const PageLayout = (props) => {

    return (
        <div>
            <HeaderHOC />
            <MainGridHOC
                leftContent={<ChatsTabsHOC/>}
                centerContent={<ContentRouter/>}//<StatisticsHOC title='Stats Dashboard'/>}
                rightContent={<SidebarRouter/>}
            />
        </div>
    )
};
const mapStateToProps = state => {
  return {
    token: state.user.token
  }
};
export default  connect(mapStateToProps)(PageLayout);
