import * as actionTypes from './types';
import {actionCreator} from "../shared/utility";

export const fetchRoles = actionCreator(actionTypes.FETCH_ROLES);
export const fetchRolesSuccess = actionCreator(actionTypes.FETCH_ROLES_SUCCESS);
export const fetchRolesFailed = actionCreator(actionTypes.FETCH_ROLES_FAILED);




