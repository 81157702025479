import * as actionTypes from './types';
import {actionCreator} from "../shared/utility";

export const sendMessage = actionCreator(actionTypes.SEND_MESSAGE);
export const sendMessageSuccess = actionCreator(actionTypes.SEND_MESSAGE_SUCCESS);
export const sendMessageFailure = actionCreator(actionTypes.SEND_MESSAGE_FAILURE);
export const storeMessages = actionCreator(actionTypes.STORE_MESSAGES);
export const getMessagesFailure = actionCreator(actionTypes.GET_MESSAGES_FAILURE);
export const startChat = actionCreator(actionTypes.START_CHAT);
export const chatStarted = actionCreator(actionTypes.CHAT_STARTED);
export const startChatFailure = actionCreator(actionTypes.START_CHAT_FAILURE);
export const endChat = actionCreator(actionTypes.END_CHAT);
export const chatEnded = actionCreator(actionTypes.CHAT_ENDED);
export const endChatFailure = actionCreator(actionTypes.END_CHAT_FAILURE);
