import React, {Component} from 'react';
import './Feeiling.css';
import {Typography} from "@material-ui/core";
import IMAGES from '../../assets/images';
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";

class Feeling extends Component{

    render() {

        let feelingSection = null;
        if(this.props.feelingInfo) {
            if (!this.props.withIconText) {
                feelingSection = (<div className='feeling'>
                    <Typography variant='caption' component='div' className='feelingLabel'>
                        Νιώθει&nbsp;
                    </Typography>
                    <Tooltip title={this.props.feelingInfo.name} placement='bottom'>
                        <img src={IMAGES[this.props.feelingInfo.code.toUpperCase()]} alt={this.props.feelingInfo.name}/>
                    </Tooltip>
                </div>)
            } else {
                feelingSection = <Grid container spacing={0} className='detailsGrid withIconText'>
                    <Grid item lg={1}>
                        <img src={IMAGES[this.props.feelingInfo.code.toUpperCase()]} alt={this.props.feelingInfo.name}/>
                    </Grid>
                    <Grid item lg={11}>
                        <Typography variant="caption" component='div' className='details'>
                            Νιώθει {this.props.feelingInfo.name} {this.props.when && this.props.when.length > 0 && (this.props.when === "start"? "πριν το chat":"μετά το chat") }
                        </Typography>
                    </Grid>
                </Grid>
            }
        }
        return(
            <div className='feelingSection'>
                {feelingSection}
            </div>
        )
    }
}

export default Feeling;
