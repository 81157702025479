import React from 'react';
import './InfoBlock.css';
import Grid from '@material-ui/core/Grid';
import {Typography} from "@material-ui/core";

const InfoBlock = (props) => {
    return(
      <div className={['infoBlockAgent', props.coloredNumber ? 'colored': null ].join(' ')}>

          <Grid
              container spacing={1}
              direction="row"
              justify="center"
              alignItems="center"
          >

              <Grid item xs={3} className='number'>
                  <Typography variant={props.styleNumber}>
                      {props.number}
                  </Typography>
              </Grid>

              <Grid item xs={8} className='text'>
                  <Typography variant='body1' component='div'>
                      {props.text}
                  </Typography>
              </Grid>


          </Grid>

      </div>
    );
};

export default  InfoBlock;