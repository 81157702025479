import * as actionTypes from './types';
import {actionCreator} from "../shared/utility";

export const fetchChatStatistics = actionCreator(actionTypes.FETCH_CHAT_STATISTICS);

export const storeChatStatistics = actionCreator(actionTypes.STORE_CHAT_STATISTICS);

export const fetchChatStatisticsFailed = actionCreator(actionTypes.FETCH_CHAT_STATISTICS_FAILED);

export const toggleChatStatisticsVisibility = actionCreator(actionTypes.TOGGLE_CHAT_STATISTICS_VISIBILITY);
