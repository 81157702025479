import React, {Component} from "react";
import {connect} from "react-redux";
import * as actions from "../../actions";
import Pages from "../../components/Pages/Pages";

class PagesHOC extends Component {

    constructor(props){
        super(props);
        this.state = {
            isModalOpen: false,
            isDeleteModalOpen: false,
            selectedPage: {}
        }
    }

    componentDidMount() {
        this.props.fetchPages({
            page: this.props.page,
            limit: this.props.rowsPerPage,
            sort: `${this.props.orderBy}:${this.props.order}`
        });
        this.props.fetchPageTypes();

    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(
            prevProps.page !== this.props.page
            || prevProps.rowsPerPage !== this.props.rowsPerPage
            || prevProps.orderBy !== this.props.orderBy
            || prevProps.order !== this.props.order
        ) {
            this.props.fetchPages({
                page: this.props.page,
                limit: this.props.rowsPerPage,
                sort: `${this.props.orderBy}:${this.props.order}`
            });
        }

        // to be executed after page create
        if(
            prevProps.posting && !this.props.posting
            && !this.props.responseMessage
        ){
            this.setIsModalOpen(false);
            this.props.fetchPages({
                page: this.props.page,
                limit: this.props.rowsPerPage,
                sort: `${this.props.orderBy}:${this.props.order}`
            });
        }

        //to be executed after page delete
        if(
            prevProps.deleting && !this.props.deleting
            && !this.props.deleteResponseMessage
        ){
            this.setIsDeleteModalOpen(false);
            this.props.fetchPages({
                page: this.props.page,
                limit: this.props.rowsPerPage,
                sort: `${this.props.orderBy}:${this.props.order}`
            });
        }
    }

    setIsDeleteModalOpen = (isOpen, page) => {
        this.setState({
            ...this.state,
            isDeleteModalOpen: isOpen,
            selectedPage: page && isOpen ? page : {}
        });
    };

    setIsModalOpen = (isOpen) => {
        this.setState({...this.state, isModalOpen: isOpen});
    };

    onChangePage = (evt, page) => {
        this.props.changePagesState({key: "page", value: page})
    };

    onChangeRowsPerPage = (evt) => {
        this.props.changePagesState({key: "page", value: 0});
        this.props.changePagesState({key: "rowsPerPage", value: evt.target.value})
    };

    onMessageResponseDismiss = () => {
        this.props.changePagesState({key: "responseMessage", value: null});
    };

    onSort = (columnId) =>{
        this.props.changePagesState({key: "orderBy", value: columnId});
        if(columnId === this.props.orderBy){
            const order = this.props.order === 'desc' ? 'asc' : 'desc';
            this.props.changePagesState({key: "order", value: order});
        } else {
            this.props.changePagesState({key: "order", value: "desc"});
        }
    };

    onDeleteResponseMessageDismiss = () => {
        this.props.changePagesState({key: "deleteResponseMessage", value: null});
    };

    render(){
        return (
            <Pages
                {...this.props}
                onSort={this.onSort}
                onChangePage={this.onChangePage}
                onChangeRowsPerPage={this.onChangeRowsPerPage}
                setIsModalOpen={this.setIsModalOpen}
                isModalOpen={this.state.isModalOpen}
                onMessageResponseDismiss={this.onMessageResponseDismiss}
                setIsDeleteModalOpen={this.setIsDeleteModalOpen}
                isDeleteModalOpen={this.state.isDeleteModalOpen}
                selectedPage={this.state.selectedPage}
                onDeleteResponseMessageDismiss={this.onDeleteResponseMessageDismiss}
            />
        )
    }
}

const mapStateToProps = state => ({
    pagesById: state.pages.byId,
    loadingPages: state.pages.loading,
    page: state.pages.page,
    totalRecords: state.pages.totalRecords,
    rowsPerPage: state.pages.rowsPerPage,
    posting: state.pages.posting,
    responseMessage: state.pages.responseMessage,
    pageTypesById: state.pageTypes.byId,
    orderBy: state.pages.orderBy,
    order: state.pages.order,
    deleting: state.pages.deleting,
    deleteResponseMessage: state.pages.deleteResponseMessage
});

const mapDispatchToProps = dispatch => ({
    fetchPages: (payload) => {
        dispatch(actions.fetchPages(payload))
    },
    changePagesState: (payload) => {
        dispatch(actions.changePagesState(payload))
    },
    fetchPageTypes: (payload) => {
        dispatch(actions.fetchPageTypes(payload))
    },
    createPage: (payload) => {
        dispatch(actions.createPage(payload))
    },
    deletePage: (payload) => {
        dispatch(actions.deletePage(payload))
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(PagesHOC);