import React  from 'react';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './editorComponent.css';

const EditorComponent = (props) => {
    const {wrapperClassName, editorClassName} = props;
    return (
        <Editor
            {...props}
            wrapperClassName={wrapperClassName ? wrapperClassName: "demo-wrapper"}
            editorClassName={editorClassName ? editorClassName : "demo-editor"}
        />)
};

export default EditorComponent;