import React, {Component} from 'react';
import ChatEndedSummaryPrint from "../../components/ChatEndedSummaryPrint/ChatEndedSummaryPrint";
import {connect} from "react-redux";

class ChatEndedSummaryPrintHOC extends Component{
    render() {
        return (
            <ChatEndedSummaryPrint {...this.props} chat={this.props.chat[this.props.selectedChatId]}/>
        )
    }
}

const mapStateToProps = (state) => ({
    selectedChatId: state.chats.selectedChatId,
    chat: state.chats.byId,
    feelingsCodes:state.general.feelings
});

export default connect(mapStateToProps)(ChatEndedSummaryPrintHOC);