import React from 'react';
import './NavigationItem.css';
import {NavLink} from 'react-router-dom';

const navigationItem = (props) => {

    return(
        <NavLink
            to={props.link}
            exact={props.exact}
            className={props.color}
        >
            {props.children}

        </NavLink>
    );
};

export default navigationItem;