import {headersWithTokenJson} from './headers';
import xs from 'xstream';
import {BASE_URL} from "../helpers/constants";
import * as actions from "../actions";
import * as actionTypes from "../actions/types";
import sampleCombine from "xstream/extra/sampleCombine";
import delay from "xstream/extra/delay";

export function requestPageTypes(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.user.token);
    const request$ = sources.ACTION
        .filter(action => action.type === actionTypes.FETCH_PAGE_TYPES)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) =>  ({
                url: BASE_URL + `PageTypes`,
                category: 'requestPageTypes',
                method: 'GET',
                headers: headersWithTokenJson(token),
                withCredentials: true
            })
        );

    let httpResponse$ = sources.HTTP
        .select('requestPageTypes')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.fetchPageTypesSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    };
}

export function requestPageTypesFailed(sources) {
    const response$ = sources.HTTP
        .select('requestPageTypes')
        .map(response =>
            response.replaceError(err => xs.of(err)))
        .flatten()
        .filter(response => response.status !== 200);

    const action$ = xs.combine(response$)
        .map(response => actions.fetchPageTypesFailed(response));

    return {
        ACTION: action$
    };
}


