import React from 'react';
import './message.css';
import moment from 'moment';
import {emojify} from 'react-emojione';

const Message = (props) => {
  const {
    createdDate,
    body,
    isMine,
    startsSequence,
    endsSequence,
    showTimestamp,
    sendingInProgress,
  } = props;

  const friendlyTimestamp = moment.utc(createdDate).local().format('HH:mm');

  return (
    <div className={[
      'message',
      `${isMine ? 'mine' : ''}`,
      `${startsSequence ? 'start' : ''}`,
      `${endsSequence ? 'end' : ''}`
    ].join(' ')}>
      {
        showTimestamp &&
        <div
          className={`timestamp
          ${isMine ? ' justifyContentFlexEnd' :
            ' justifyContentFlexStart'}`}>
          { friendlyTimestamp }
        </div>
      }
      {sendingInProgress ?
        <div className="bubble-container withError">
          <div className="bubble" title={createdDate}>
            { body }
          </div>
          <div
            className="waitingCircle deliveryStatus"
          />
        </div> : <div className="bubble-container">
          <div className="bubble" title={createdDate}>
            { emojify(body) }
          </div>
        </div>}
    </div>
  );
};

export default Message;
