import {headersWithTokenJson} from './headers';
import xs from 'xstream';
import {ACTIVE_TAB, BASE_URL, HISTORY_TAB, PENDING_TAB} from "../helpers/constants";
import * as actions from "../actions";
import sampleCombine from 'xstream/extra/sampleCombine';
import * as actionTypes from "../actions/types";
import {push} from "connected-react-router";

// pending chats
export function fetchPendingChats(sources) {
  const state$ = sources.STATE;
  const token$ = state$.map(state => state.user.token);
  const request$ = sources.ACTION
    .filter(action => action.type === actionTypes.FETCH_PENDING_CHATS)
    .compose(sampleCombine(token$))
    .map(([action, token]) =>  ({
        url: BASE_URL + 'chats?ChatIsPending=true&Sort=CreatedDate:desc',
        category: 'fetchPendingChats',
        method: 'GET',
        headers: headersWithTokenJson(token),
        withCredentials: true
      })
    );

  let httpResponse$ = sources.HTTP
    .select('fetchPendingChats')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200)
    .map(response => actions.storePendingChats(response));

  return {
    ACTION: httpResponse$,
    HTTP: request$
  };
}

export function fetchPendingChatsFailed(sources) {
  const response$ = sources.HTTP
    .select('fetchPendingChats')
    .map(response =>
      response.replaceError(err => xs.of(err)))
    .flatten()
    .filter(response => response.status !== 200);

  const action$ = xs.combine(response$)
    .map(response => actions.fetchPendingChatsFailed(response));

  return {
    ACTION: action$
  };
}

// active-ongoing Chats
export function fetchActiveChats(sources) {
  const state$ = sources.STATE;
  const token$ = state$.map(state => state.user.token);
    const request$ = sources.ACTION
        .filter(action => action.type === actionTypes.FETCH_ACTIVE_CHATS )
        .compose(sampleCombine(token$))
        .map(([action, token]) =>  ({
            url: BASE_URL + 'chats?ChatIsOngoing=true&Sort=CreatedDate:desc',
            category: 'fetchActiveChats',
            method: 'GET',
            headers: headersWithTokenJson(token),
            withCredentials: true
          })
        );

    let httpResponse$ = sources.HTTP
        .select('fetchActiveChats')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.storeActiveChats(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    };
}

export function fetchActiveChatsFailed(sources) {
    const response$ = sources.HTTP
        .select('fetchActiveChats')
        .map(response =>
            response.replaceError(err => xs.of(err)))
        .flatten()
        .filter(response => response.status !== 200);

    const action$ = xs.combine(response$)
        .map(response => actions.fetchActiveChatsFailed(response));

    return {
        ACTION: action$
    };
}

// history Chats
export function fetchHistoryChats(sources) {
  const state$ = sources.STATE;
  const token$ = state$.map(state => state.user.token);
    const request$ = sources.ACTION
        .filter(action => action.type === actionTypes.FETCH_HISTORY_CHATS)
        //TODO: check this
        .filter(action => action.payload && action.payload.sendBody && action.payload.sendBody.page)
      .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: BASE_URL + 'chats/?ChatHasEnded=true&Page=' + action.payload.sendBody.page ,
            category: 'fetchHistoryChats',
            method: 'GET',
            headers: headersWithTokenJson(token),
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('fetchHistoryChats')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.storeHistoryChats(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    };
}

export function fetchHistoryChatsFailed(sources) {
    const response$ = sources.HTTP
        .select('fetchHistoryChats')
        .map(response =>
            response.replaceError(err => xs.of(err)))
        .flatten()
        .filter(response => response.status !== 200);

    const action$ = xs.combine(response$)
        .map(response => actions.fetchHistoryChatsFailed(response));

    return {
        ACTION: action$
    };
}

// Start Chat
export function startChat(sources) {
  const state$ = sources.STATE;
  const token$ = state$.map(state => state.user.token);
    const request$ = sources.ACTION
        .filter(action => action.type === actionTypes.START_CHAT)
      .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: `${BASE_URL}chats/${action.payload.chatId}/start`,
            category: 'startChat',
            method: 'PATCH',
            headers: headersWithTokenJson(token),
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('startChat')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 204)
        .map(response => actions.chatStarted(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    };
}

export function startChatFailed(sources) {
    const response$ = sources.HTTP
        .select('startChat')
        .map(response =>
            response.replaceError(err => xs.of(err)))
        .flatten()
        .filter(response => response.status !== 204);

    const action$ = xs.combine(response$)
        .map(response => actions.startChatFailure(response));

    return {
        ACTION: action$
    };
}
// End Chat
export function endChat(sources) {
  const state$ = sources.STATE;
  const token$ = state$.map(state => state.user.token);
    const request$ = sources.ACTION
        .filter(action => action.type === actionTypes.END_CHAT)
      .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: `${BASE_URL}chats/${action.payload.chatId}/end`,
            category: 'endChat',
            method: 'PATCH',
            headers: headersWithTokenJson(token),
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('endChat')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 204)
        .map(response => actions.chatEnded(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    };
}

export function endChatailed(sources) {
    const response$ = sources.HTTP
        .select('endChat')
        .map(response =>
            response.replaceError(err => xs.of(err)))
        .flatten()
        .filter(response => response.status !== 204);

    const action$ = xs.combine(response$)
        .map(response => actions.endChatFailure(response));

    return {
        ACTION: action$
    };
}

// Get individual chat

export function getSingleChat(sources) {
  const state$ = sources.STATE;
  const token$ = state$.map(state => state.user.token);
  const request$ = sources.ACTION
    .filter(action => action.type === actionTypes.GET_SINGLE_CHAT)
    .compose(sampleCombine(token$))
    .map(([action, token]) => ({
      url: `${BASE_URL}chats/${action.payload}`,
      category: 'getSingleChat',
      method: 'GET',
      headers: headersWithTokenJson(token),
      withCredentials: true
    }));

  let httpResponse$ = sources.HTTP
    .select('getSingleChat')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200)
    .map(response => actions.getSingleChatSuccess(response));

  return {
    ACTION: httpResponse$,
    HTTP: request$
  };
}

export function fetchChatMessages(sources) {
  const state$ = sources.STATE;
  const token$ = state$.map(state => state.user.token);
  const selectedChatId$ = state$.map(state => state.chats.selectedChatId);
  const request$ = sources.ACTION
    .filter(action =>
      action.type === actionTypes.SEND_MESSAGE_SUCCESS
    )
    .compose(sampleCombine(token$, selectedChatId$))
    .map(([action, token, selectedChatId]) => ({
      url: `${BASE_URL}messages/agent?ChatId=${selectedChatId}`,
      category: 'fetchChatMessages',
      method: 'GET',
      headers: headersWithTokenJson(token),
      withCredentials: true
    }));

  let httpResponse$ = sources.HTTP
    .select('fetchChatMessages')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200)
    .map(response => actions.fetchChatMessagesSuccess(response));

  return {
    ACTION: httpResponse$,
    HTTP: request$
  };
}

export function fetchChatMessagesFailed(sources) {
  const response$ = sources.HTTP
    .select('fetchChatMessages')
    .map(response =>
      response.replaceError(err => xs.of(err)))
    .flatten()
    .filter(response => response.status !== 200);

  const action$ = xs.combine(response$)
    .map(response => actions.fetchChatMessagesFailure(response));

  return {
    ACTION: action$
  };
}


export function fetchChatMessagesAfterSignalR(sources) {
  const state$ = sources.STATE;
  const token$ = state$.map(state => state.user.token);
  const request$ = sources.ACTION
    .filter(action =>
      action.type === actionTypes.SIGNAL_R_EVENT_CHAT_NEW_MESSAGE
    )
    .compose(sampleCombine(token$))
    .map(([action, token]) => ({
      url: `${BASE_URL}messages/agent?ChatId=${action.payload.ChatId}`,
      category: 'fetchChatMessagesAfterSignalR',
      method: 'GET',
      headers: headersWithTokenJson(token),
      withCredentials: true
    }));

  let httpResponse$ = sources.HTTP
    .select('fetchChatMessagesAfterSignalR')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200)
    .map(response => actions.fetchChatMessagesAfterSignalRSuccess(response));

  return {
    ACTION: httpResponse$,
    HTTP: request$
  };
}

export function fetchChatMessagesAfterSignalRFailed(sources) {
  const response$ = sources.HTTP
    .select('fetchChatMessagesAfterSignalR')
    .map(response =>
      response.replaceError(err => xs.of(err)))
    .flatten()
    .filter(response => response.status !== 200);

  const action$ = xs.combine(response$)
    .map(response => actions.fetchChatMessagesAfterSignalRFailure(response));

  return {
    ACTION: action$
  };
}

// Get all child chats
export function fetchChildChatsAfterSingleFetched(sources) {
  const state$ = sources.STATE;
  const token$ = state$.map(state => state.user.token);
  const request$ = sources.ACTION
    .filter(action => action.type === actionTypes.GET_SINGLE_CHAT_SUCCESS)
    .compose(sampleCombine(token$))
    .map(([action, token]) => ({
        url: `${BASE_URL}ApplicationUsers/childs/${action.payload.body.result.createdBy.id}/chats/`,
        category: 'fetchChildChatsAfterSingleFetched',
        method: 'GET',
        headers: headersWithTokenJson(token),
        withCredentials: true
      })
    );

  let httpResponse$ = sources.HTTP
    .select('fetchChildChatsAfterSingleFetched')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200)
    .map(response => actions.fetchChildChatsSuccess(response));

  return {
    ACTION: httpResponse$,
    HTTP: request$
  };
}
export function fetchChildChats(sources) {
  const state$ = sources.STATE;
  const token$ = state$.map(state => state.user.token);
  const allChats$ = state$.map(state => state.chats.byId);
  const request$ = sources.ACTION
    .filter(action => action.type === actionTypes.CHAT_SELECTED)
    .compose(sampleCombine(token$, allChats$))
    .filter(([action, token, allChats]) => allChats[action.payload] && allChats[action.payload].childId)
    .map(([action, token, allChats]) => ({
        url: `${BASE_URL}ApplicationUsers/childs/${allChats[action.payload].childId}/chats/`,
        category: 'fetchChildChats',
        method: 'GET',
        headers: headersWithTokenJson(token),
        withCredentials: true
      })
    );

  let httpResponse$ = sources.HTTP
    .select('fetchChildChats')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200)
    .map(response => actions.fetchChildChatsSuccess(response));

  return {
    ACTION: httpResponse$,
    HTTP: request$
  };
}

export function fetchChildChatsFailed(sources) {
  const response$ = sources.HTTP
    .select('fetchChildChats')
    .map(response =>
      response.replaceError(err => xs.of(err)))
    .flatten()
    .filter(response => response.status !== 200);

  const action$ = xs.combine(response$)
    .map(response => actions.fetchChildChatsFailure(response));

  return {
    ACTION: action$
  };
}

// fetch notes after adding a new note
export function fetchNotes(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.user.token);
    const selectedChatId$ = state$.map(state => state.chats.selectedChatId);
    const request$ = sources.ACTION
        .filter(action => action.type === actionTypes.ADD_NEW_CHAT_NOTE_SUCCESS )
        .compose(sampleCombine(token$, selectedChatId$))
        .map(([action, token, selectedChatId]) => ({
            url: `${BASE_URL}notes?ChatId=${selectedChatId}`,
            category: 'fetchNotes',
            method: 'GET',
            headers: headersWithTokenJson(token),
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('fetchNotes')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.storeChatNotes(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    };
}

export function fetchNotesFailed(sources) {
    const response$ = sources.HTTP
        .select('fetchNotes')
        .map(response =>
            response.replaceError(err => xs.of(err)))
        .flatten()
        .filter(response => response.status !== 200);

    const action$ = xs.combine(response$)
        .map(response => actions.fetchChatNotesFailure(response));

    return {
        ACTION: action$
    };
}

export function setActiveTabActive(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === actionTypes.CHAT_STARTED)
    .map(action => actions.setActiveTab(ACTIVE_TAB))
  return {
    ACTION: action$
  };
}

export function setActiveTabHistory(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === actionTypes.CHAT_ENDED)
    .map(action => actions.setActiveTab(HISTORY_TAB))
  return {
    ACTION: action$
  };
}



export function setActiveTabHistoryCausedBySignalR(sources) {
  const selectedChatId$ = sources.STATE.map(state => state.chats.selectedChatId);
  const action$ = sources.ACTION
    .filter(action => action.type === actionTypes.SIGNAL_R_EVENT_CHAT_ENDED
    )
    .compose(sampleCombine(selectedChatId$))
    .filter(([action, selectedChatId]) =>action.payload.ChatId === selectedChatId)
    .map(action => actions.setActiveTab(HISTORY_TAB));
  return {
    ACTION: action$
  };
}


  export function redirectToEndAfterSignalREventChatEnded(sources) {
    const selectedChatId$ = sources.STATE.map(state => state.chats.selectedChatId);
    const action$ = sources.ACTION
      .filter(action => action.type === actionTypes.SIGNAL_R_EVENT_CHAT_ENDED)
      .compose(sampleCombine(selectedChatId$))
      .filter(([action, selectedChatId]) =>action.payload.ChatId === selectedChatId)
      .map(([action, selectedChatId]) => push(`/chat/${selectedChatId}/end`));

    return {
      ACTION: action$,
    }
  }


export function mapSelectedChatToGetSingleChat(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === actionTypes.CHAT_SELECTED)
    .map(action => actions.getSingleChat(action.payload));
  return {
    ACTION: action$
  };
}


export function mapSignalREventChatNewMessageToFetchChatMessagesAfterSignalR(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === actionTypes.SIGNAL_R_EVENT_CHAT_NEW_MESSAGE)
    .map(action => actions.fetchChatMessagesAfterSignalR(action.payload));
  return {
    ACTION: action$
  };
}

export function mapSignalREventChatNewMessageToAddUnreadChat(sources) {
    const action$ = sources.ACTION
        .filter(action => action.type === actionTypes.SIGNAL_R_EVENT_CHAT_NEW_MESSAGE)
        .map(action => actions
            .addNewUnreadChat({
                ...action.payload,
                chatTab: ACTIVE_TAB
            }));
    return {
        ACTION: action$
    };
}

export function mapSignalREventChatStartedToAddUnreadChat(sources) {
    const action$ = sources.ACTION
        .filter(action => action.type === actionTypes.SIGNAL_R_EVENT_CHAT_STARTED)
        .map(action => actions
            .addNewUnreadChat({
                ...action.payload,
                chatTab: ACTIVE_TAB
            }));
    return {
        ACTION: action$
    };
}


export function mapSignalREventChatInitiatedToAddUnreadChat(sources) {
    const action$ = sources.ACTION
        .filter(action => action.type === actionTypes.SIGNAL_R_EVENT_CHAT_INITIATED)
        .map(action => actions
            .addNewUnreadChat({
                ...action.payload,
                chatTab: PENDING_TAB
            }));
    return {
        ACTION: action$
    };
}

export function mapSignalREventChatEndedToUnreadChatWasRead(sources) {
    const action$ = sources.ACTION
        .filter(action => action.type === actionTypes.SIGNAL_R_EVENT_CHAT_ENDED)
        .map(action => actions.unreadChatWasRead({chatId: action.payload.ChatId}));
    return {
        ACTION: action$
    };
}

export function mapSendMessageSuccessToFetchChatMessages(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === actionTypes.SEND_MESSAGE_SUCCESS)
    .map(action => actions.fetchChatMessages(action.payload));
  return {
    ACTION: action$
  };
}

export function fetchChatEndedFeelingCausedBySignalR(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.user.token);
    const selectedChatId$ = sources.STATE.map(state => state.chats.selectedChatId);
    const request$ = sources.ACTION
        .filter(action => action.type === actionTypes.SIGNAL_R_EVENT_CHAT_END_FEELING_SENT )
        .compose(sampleCombine(token$, selectedChatId$))
        .map(([action, token, selectedChatId]) => ({
            url: `${BASE_URL}chats/${selectedChatId}`,
            category: 'getSingleChatAfterChatEnded',
            method: 'GET',
            headers: headersWithTokenJson(token),
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('getSingleChatAfterChatEnded')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.getSingleChatSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    };
}

//send Chats Report via mail
export function sendChatsReportEmail(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.user.token);
    const request$ = sources.ACTION
        .filter(action => action.type === actionTypes.SEND_CHATS_REPORT)
        .compose(sampleCombine(token$))
        .map(([action, token]) =>  ({
                url: `${BASE_URL}chats/emailReport`,
                category: 'sendChatsReport',
                method: 'POST',
                send: action.payload.body,
                headers: headersWithTokenJson(token),
                withCredentials: true
            })
        );

    let httpResponse$ = sources.HTTP
        .select('sendChatsReport')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 204)
        .map(response => actions.sendChatsReportSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    };
}

export function sendChatsReportEmailFailure(sources) {
    const response$ = sources.HTTP
        .select('sendChatsReport')
        .map(response =>
            response.replaceError(err => xs.of(err)))
        .flatten()
        .filter(response => response.status !== 204);

    const action$ = xs.combine(response$)
        .map(response => actions.sendChatsReportFailure(response));

    return {
        ACTION: action$
    };
}

