import * as actionTypes from '../actions/types';
import { updateObject } from "../shared/utility";

const initialState ={
    feelings: null,
    loadingFeelings: false,
    ages: null,
    loadingAges: false
};
// Feelings
const fetchFeelings = (state,action) =>{
    return updateObject(state, { loadingFeelings: true });
};
const storeFeelings = (state,action) =>{
    let fetchedFeelings = null;
    if(action.payload && action.payload.body && action.payload.body.results){
        fetchedFeelings = action.payload.body.results;
    }
    return updateObject(state, {
        feelings: fetchedFeelings,
        loadingFeelings: false
    });
};

// Ages
const fetchAges = (state,action) =>{
    return updateObject(state, { loadingAges: true });
};
const storeAges = (state,action) =>{
    let fetchedAges = null;
    if(action.payload && action.payload.body && action.payload.body.results){
        fetchedAges = action.payload.body.results;
    }
    return updateObject(state, {
        ages: fetchedAges,
        loadingAges: false
    });
};

export const general = (state = initialState, action ) => {
    switch (action.type) {
        // Feelings
        case actionTypes.FETCH_FEELINGS: return fetchFeelings(state,action);
        case actionTypes.STORE_FEELINGS: return storeFeelings(state,action);
        // Ages
        case actionTypes.FETCH_AGES: return fetchAges(state,action);
        case actionTypes.STORE_AGES: return storeAges(state,action);
        default:
            return state;
    }
}
