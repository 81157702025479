import {headersWithTokenJson} from './headers';
import xs from 'xstream';
import {BASE_URL} from "../helpers/constants";
import * as actions from "../actions";
import * as actionTypes from "../actions/types";
import sampleCombine from "xstream/extra/sampleCombine";
import delay from "xstream/extra/delay";

export function requestDeleteApplicationUser(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.user.token);
    const request$ = sources.ACTION
        .filter(action => action.type === actionTypes.DELETE_APPLICATION_USER)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) =>  ({
                url: BASE_URL + `ApplicationUsers/${action.payload.applicationUserId}`,
                category: 'requestDeleteApplicationUser',
                method: 'DELETE',
                headers: headersWithTokenJson(token),
                withCredentials: true
            })
        );

    let httpResponse$ = sources.HTTP
        .select('requestDeleteApplicationUser')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 204)
        .map(response => actions.deleteApplicationUserSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    };
}

export function requestDeleteApplicationUserFailed(sources) {
    const response$ = sources.HTTP
        .select('requestDeleteApplicationUser')
        .map(response =>
            response.replaceError(err => xs.of(err)))
        .flatten()
        .filter(response => response.status !== 204);

    const action$ = xs.combine(response$)
        .map(response => actions.deleteApplicationUserFailed(response));

    return {
        ACTION: action$
    };
}

export function requestUpdateApplicationUser(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.user.token);
    const request$ = sources.ACTION
        .filter(action => action.type === actionTypes.UPDATE_APPLICATION_USER)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) =>  ({
                url: BASE_URL + `ApplicationUsers/${action.payload.applicationUserId}`,
                category: 'requestUpdateApplicationUser',
                method: 'PATCH',
                send: action.payload.sendBody,
                headers: headersWithTokenJson(token),
                withCredentials: true
            })
        );

    let httpResponse$ = sources.HTTP
        .select('requestUpdateApplicationUser')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 204)
        .map(response => actions.updateApplicationUserSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    };
}

export function requestUpdateApplicationUserFailed(sources) {
    const response$ = sources.HTTP
        .select('requestUpdateApplicationUser')
        .map(response =>
            response.replaceError(err => xs.of(err)))
        .flatten()
        .filter(response => response.status !== 204);

    const action$ = xs.combine(response$)
        .map(response => actions.updateApplicationUserFailed(response));

    return {
        ACTION: action$
    };
}

export function requestCreateApplicationUser(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.user.token);
    const request$ = sources.ACTION
        .filter(action => action.type === actionTypes.CREATE_APPLICATION_USER)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) =>  ({
                url: BASE_URL + `ApplicationUsers`,
                category: 'requestCreateApplicationUser',
                method: 'POST',
                send: action.payload.sendBody,
                headers: headersWithTokenJson(token),
                withCredentials: true
            })
        );

    let httpResponse$ = sources.HTTP
        .select('requestCreateApplicationUser')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.createApplicationUserSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    };
}

export function requestCreateApplicationUserFailed(sources) {
    const response$ = sources.HTTP
        .select('requestCreateApplicationUser')
        .map(response =>
            response.replaceError(err => xs.of(err)))
        .flatten()
        .filter(response => response.status !== 200);

    const action$ = xs.combine(response$)
        .map(response => actions.createApplicationUserFailed(response));

    return {
        ACTION: action$
    };
}

export function requestApplicationUser(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.user.token);
    const request$ = sources.ACTION
        .filter(action => action.type === actionTypes.FETCH_APPLICATION_USER)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) =>  ({
                url: BASE_URL + `ApplicationUsers/${action.payload.applicationUserId}`,
                category: 'requestApplicationUser',
                method: 'GET',
                headers: headersWithTokenJson(token),
                withCredentials: true
            })
        );

    let httpResponse$ = sources.HTTP
        .select('requestApplicationUser')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.fetchApplicationUserSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    };
}

export function requestApplicationUserFailed(sources) {
    const response$ = sources.HTTP
        .select('requestApplicationUser')
        .map(response =>
            response.replaceError(err => xs.of(err)))
        .flatten()
        .filter(response => response.status !== 200);

    const action$ = xs.combine(response$)
        .map(response => actions.fetchApplicationUserFailed(response));

    return {
        ACTION: action$
    };
}

export function requestApplicationUsers(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.user.token);
    const request$ = sources.ACTION
        .filter(action => action.type === actionTypes.FETCH_APPLICATION_USERS)
        .compose(delay())
        .compose(sampleCombine(token$))
        .map(([action, token]) =>  ({
                url: BASE_URL + `ApplicationUsers` + (
                    action.payload ?
                        "?"
                        + `page=${action.payload.page + 1}`
                        + "&"
                        + `limit=${action.payload.limit}`
                        + "&"
                        + `sort=${action.payload.sort}`
                        :
                        ""
                ),
                category: 'requestApplicationUsers',
                method: 'GET',
                headers: headersWithTokenJson(token),
                withCredentials: true
            })
        );

    let httpResponse$ = sources.HTTP
        .select('requestApplicationUsers')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.fetchApplicationUsersSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    };
}

export function requestApplicationUsersFailed(sources) {
    const response$ = sources.HTTP
        .select('requestApplicationUsers')
        .map(response =>
            response.replaceError(err => xs.of(err)))
        .flatten()
        .filter(response => response.status !== 200);

    const action$ = xs.combine(response$)
        .map(response => actions.fetchApplicationUsersFailed(response));

    return {
        ACTION: action$
    };
}


