import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import SidebarMenu from "../sidebarMenu/sidebarMenu";
import NavigationBar from "./navigationBar/navigationBar";
import Content from "./content/content";
import {useTheme} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        boxSizing: "border-box",
        margin: 0,
        padding: 0
    },
}));

const Layout = () => {
    const classes = useStyles();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElNotification, setAnchorElNotification] = React.useState(null);
    const open = Boolean(anchorEl);
    const openNotification = Boolean(anchorElNotification);
    const [isMenuOpen, setMenu] = React.useState(false);



    const handleMenu=(event)=> {
        setAnchorEl(event.currentTarget);
    };

    const handleClose=()=> {
        setAnchorEl(null);
    };

    const sidebarHandler = () => {
        setMenu(!isMenuOpen)
    };

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <NavigationBar
                isMenuOpen={isMenuOpen}
                sidebarHandler={sidebarHandler}
                handleMenu={handleMenu}
                handleClose={handleClose}
                anchorEl={anchorEl}
                open={open}
                openNotification={openNotification}
                anchorElNotification={anchorElNotification}
            />
            <SidebarMenu
                sidebarHandler={sidebarHandler}
                isMenuOpen={isMenuOpen}
                handleClose={handleClose}
            />
            <Content/>
        </div>
    );
};

export default Layout
