import {combineReducers} from "redux";
import {user} from "./user";
import {chats} from "./chats";
import {statistics} from "./statistics";
import {childUsers} from "./childUsers";
import {general} from "./general";
import {applicationUsers} from "./applicationUsers";
import {roles} from "./roles";
import {pages} from "./pages";
import {pageTypes} from "./pageTypes";
import {LOGOUT} from "../actions/types";
import storage from "redux-persist/es/storage";
import {PERSIST_KEY} from "../store/store";
import { connectRouter } from 'connected-react-router'

const reducerWithHistoryCreator = (history) => combineReducers({
    pageTypes,
    pages,
    roles,
    applicationUsers,
    user: user,
    chats: chats,
    statistics: statistics,
    childUsers: childUsers,
    general: general,
    router: connectRouter(history),
});

const rootReducer = (history) => (state, action) => {
    const createdHistoryReducer = reducerWithHistoryCreator(history);

    if (action.type === LOGOUT) {
        storage.removeItem(`persist:${PERSIST_KEY}`);
        state = undefined;
    }
    return createdHistoryReducer(state, action);
};


export default rootReducer;
