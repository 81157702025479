import * as actionTypes from '../actions/types';
import { updateObject } from "../shared/utility";
import _ from "lodash";

const initialState = {
    byId: {},
    allIds:[],
    loading: false,
    totalRecords: 0
};


const fetchPageTypes = (state, action) => {
    return updateObject(state, {loading: false});
};

const fetchPageTypesSuccess = (state, action) => {
    try {
        const allIds = action.payload.body.results.map(e => e.id);
        const byId = _.keyBy(action.payload.body.results, "id");
        return updateObject(state, {
            loading: false,
            allIds,
            byId,
            totalRecords: action.payload.body.totalCount
        });
    } catch (e) {
        return updateObject(state, {loading: false });
    }
};

const fetchPageTypesFailed = (state, action) => {
    return updateObject(state, {loading: false});
};

export const pageTypes = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PAGE_TYPES: return fetchPageTypes(state,action);
        case actionTypes.FETCH_PAGE_TYPES_SUCCESS: return fetchPageTypesSuccess(state,action);
        case actionTypes.FETCH_PAGE_TYPES_FAILED: return fetchPageTypesFailed(state,action);
        default:
            return state;

    }
};
