import React from 'react';

import {HorizontalBar} from "react-chartjs-2";

const HorizontalBarChart = (props)  => {
    const dataBar = {
        labels: props.labels,
        datasets: [{
            label: props.datasetLabel,
            data: props.data,
            backgroundColor: props.bgColor,
            borderColor: props.borderColor,
            borderWidth: 1
        }]
    };
    return (
        <HorizontalBar
            data={dataBar}
            options={{
                maintainAspectRatio : false,
                scales: {
                    xAxes: [{
                        display:false
                    }],
                    yAxes: [{
                        display: true,
                        barThickness: 25,
                        minBarLength: 5,
                        gridLines: {
                            display: false,
                        }
                    }]
                },
                legend: {
                    "display": false
                },
                plugins: {
                    datalabels: {
                        display: true,
                        color: '#fff'
                    }
                }
            }}
        />
    )
};

export default HorizontalBarChart;
