import React, {Component} from 'react';
import './ChatEndedSummaryPrint.css';
import {Container} from "@material-ui/core";
import FeelingChatEnded from "../Feeling/FeelingChatEnded/FeelingChatEnded";
import ChatNotesHOC from "../../containers/ChatNotesHOC/ChatNotesHOC";
import ReportChats from "../ReportChats/ReportChats";

class ChatEndedSummaryPrint extends Component{
    render() {
        return(
            <Container className='chatSummaryEnded'>

                <FeelingChatEnded {...this.props}/>

                <ChatNotesHOC showOldNotes={false}/>

                {this.props.chat ?
                    <ReportChats {...this.props} title='Print or Send'/>
                :null}
            </Container>
        )
    }
}

export default ChatEndedSummaryPrint;