import React, {Component} from 'react';
import ChatButton from "../../components/ChatButton/ChatButton";
import * as actions from "../../actions";
import {connect} from "react-redux";
import {withRouter} from "react-router";

class ChatButtonHOC extends Component {
    clickedChatStartEndButton = () =>{
        if(this.props.pendingChat || !this.props.startTimestampSelectedChat) {
            if(this.props.pendingChat){
                this.props.selectChat(this.props.chatId);
            }
            this.props.startChat({chatId: this.props.chatId})
        }else{
            this.props.endChat({chatId: this.props.chatId});
            // this.props.history.push('/end') //link after end of chat
        }
    };

    render() {
        let chatButton = null;
        if(!this.props.endTimestampSelectedChat) {
            chatButton =  <ChatButton
                {...this.props}
              isAvailable={this.props.online}
                clicked={this.clickedChatStartEndButton}
                enabledChat={this.props.startTimestampSelectedChat}
            />
        }
        return (
            chatButton
        );
    }
}

const mapStateToProps = state => {
  return {
      chatSelected: state.chats.byId[state.chats.selectedChatId],
      startTimestampSelectedChat:state.chats.selectedChatId ? state.chats.byId[state.chats.selectedChatId].startTimestamp : null,
      endTimestampSelectedChat:state.chats.selectedChatId ? state.chats.byId[state.chats.selectedChatId].endTimestamp : null
  }
};

const mapDispatchToProps = dispatch => ({
    startChat: (payload) => {dispatch(actions.startChat(payload))},
    endChat: (payload) => {dispatch(actions.endChat(payload))},
    selectChat: (payload) => dispatch(actions.chatSelected(payload)),
});

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(ChatButtonHOC));
