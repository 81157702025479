import * as actionTypes from './types';
import {actionCreator} from "../shared/utility";

export const fetchPages = actionCreator(actionTypes.FETCH_PAGES);
export const fetchPagesSuccess = actionCreator(actionTypes.FETCH_PAGES_SUCCESS);
export const fetchPagesFailed = actionCreator(actionTypes.FETCH_PAGES_FAILED);

export const changePagesState = actionCreator(actionTypes.CHANGE_PAGES_STATE);

export const fetchPage = actionCreator(actionTypes.FETCH_PAGE);
export const fetchPageSuccess = actionCreator(actionTypes.FETCH_PAGE_SUCCESS);
export const fetchPageFailed = actionCreator(actionTypes.FETCH_PAGE_FAILED);

export const createPage = actionCreator(actionTypes.CREATE_PAGE);
export const createPageSuccess = actionCreator(actionTypes.CREATE_PAGE_SUCCESS);
export const createPageFailed = actionCreator(actionTypes.CREATE_PAGE_FAILED);

export const updatePage = actionCreator(actionTypes.UPDATE_PAGE);
export const updatePageSuccess = actionCreator(actionTypes.UPDATE_PAGE_SUCCESS);
export const updatePageFailed = actionCreator(actionTypes.UPDATE_PAGE_FAILED);

export const deletePage = actionCreator(actionTypes.DELETE_PAGE);
export const deletePageSuccess = actionCreator(actionTypes.DELETE_PAGE_SUCCESS);
export const deletePageFailed = actionCreator(actionTypes.DELETE_PAGE_FAILED);

export const reorderPage = actionCreator(actionTypes.REORDER_PAGE);
export const reorderPageSuccess = actionCreator(actionTypes.REORDER_PAGE_SUCCESS);
export const reorderPageFailed = actionCreator(actionTypes.REORDER_PAGE_FAILED);