import * as actionTypes from '../actions/types';
import { updateObject } from "../shared/utility";
import _ from "lodash";

const initialState = {
    byId: {},
    allIds:[],
    loading: false,
};



const fetchRoles = (state, action) => {
    return updateObject(state, {loading: true});
};

const fetchRolesSuccess = (state, action) => {
    try {
        const allIds = action.payload.body.results.map(e => e.id);
        const byId = _.keyBy(action.payload.body.results, "id");

        return updateObject(state, {
            loading: false,
            allIds,
            byId,
            totalRecords: action.payload.body.totalCount
        });
    } catch (e) {
        return updateObject(state, {loading: false });
    }
};

const fetchRolesFailed = (state, action) => {
    return updateObject(state, {loading: false});
};

export const roles = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_ROLES: return fetchRoles(state,action);
        case actionTypes.FETCH_ROLES_SUCCESS: return fetchRolesSuccess(state,action);
        case actionTypes.FETCH_ROLES_FAILED: return fetchRolesFailed(state,action);
        default:
            return state;

    }
};
