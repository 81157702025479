import React, {useCallback, useState} from "react"
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from 'prop-types';
import {makeStyles} from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToApp from "@material-ui/icons/ExitToApp";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import {useDispatch} from "react-redux";
import * as actions from "../../../actions";
import GeneralDialog from "../../GeneralDialog/GeneralDialog";
import AgentInfoModalContainer from "../../../containers/AgentInfoHOC/AgentInfoModalContainer";

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    userMenu: {
        float: "right",
        marginRight: 15
    }
}));


const AgentMenu = (props) => {
    const {
        handleMenu,
        handleClose,
        anchorEl,
        open
    } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const logoutAction = useCallback(
        (payload) => dispatch(actions.logout(payload)),
        [dispatch]
    );
    const [isModalOpen, setIsModalOpen] = useState(false);


    return (
        <div className={classes.grow}>
            <GeneralDialog
                content={
                    <AgentInfoModalContainer
                        onCancel={() => setIsModalOpen(false)}
                    />
                }
                fullWidth
                maxWidth={"sm"}
                open={isModalOpen}
                handleClose={() => setIsModalOpen(false)}
                title={"Αλλαγή Κωδικού"}
            />
            <div className={classes.userMenu}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                >
                    <AccountCircle/>
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => setIsModalOpen(true)}>
                        <ListItemIcon>
                            <VpnKeyIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Αλλαγή Κωδικού" />
                    </MenuItem>
                    <MenuItem onClick={logoutAction}>
                        <ListItemIcon>
                            <ExitToApp fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Αποσύνδεση" />
                    </MenuItem>
                </Menu>
            </div>
        </div>
    )
};

export default AgentMenu;

AgentMenu.propTypes = {
    handleMenu: PropTypes.func,
    handleClose: PropTypes.func,
    logoutAction: PropTypes.func,
    anchorEl: PropTypes.object,
    open: PropTypes.bool,

};
