import React, { Component } from 'react';
import {connect} from "react-redux";
import './App.css';
import {Router} from 'react-router-dom';
import {history} from "./store/store";
import withAuthentication from "./hocs/withAuthentication";
import PageLayout from "./components/PageLayout/PageLayout";
import CircularProgress from "@material-ui/core/CircularProgress";
import Layout from "./components/AdminLayout/layout";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {colors} from './helpers/colors';

class App extends Component {
    render() {
        const theme = createMuiTheme({
            palette: {
                primary: { main: colors.BLUE },
                secondary: { main: colors.LIGHT_ORANGE },
            },
        });
        if(this.props.fetchingCurrentUserInfo && !this.props.roleCode){
            return (
                <div className='agent-login-page'>
                    <CircularProgress className='spinner'/>
                </div>
            )
        }

        let UserLayout = () => null;
        if(this.props.roleCode === "admin"){
            UserLayout = Layout;
        }

        if(this.props.roleCode === "agent"){
            UserLayout = PageLayout
        }

        return (
          <MuiThemeProvider theme={theme}>
            <Router history={history}>
                <UserLayout/>
            </Router>
          </MuiThemeProvider>
        );
    }
}

const mapStateToProps = (state) => ({
    roleCode: state.user.info.roleCode,
    fetchingCurrentUserInfo: state.user.fetchingInfo
});

const mapDispatchToProps = (dispatch) => ({});

export default withAuthentication(connect(mapStateToProps, mapDispatchToProps)(App));
