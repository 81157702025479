export const actionCreator = (type) => (payload) => ({
    type,
    payload
});

export const updateObject = (oldObject, updatedProperties) => {
    return{
        ...oldObject,
        ...updatedProperties
    }
};

export const checkValidity = (value,rules) => {
    let isValid = true;

    if(!rules){
        return true;
    }

    if(rules.required){
        isValid = value.trim() !== '' && isValid;
    }
    if (rules.minLength){
        isValid = value.length >= rules.minLength && isValid
    }
    if (rules.maxLength){
        isValid = value.length <= rules.maxLength && isValid
    }
    if(rules.isEmail){
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        isValid = pattern.test(value) && isValid
    }
    if(rules.isNumeric){
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid
    }
    return isValid;
};