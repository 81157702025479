import React, {Component} from 'react';
import './AvgTime.css';
import Typography from "@material-ui/core/Typography";
import {Grid} from "@material-ui/core";
import moment from 'moment';

class AvgChatTime extends Component{
    render() {
        return (
            <div>
                <Typography variant='body2' component='div' className='statsTitle'>
                    {this.props.title}
                </Typography>
                <Grid spacing={1} container>
                    <Grid item xs>
                        <Typography variant='h3' component='div' className='coloredValue'>
                            {moment.duration({"seconds": this.props.time}).humanize()}
                            {/*{moment.utc(this.props.time*1000).format(this.props.formatTime)}*/}
                        </Typography>
                    </Grid>
                    {/*<Grid item xs className={['rate', this.props.class, increase ? 'increase' : 'decrease'].join(' ')}>*/}
                    {/*    {increase ? <ArrowUp/> : <ArrowDown/>}*/}
                    {/*    <span>{increase ? '+' : null}{differencePercentage} %</span>*/}
                    {/*</Grid>*/}
                </Grid>
            </div>
        )
    }
};

export default AvgChatTime;
