import * as actionTypes from './types';
import {actionCreator} from "../shared/utility";

export const fetchApplicationUsers = actionCreator(actionTypes.FETCH_APPLICATION_USERS);
export const fetchApplicationUsersSuccess = actionCreator(actionTypes.FETCH_APPLICATION_USERS_SUCCESS);
export const fetchApplicationUsersFailed = actionCreator(actionTypes.FETCH_APPLICATION_USERS_FAILED);

export const fetchApplicationUser = actionCreator(actionTypes.FETCH_APPLICATION_USER);
export const fetchApplicationUserSuccess = actionCreator(actionTypes.FETCH_APPLICATION_USER_SUCCESS);
export const fetchApplicationUserFailed = actionCreator(actionTypes.FETCH_APPLICATION_USER_FAILED);

export const changeApplicationUsersState = actionCreator(actionTypes.CHANGE_APPLICATION_USERS_STATE);

export const createApplicationUser = actionCreator(actionTypes.CREATE_APPLICATION_USER);
export const createApplicationUserSuccess = actionCreator(actionTypes.CREATE_APPLICATION_USER_SUCCESS);
export const createApplicationUserFailed = actionCreator(actionTypes.CREATE_APPLICATION_USER_FAILED);

export const updateApplicationUser = actionCreator(actionTypes.UPDATE_APPLICATION_USER);
export const updateApplicationUserSuccess = actionCreator(actionTypes.UPDATE_APPLICATION_USER_SUCCESS);
export const updateApplicationUserFailed = actionCreator(actionTypes.UPDATE_APPLICATION_USER_FAILED);

export const deleteApplicationUser = actionCreator(actionTypes.DELETE_APPLICATION_USER);
export const deleteApplicationUserSuccess = actionCreator(actionTypes.DELETE_APPLICATION_USER_SUCCESS);
export const deleteApplicationUserFailed = actionCreator(actionTypes.DELETE_APPLICATION_USER_FAILED);



