import React from "react";
import _ from "lodash";
import CustomTable from "../CustomTable/CustomTable";
import EditIcon from '@material-ui/icons/Edit';
import {withRouter} from "react-router";
import {Grid} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import GeneralDialog from "../GeneralDialog/GeneralDialog";
import PageDetailsForm from "../PageDetails/PageDetailsForm";
import FabAdd from "../General/fabAdd";
import DeleteIcon from '@material-ui/icons/Delete';
import Button from "@material-ui/core/Button";
import DeletePageConfirmation from "./DeletePageConfirmation";
import {SOS_LINE_TITLE, TERMS_OF_USE_TITLE} from "../../helpers/constants";

const columns = [
    {propertyName: "title", header: "Title", id: "Title" },
    {propertyName: "type", header: "Type", id: "Type" },
];


const Pages = (props) => {
    const {
        pagesById,
        page,
        totalRecords,
        rowsPerPage,
        history,
        onChangePage,
        onChangeRowsPerPage,
        loadingPages,
        setIsModalOpen,
        isModalOpen,
        onMessageResponseDismiss,
        responseMessage,
        posting,
        createPage,
        pageTypesById,
        orderBy,
        order,
        onSort,
        setIsDeleteModalOpen,
        isDeleteModalOpen,
        deletePage,
        deleting,
        selectedPage,
        onDeleteResponseMessageDismiss,
        deleteResponseMessage
    } = props;
    const pages = _.map(pagesById, e => e);
    const pageTypes = _.map(pageTypesById, e => e);
    const actions = [
        {
            label: "Edit",
            callback: (row) => history.push(`/pages/${row.id}`),
            icon:  <EditIcon/>
        },
        {
            shouldDisplay: (row) => (
                row.title.trim().toLowerCase() !== TERMS_OF_USE_TITLE.trim().toLowerCase()
                && row.title.trim().toLowerCase() !== SOS_LINE_TITLE.trim().toLowerCase()
            ),
            label: "Delete",
            callback: (row) => setIsDeleteModalOpen(true, row),
            icon:  <DeleteIcon/>
        },
    ];
    if(loadingPages){
        return (
            <Grid
                container spacing={1}
                direction="row"
                justify={"center"}
            >
                <CircularProgress className='spinner'/>
            </Grid>

        )
    }

    return (
        <div>
            <GeneralDialog
                content={
                    <DeletePageConfirmation
                        onDeleteResponseMessageDismiss={onDeleteResponseMessageDismiss}
                        deleteResponseMessage={deleteResponseMessage}
                        selectedPage={selectedPage}
                    />
                }
                actions={
                    <React.Fragment>
                        {deleting && (
                            <CircularProgress className='spinner' size={30}/>
                        )}
                        <Button  color="primary" onClick={() => deletePage({pageId: selectedPage.id})}>
                            Yes
                        </Button>
                        <Button color="secondary" onClick={() => setIsDeleteModalOpen(false)}>
                            Cancel
                        </Button>
                    </React.Fragment>
                }
                fullWidth
                maxWidth={"sm"}
                open={isDeleteModalOpen}
                handleClose={() => setIsDeleteModalOpen(false)}
                title={"Delete Page"}
            />
            <GeneralDialog
                content={
                    <PageDetailsForm
                        onResponseMessageDismiss={onMessageResponseDismiss}
                        responseMessage={responseMessage}
                        loading={posting}
                        createPage={createPage}
                        onCancel={() => setIsModalOpen(false)}
                        pageTypes={pageTypes}
                    />
                }
                fullWidth
                maxWidth={"md"}
                open={isModalOpen}
                handleClose={() => setIsModalOpen(false)}
                title={"Create Page"}
            />
            <FabAdd
                addIconAction={() => setIsModalOpen(true)}
                title={"Create Page"}
                placement={"top"}
            />
            <CustomTable
                onSort={onSort}
                orderBy={orderBy}
                order={order}
                sortable
                accordionItemHeaderFormatter={datum => datum.title}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                actions={actions}
                title={"Pages"}
                paginated
                columns={columns}
                data={pages}
                totalRecords={totalRecords}
                page={page}
                rowsPerPage={rowsPerPage}
            />
        </div>
    )
};

export default withRouter(Pages);