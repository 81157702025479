import React, {Component} from 'react';
import './ChatNotes.css';

import {Typography} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import SectionTitle from "../SectionTitle/SectionTitle";
import Button from "@material-ui/core/Button";
import ChatsHistoryNotesHOC from "../../containers/ChatsHistoryNotesHOC/ChatsHistoryNotesHOC";
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

class ChatNotes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            rows: 2,
            minRows: 2,
            maxRows: 4
        };
    }

    handleChange = (event) => {
        const textareaLineHeight = 18;
        const { minRows, maxRows } = this.state;

        const previousRows = event.target.rows;
        event.target.rows = minRows; // reset number of rows in textarea

        const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

        if (currentRows === previousRows) {
            event.target.rows = currentRows;
        }

        if (currentRows >= maxRows) {
            event.target.rows = maxRows;
            event.target.scrollTop = event.target.scrollHeight;
        }

        this.setState({
            value: event.target.value,
            rows: currentRows < maxRows ? currentRows : maxRows,
        });
    };

    addNewNote = (e) => {
            this.props.addNewNote(this.state.value);
            // I want to clear the textarea around here
            this.setState({value:""})
    };

    handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.props.clearAddNewNoteStatus();
    };
    render() {
        let messageStatus = null;
        if(this.props.addNewNoteStatus && this.props.addNewNoteStatus === 'success'){
            messageStatus = (<Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={this.props.addNewNoteStatus && this.props.addNewNoteStatus==='success'}
                autoHideDuration={5000}
                onClose={this.handleCloseSnackbar}
            >
                <SnackbarContent
                    className='success'
                    aria-describedby="client-snackbar"
                    message={
                        <span id="client-snackbar" >
                            <CheckCircleIcon/>
                            H σημείωση αποθηκεύτηκε επιτυχώς.
                        </span>
                    }
                    action={[
                        <IconButton key="close" aria-label="close" color="inherit" onClick={this.handleCloseSnackbar}>
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />
            </Snackbar>);
        }else if(this.props.addNewNoteStatus && this.props.addNewNoteStatus === 'failure'){
            messageStatus = (<Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={this.props.addNewNoteStatus && this.props.addNewNoteStatus==='failure'}
                autoHideDuration={5000}
                onClose={this.handleCloseSnackbar}
            >
                <SnackbarContent
                    className='error'
                    aria-describedby="client-snackbar"
                    message={
                        <span id="client-snackbar" >
                            <ErrorIcon/>
                            Δυστυχώς, παρουσιάστηκε κάποιο πρόβλημα! <br/> Παρακαλώ προσπαθήστε ξανά.
                        </span>
                    }
                    action={[
                        <IconButton key="close" aria-label="close" color="inherit" onClick={this.handleCloseSnackbar}>
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />
            </Snackbar>);
        }
        return (
            <div className='chatNotes'>
                <Container>

                    {/*title*/}
                    <SectionTitle title={this.props.title}/>

                    {/*oldNotes*/}
                    {this.props.showOldNotes?
                        <ChatsHistoryNotesHOC {...this.props} />
                    :null}

                    {/*addNewNote*/}
                    <div className='newNote'>
                        {messageStatus}
                        <div className='saveButton justifyContentFlexEnd'>
                            <Button variant="outlined" color="primary"
                                    onClick={this.addNewNote}
                            >Αποθηκευση
                            </Button>
                        </div>
                        <Box display={{ xs: 'none', lg: 'none', xl: 'block' }}>
                            <Typography  variant="caption" component='div' className='details' >
                                {this.props.textAreaTooltipText}
                            </Typography>
                        </Box>
                        <TextField
                            id="outlined-bare"
                            className='inputSubject'
                            value={this.state.value}
                            placeholder={this.props.textAreaPlaceholder}
                            fullWidth
                            variant="outlined"
                            multiline={true}
                            rows={this.state.rows}
                            onChange={this.handleChange}
                        />
                    </div>
                </Container>
            </div>
        )
    }
};

export default ChatNotes;