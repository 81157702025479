import React from "react";
import Tooltip from '@material-ui/core/Tooltip/index';
import Fab from '@material-ui/core/Fab/index';
import AddIcon from '@material-ui/icons/Add';
import {makeStyles} from '@material-ui/core/styles/index';
import {colors} from "../../helpers/colors";

const useStyles = makeStyles(theme => ({
    absolute: {
        position: "absolute",
        bottom: 10,
        right:  theme.spacing(4),
        backgroundColor: colors.ORANGE,
        "&:hover": {
            backgroundColor: colors.ORANGE,
        },
        zIndex: 1250
    },
}));


const FabAdd = (props) => {
    const classes = useStyles();
    const {addIconAction, title, placement} = props;
    return (
        <Tooltip title={title} placement={placement} >
            <Fab
                color="primary"
                className={classes.absolute}
                onClick={addIconAction}
            >
                <AddIcon/>
            </Fab>
        </Tooltip>
    )
};


export default FabAdd
