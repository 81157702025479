import React from 'react';
import './warning.css';

const Warning = (props) => {
  return (
    <div className="warning">{props.text}</div>
  );
};

export default Warning;
