import {createStore, applyMiddleware} from 'redux';
import { createBrowserHistory as createHistory } from 'history'
import {persistStore, persistReducer, createMigrate} from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import rootReducer from '../reducers';
import {run} from '@cycle/run';
import {makeHTTPDriver} from '@cycle/http';
import {createCycleMiddleware} from 'redux-cycles';
import main from '../cycles/cycles';
import {createLogger} from 'redux-logger';
import { createSignalRMiddleware } from '../redux-signalR';
import signalREventHandlers from '../signalRs';
import { SIGNAL_R_URL } from '../helpers/constants';
import {migrations} from "./migrations";
import { routerMiddleware } from 'connected-react-router'

const middleWare = [];
export const history = createHistory();
// middleWare.push(routerMiddleware(history));
export const PERSIST_KEY = 'rootChatPersist';
const config = {
    key: 'rootChatPersist',
    whitelist: ['user'],
    storage,
    version: 0,
    migrate: createMigrate(migrations)
};

const cycleMiddleware = createCycleMiddleware();
const {makeActionDriver, makeStateDriver} = cycleMiddleware;

middleWare.push(cycleMiddleware);

const signalRMiddleware = createSignalRMiddleware({
    url: SIGNAL_R_URL,
    signalREventHandlers,
    logMessageContent: true,
    logger: 'Trace'
});

middleWare.push(signalRMiddleware);

// for dispatching history actions
middleWare.push(routerMiddleware(history));

if (process.env.NODE_ENV === `development`) {
    let logger;

    logger = createLogger({
    });

    middleWare.push(logger);
}

const persistedReducer = persistReducer(config, rootReducer(history));

const store = createStore(
    persistedReducer,
    applyMiddleware(...middleWare),
);


export const persistor = persistStore(store);


run(main, {
    ACTION: makeActionDriver(),
    STATE: makeStateDriver(),
    HTTP: makeHTTPDriver()
});


export default store;

// function createStorageListener(store) {
//     return event => {
//         // the storage event tells you which value changed
//         const {action} = JSON.parse(event.newValue);
//
//         store.dispatch(action);
//     }
// }
