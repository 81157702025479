import React from "react";
import AuthAgentHOC from "../containers/LoginHOC/LoginHOC";
import {connect} from "react-redux";
const withAuthentication = (WrappedComponent) => {
    class AuthHOC extends React.Component{
        render(){
            const {token} = this.props;

            if(!token){
                return (
                    <AuthAgentHOC/>
                )}

            return (
                <WrappedComponent {...this.props} />
            )
        }
    }

    const mapStateToProps = (state) => ({
        token: state.user.token
    });

    const mapDispatchToProps = (dispatch) => ({});
    return connect(mapStateToProps, mapDispatchToProps)(AuthHOC)
};


export default withAuthentication;
