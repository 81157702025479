import React, { Component } from 'react';
import './ChatSummary.css';
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import Moment from "react-moment";
import AvatarInitials from "../AvatarInitials/AvatarInitials";
import Feeling from "../Feeling/Feeling";
import _ from "lodash";
import ChatButtonHOC from "../../containers/ChatButtonHOC/ChatButtonHOC";
import moment from "moment";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Box from "@material-ui/core/Box";


class ChatSummary extends Component {
    constructor(props){
        super(props);
        this.handleMouseHover = this.handleMouseHover.bind(this);
        this.state={
            isHovering: false
        };
    }
    handleMouseHover() {
        this.setState(this.toggleHoverState);
    }

    toggleHoverState(state) {
        return {
            isHovering: !state.isHovering,
        };
    }

    render() {
        const {unread} = this.props;
        const feeling = this.props.feeling;
        const feelingsCodes = this.props.feelingsCodes;
        const feelingInfo = _.find(feelingsCodes, function(c) { return  c.code === feeling });

        // show start button only for pending chats
        const chatIsPending = _.includes(this.props.pendingChatsIds, this.props.chatId );

        let chatSummary = null;
        if(chatIsPending && !this.props.selectedChatId && !this.props.showStats && this.state.isHovering){
            chatSummary = (<Grid container spacing={0}>
                <Grid item xs={2}>
                    <AvatarInitials name={this.props.displayName} maxInitials={2} bgColor="#0d8abc"/>
                </Grid>

                <Grid item xs={7}>
                    <Typography variant='subtitle1' component='div'
                                className='userName'>{this.props.displayName}</Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <Feeling feeling={this.props.feeling} feelingInfo={feelingInfo}/>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant='caption' component='div'
                                        className='subject'>Θέμα: {this.props.subject}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant='subtitle1' component='div' className='messageDate'>
                            {this.props.startTimestamp ?
                                <Moment format='DD/MM/YY HH:mm'>
                                    {moment.utc(this.props.startTimestamp).local()}
                                </Moment>
                                : "Not Started"}
                        </Typography>
                        <div>
                            <ChatButtonHOC
                                {...this.props}
                                pendingChat={chatIsPending}
                                online={this.props.userOnline}
                                helpTextDisabled='Πρέπει να μπείτε online για να ξεκινήσετε τη συζήτηση'
                                helpText='Ξεκινήστε τη συζήτηση'
                                helpEnabledChatText='Τερματίστε τη συζήτηση'
                                startLabel='Start'
                                endLabel='End'
                            />
                        </div>
                    </Box>
                </Grid>
                <Grid item xs={1}>
                    <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
                        {unread && (
                            <Box alignSelf="center">
                                <FiberManualRecordIcon fontSize={"small"} color={"error"}/>
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>);
        }else {
            chatSummary = (<Grid container spacing={0}>
                <Grid item xs={2}>
                    <AvatarInitials name={this.props.displayName} maxInitials={2} bgColor="#0d8abc"/>
                </Grid>

                <Grid item xs={7}>
                    <Typography variant='subtitle1' component='div'
                                className='userName'>{this.props.displayName}</Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <Feeling feeling={this.props.feeling} feelingInfo={feelingInfo}/>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant='caption' component='div'
                                        className='subject'>Θέμα: {this.props.subject}</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={3}>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography variant='subtitle1' component='div' className='messageDate'>
                            {this.props.startTimestamp ?
                                <Box display={'flex'} flexDirection={'column'}>
                                    <Moment format='DD/MM/YY'>
                                        {this.props.startTimestamp}
                                    </Moment>
                                    <Moment format='HH:mm'>
                                        {this.props.startTimestamp}
                                    </Moment>
                                </Box>
                                : "Not Started"}
                        </Typography>
                        {unread && (
                            <Box alignSelf="center">
                                <FiberManualRecordIcon fontSize={"small"} color={"error"}/>
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>);
        }
        return(
            <div className={`messageSummary`}
                 onMouseEnter={this.handleMouseHover}
                 onMouseLeave={this.handleMouseHover}
            >
               {chatSummary}
            </div>
        )
    }
}

export default ChatSummary;
