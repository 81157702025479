import React, {Component} from 'react';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Button from "@material-ui/core/Button";
import moment from "moment";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class PrintReport extends Component{

   printReport = (content) => {
        // createPdf
        const docDefinition = {
            info: {
                title: 'Chat Report '+moment().format("DD-MM-YYYY"),
                author: 'Chat1056'
            },
            content:  content.content,
            styles: content.styles
        };

       pdfMake.createPdf(docDefinition).download('chat_report.pdf');

    };
    render() {
        return(
            <Button
                disabled={this.props.disabled}
                onClick={() => this.printReport(this.props.reportContent)} color='primary'>
                Εκτυπωση
            </Button>
        )
    }
}

export default PrintReport;