import * as actionTypes from '../actions/types';
import { updateObject } from "../shared/utility";
import parseChats from "../helpers/parseChats";
import moment from 'moment';
import _ from 'lodash';
import parseChat from "../helpers/parseChat";
import {ACTIVE_TAB, PENDING_TAB} from "../helpers/constants";
import {func} from "prop-types";

const initialState = {
    selectedChatId: null,
    fetchingChat: false,
    loadingPendingChats: false,
    loadingActiveChats: false,
    loadingHistoryChats: false,
    byId: {},
    allIds:[],
    pendingChatsIds: [],
    activeChatsIds: [],
    historyChatsIds: [],
    unreadChatsIds: [],
    historyPage: 1,
    historyTotalPages: null,
    allChatsOfCurrentChild: [],
    pendingChatsShouldUpdate: false,
    activeChatsShouldUpdate: false,
    historyChatsShouldUpdate: false,
    selectedTab: PENDING_TAB,
    sendEmailStatus: null,
    addNewNoteStatus:null
};

const chatSelected = (state, action) => {
    return updateObject(state, {
        selectedChatId: action.payload,
        fetchingChat: true,
    });
};

const chatSelectedClosed = (state, action) => {
    return updateObject(state, { selectedChatId: null });
};

// active Chats
const fetchActiveChats = (state,action) =>{
    return updateObject(state, { loadingActiveChats: true });
};
const storeActiveChats = (state,action) =>{
    let parsedChats = null;
    if(action.payload && action.payload.body && action.payload.body.results){
        parsedChats = parseChats(state.byId, state.allIds, [], state.historyChatsIds, state.pendingChatsIds, action.payload.body.results, "active");
    }
    return updateObject(state, {
        loadingActiveChats: false,
        byId: {
            ...state.byId,
            ...parsedChats.newChats
        },
        allIds: parsedChats.chatsIds,
        activeChatsIds: parsedChats.activeChatsIds,
        activeChatsShouldUpdate: false,
    });
};

// pending Chats
const fetchPendingChats = (state,action) =>{
    return updateObject(state, { loadingPendingChats: true });
};
const storePendingChats = (state,action) =>{
    let parsedChats = null;
    if(action.payload && action.payload.body && action.payload.body.results){
        parsedChats = parseChats(state.byId, state.allIds, state.activeChatsIds, state.historyChatsIds, [], action.payload.body.results, "pending");
    }
    return updateObject(state, {
        loadingPendingChats: false,
        byId: {
            ...state.byId,
            ...parsedChats.newChats
        },
        allIds: parsedChats.chatsIds,
        pendingChatsIds: parsedChats.pendingChatsIds,
        pendingChatsShouldUpdate: false,
    });
};

// history Chats
const fetchHistoryChats = (state,action) =>{
    return updateObject(state, { loadingHistoryChats: true });
};
const storeHistoryChats = (state,action) =>{
    let parsedChats = null;
    if(action.payload && action.payload.body && action.payload.body.results){
        parsedChats = parseChats(state.byId, state.allIds, state.activeChatsIds, state.historyChatsIds, state.pendingChatsIds, action.payload.body.results, "history");
    }
    return updateObject(state, {
        loadingHistoryChats: false,
        byId: {
            ...state.byId,
            ...parsedChats.newChats
        },
        allIds: parsedChats.chatsIds,
        historyChatsIds: parsedChats.historyChatsIds,
        historyPage: action.payload.body.page,
        historyTotalPages: action.payload.body.totalPages,
        historyChatsShouldUpdate: false,
    });

};

const fetchChatSuccess = (state,action) =>{
    return updateObject(state, {
        fetchingChat:false,
        byId: {
            ...state.byId,
            [action.payload.body.chatId]:{
                ...state.byId[action.payload.body.chatId],
                messages: _.orderBy(action.payload.body.results, 'createdDate', 'asc')
            },
        }
    });
};

const storeChatNotes = (state,action) =>{
    return updateObject(state, {
        fetchingChat:false,
        byId: {
            ...state.byId,
            [action.payload.body.chatId]:{
                ...state.byId[action.payload.body.chatId],
                notes: _.orderBy(action.payload.body.results, 'createdDate', 'asc')
            },
        }
    });
};

const fetchChildChats = (state,action) =>{
    return updateObject(state, {

    });

};

const fetchChildChatsSuccess = (state,action) =>{
    let parsedChildChats = null;
    if(action.payload && action.payload.body && action.payload.body.results){
        parsedChildChats = parseChats(state.byId, state.allIds, state.activeChatsIds, state.historyChatsIds, state.pendingChatsIds, action.payload.body.results, "history");
    }

    return updateObject(state, {
        allChatIdsOfCurrentChild: _.orderBy(action.payload.body.results.map(c => c.id), 'createdDate').reverse(),
        byId: {
            ...state.byId,
            ...parsedChildChats.newChats
        },
    });

};

const fetchChildChatsFailure = (state,action) =>{
    return updateObject(state, {

    });

};

const sendMessage = (state,action) =>{
    return updateObject(state, {
        byId: {
            ...state.byId,
            [action.payload.chatId]: {
                ...state.byId[action.payload.chatId],
                messages:[ ...state.byId[action.payload.chatId].messages,
                  {
                      body: action.payload.body,
                      created: moment().utc().format('YYYY-MM-DDThh:mm:ss'),
                      id: Math.random(),
                      isAgent: true,
                      sendingInProgress: true,
                  }
                ]
            }
        },
    });

};

const signalREventChatInitiated = (state,action) =>{
    return updateObject(state, {
        pendingChatsShouldUpdate: true,
    });
};

const signalREventChatStarted = (state,action) =>{
    return updateObject(state, {
        activeChatsShouldUpdate: true,
        pendingChatsShouldUpdate: true,
    });
};

const chatStarted = (state,action) =>{
    return updateObject(state, {
        activeChatsShouldUpdate: true,
        pendingChatsShouldUpdate: true,
    });
};

const chatEnded = (state,action) =>{
    return updateObject(state, {
        activeChatsShouldUpdate: true,
        historyChatsShouldUpdate: true,
    });
};

const signalREventChatEnded = (state,action) =>{
    return updateObject(state, {
        activeChatsShouldUpdate: true,
        historyChatsShouldUpdate: true,
    });
};

const getSingleChatSuccess = (state,action) =>{
    let newAllIds = state.allIds;
    if (!newAllIds.includes(action.payload.body.result.id)) {
        newAllIds.push(action.payload.body.result.id);
    }
    return updateObject(state, {
        byId: {
            ...state.byId,
            ...parseChat(state.byId, action.payload.body.result)
        },
        allIds: newAllIds
    });
};


const setActiveTab = (state,action) =>{
    return updateObject(state, {
        selectedTab: action.payload,
    });
};

const sendChatsReport = (state,action) =>{
    return updateObject(state, {
        sendEmailStatus: 'loading'
    });
};
const sendChatsReportSuccess = (state,action) =>{
    return updateObject(state, {
        sendEmailStatus: 'success'
    });
};
const sendChatsReportFailure = (state,action) =>{
    return updateObject(state, {
        sendEmailStatus: 'failure'
    });
};
const clearSendChatsReportStatus = (state,action) =>{
    return updateObject(state, {
        sendEmailStatus: null
    });
};

const addNewNoteSuccess = (state,action) =>{
    return updateObject(state, {
        addNewNoteStatus: 'success'
    });
};
const addNewNoteFailure = (state,action) =>{
    return updateObject(state, {
        addNewNoteStatus: 'failure'
    });
};
const clearAddNewNoteStatus = (state,action) =>{
    return updateObject(state, {
        addNewNoteStatus: null
    });
};


function logout() {
    return initialState;
}

const addNewUnreadChat = (state, action) => {
    if(
        action.payload
        && action.payload.ChatId
        && state.selectedChatId !== action.payload.ChatId
    ){
        let changes = {
            unreadChatsIds: [...state.unreadChatsIds]
        };

        if(!state.unreadChatsIds.includes(action.payload.ChatId)){
            changes = {
                unreadChatsIds: [...changes.unreadChatsIds, action.payload.ChatId]
            };
        }

        if(
            action.payload.chatTab === PENDING_TAB
            && !state.pendingChatsIds.includes(action.payload.ChatId)
        ){
            changes.pendingChatsIds = [...state.pendingChatsIds, action.payload.ChatId];
        }

        if(
            action.payload.chatTab === ACTIVE_TAB
        ){
            if(!state.activeChatsIds.includes(action.payload.ChatId)){
                changes.activeChatsIds = [...state.activeChatsIds, action.payload.ChatId];
            }

            changes.pendingChatsIds = state.pendingChatsIds.filter(c => c !== action.payload.ChatId);
        }

        return updateObject(state, changes);
    } else {
        return state;
    }

};

const unreadChatWasRead = (state, action) => {
    if(
        action.payload
        && action.payload.chatId
        && state.unreadChatsIds.includes(action.payload.chatId)
    ){
        return updateObject(state, {
            unreadChatsIds: state.unreadChatsIds.filter(c => c !== action.payload.chatId)
        });
    } else {
        return state;
    }


};

export const chats = (state = initialState, action) => {
    switch (action.type) {
      //individual chat
        case actionTypes.CHAT_SELECTED: return chatSelected(state,action);
        case actionTypes.CHAT_SELECTED_CLOSED: return chatSelectedClosed(state,action);
        case actionTypes.FETCH_CHILD_CHATS: return fetchChildChats(state,action);
        case actionTypes.FETCH_CHILD_CHATS_SUCCESS: return fetchChildChatsSuccess(state,action);
        case actionTypes.FETCH_CHILD_CHATS_FAILURE: return fetchChildChatsFailure(state,action);
        case actionTypes.SEND_MESSAGE: return sendMessage(state,action);
        case actionTypes.FETCH_CHAT_MESSAGES_AFTER_SIGNAL_R_SUCCESS:
        case actionTypes.FETCH_CHAT_MESSAGES_SUCCESS: return fetchChatSuccess(state,action);
        case actionTypes.STORE_CHAT_NOTES: return storeChatNotes(state, action);
        case actionTypes.CHAT_STARTED: return chatStarted(state,action);
        case actionTypes.CHAT_ENDED: return chatEnded(state,action);
        case actionTypes.GET_SINGLE_CHAT_SUCCESS: return getSingleChatSuccess(state,action);
      // add new Note
        case actionTypes.ADD_NEW_CHAT_NOTE_SUCCESS: return addNewNoteSuccess(state,action);
        case actionTypes.ADD_NEW_CHAT_NOTE_FAILURE: return addNewNoteFailure(state,action);
        case actionTypes.ADD_NEW_CHAT_NOTE_FAILURE: return clearAddNewNoteStatus(state,action);
        case actionTypes.CLEAR_ADD_NEW_CHAT_NOTE_STATUS: return clearAddNewNoteStatus(state,action);

      // active Chats
        case actionTypes.FETCH_PENDING_CHATS: return fetchPendingChats(state,action);
        case actionTypes.STORE_PENDING_CHATS: return storePendingChats(state,action);
      // active Chats
        case actionTypes.FETCH_ACTIVE_CHATS: return fetchActiveChats(state,action);
        case actionTypes.STORE_ACTIVE_CHATS: return storeActiveChats(state,action);
      // history Chats
        case actionTypes.FETCH_HISTORY_CHATS: return fetchHistoryChats(state,action);
        case actionTypes.STORE_HISTORY_CHATS: return storeHistoryChats(state,action);
        case actionTypes.LOGOUT: return logout(state,action);
      // signalR events
        case actionTypes.SIGNAL_R_EVENT_CHAT_INITIATED: return signalREventChatInitiated(state,action);
        case actionTypes.SIGNAL_R_EVENT_CHAT_STARTED: return signalREventChatStarted(state,action);
        case actionTypes.SIGNAL_R_EVENT_CHAT_ENDED: return signalREventChatEnded(state,action);
        case actionTypes.SET_ACTIVE_TAB: return setActiveTab(state,action);
      // send Email
        case actionTypes.SEND_CHATS_REPORT: return sendChatsReport(state,action);
        case actionTypes.SEND_CHATS_REPORT_SUCCESS: return sendChatsReportSuccess(state,action);
        case actionTypes.SEND_CHATS_REPORT_FAILURE: return sendChatsReportFailure(state,action);
        case actionTypes.CLEAR_SEND_CHATS_REPORT_STATUS: return clearSendChatsReportStatus(state,action);
      // unread chats
        case actionTypes.ADD_NEW_UNREAD_CHAT: return addNewUnreadChat(state, action);
        case actionTypes.UNREAD_CHAT_WAS_READ: return unreadChatWasRead(state, action);
        default:
            return state;
    }
};
