import React, {Component} from 'react';
import './ChatHistoryNote.css';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import moment from "moment";

class ChatHistoryNote extends Component{
    render() {
        const {createdDate, text, index} = this.props;
        return(
            <div className='note'>
                <div className='noteHeader'>
                    <Grid container spacing={0}>
                        <Grid item xs={8}>
                            <Typography className='titleLabel' variant="subtitle2">
                                Σημείωση #{index}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className='titleTimestamp' variant="subtitle2">
                                {moment(createdDate).format('DD/MM/YY, HH:mm')}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <div className='noteContent'>
                    {text}
                </div>
            </div>
        )
    }
}

export default ChatHistoryNote;
