import {withStyles} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import {colors} from '../../helpers/colors'

const GreenSwitch = withStyles({
  switchBase: {
    color: colors.GREY,
    '&$checked': {
      color: colors.LIGHT_ORANGE,
    },
    '&$checked + $track': {
      backgroundColor: colors.LIGHT_ORANGE,
    },
  },
  checked: {},
  track: {},
})(Switch);

export default GreenSwitch;
