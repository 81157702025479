import React, {Component} from 'react';
import {connect} from "react-redux";
import PageDetails from "../../components/PageDetails/PageDetails";
import {withRouter} from "react-router";
import * as actions from "../../actions";

class PageDetailsHOC extends Component {


    componentDidMount() {
        const id = this.props.match.params.id;
        this.props.fetchPage({pageId: id});
        this.props.fetchPageTypes();
    }

    onMessageResponseDismiss = () => {
        this.props.changePagesState({key: "responseMessage", value: null});
    };

    render() {
        return (
            <PageDetails
                {...this.props}
                onMessageResponseDismiss={this.onMessageResponseDismiss}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingPage: state.pages.fetchingPage,
        updating: state.pages.updating,
        fetchedPage: state.pages.fetchedPage,
        responseMessage: state.pages.responseMessage,
        pageTypesById: state.pageTypes.byId
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetchPage: (payload) => {
            dispatch(actions.fetchPage(payload))
        },
        updatePage: (payload) => {
            dispatch(actions.updatePage(payload))
        },
        changePagesState: (payload) => {
            dispatch(actions.changePagesState(payload))
        },
        fetchPageTypes: (payload) => {
            dispatch(actions.fetchPageTypes(payload))
        }
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageDetailsHOC));