import * as ActionTypes from './actionTypes';
const signalRConnect = (token) => {
  return {
    type: ActionTypes.SIGNALR_CONNECT,
    payload: token,
  }
};

const signalRDisconnect = (token) => {
  return {
    type: ActionTypes.SIGNALR_DISCONNECT,
    payload: token,
  }
};

export {
  signalRConnect,
  signalRDisconnect
};
