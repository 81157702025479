import React, {Component} from 'react';
import {connect} from "react-redux";
import * as actions from "../../actions";
import AdminInfoForm from "../../components/AdminInfo/AdminInfoForm";

class AdminInfoModalContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
    }

    onResponseMessageDismiss = () => {
        this.props.changeUserState({key: 'updateResponseMessage', value: null})
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(
            prevProps.updatingAdminInfo && !this.props.updatingAdminInfo
            && !this.props.updateResponseMessage
        ){
            this.props.onCancel();
        }

    }

    render() {
        return (
            <AdminInfoForm
                {...this.props}
                onCancel={this.props.onCancel}
                responseMessage={this.props.updateResponseMessage}
                onResponseMessageDismiss={this.onResponseMessageDismiss}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        updatingAdminInfo: state.user.updatingCurrentUserInfo,
        updateResponseMessage: state.user.updateResponseMessage
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateAdminInfo: (payload) => {
            dispatch(actions.updateCurrentUserInfo(payload))
        },
        changeUserState: (payload) => {
            dispatch(actions.changeUserState(payload))
        }
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminInfoModalContainer);