import React from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import _ from "lodash";
import {makeStyles, withStyles} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';

const StyledTableCell = withStyles(theme => ({
    head: {

    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 700,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

const TableList = (props) => {
    const {
        data,
        columns,
        actions,
        order,
        orderBy,
        sortable,
        onSort,
        selectable,
        selected,
        onSelectAllClick,
        onSelectRowClick
    } = props;
    const hasActions = !!actions;
    const classes = useStyles();

    return (
        <Table className={classes.table}>
            <TableHead>
                <TableRow>
                    {selectable && (
                        <TableCell padding="checkbox">
                            <Checkbox
                                checked={selected.length === data.length}
                                onChange={onSelectAllClick}
                                inputProps={{ 'aria-label': 'select all desserts' }}
                            />
                        </TableCell>
                    )}
                    {hasActions && (
                        <StyledTableCell>
                            {"Actions"}
                        </StyledTableCell>
                    )}
                    {columns.map((column, index) => (
                        <StyledTableCell
                            key={index}
                            sortDirection={(orderBy && (orderBy === column.id ))? order : false}
                        >
                            <TableSortLabel
                                hideSortIcon={!sortable || !column.id}
                                active={(orderBy &&  (orderBy === column.id))}
                                direction={order}
                                onClick={onSort && column.id ? () => onSort(column.id) : null}
                            >
                                {column.header}
                                {(orderBy && (orderBy === column.id) ) && (
                                    <span className={classes.visuallyHidden}>
                                         {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                     </span>
                                )}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((datum, index) => {
                    const isItemSelected = selectable ? selected.includes(index) :  false;
                    return (
                        <StyledTableRow
                            hover
                            key={index}
                        >
                            {selectable && (
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={isItemSelected}
                                        onClick={() => onSelectRowClick(index)}
                                    />
                                </TableCell>
                            )}
                            {hasActions && (
                                <StyledTableCell>
                                    {
                                        actions.map((action, index) => {
                                            if(action.shouldDisplay && !action.shouldDisplay(datum)){
                                                return null;
                                            }

                                            return (
                                                <IconButton key={index} onClick={() => action.callback(datum)}>
                                                    {action.icon}
                                                </IconButton>
                                            )
                                        })
                                    }
                                </StyledTableCell>
                            )}
                            {
                                columns.map((column, index) => (
                                    <StyledTableCell key={index}>
                                        {
                                            column.formatter ?
                                                column.formatter(datum)
                                                :
                                                _.get(datum, column.propertyName)
                                        }
                                    </StyledTableCell>
                                ))
                            }
                        </StyledTableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
};

export default TableList;