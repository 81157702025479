import * as actionTypes from './types';
import {actionCreator} from "../shared/utility";

// feelings
export const fetchFeelings = actionCreator(actionTypes.FETCH_FEELINGS);
export const storeFeelings = actionCreator(actionTypes.STORE_FEELINGS);
export const fetchFeelingsFailure = actionCreator(actionTypes.FETCH_FEELINGS_FAILURE);

// ages
export const fetchAges = actionCreator(actionTypes.FETCH_AGES);
export const storeAges = actionCreator(actionTypes.STORE_AGES);
export const fetchAgesFailure = actionCreator(actionTypes.FETCH_AGES_FAILURE);