import * as actionTypes from '../actions/types';
import { updateObject } from "../shared/utility";
import _ from "lodash";
import {DEFAULT_LIST_SIZE} from "../helpers/constants";
import {parseError} from "../helpers/functions";

const initialState = {
    byId: {},
    allIds:[],
    loading: false,
    totalRecords: 0,
    page: 0,
    rowsPerPage: DEFAULT_LIST_SIZE,
    fetchedPage: {},
    fetchingPage: false,
    posting: false,
    updating: false,
    responseMessage: null,
    order: 'asc',
    orderBy: 'Title',
    deleting: false,
    deleteResponseMessage: null
};

const fetchPages = (state, action) => {
    return updateObject(state, {loading: true});
};

const fetchPagesSuccess = (state, action) => {
    try{
        const allIds = action.payload.body.results.map(e => e.id);
        const byId = _.keyBy(action.payload.body.results, "id");
        return updateObject(state, {
            loading: false,
            allIds,
            byId,
            totalRecords: action.payload.body.totalCount
        });
    } catch (e) {
        return updateObject(state, {loading: false});
    }
};

const fetchPagesFailed = (state, action) => {
    return updateObject(state, {loading: false});
};

const changePagesState = (state, action) => {
    return updateObject(state, {[action.payload.key]: action.payload.value});
};

const fetchPage = (state, action) => {
    return updateObject(state, {fetchingPage: true});
};

const fetchPageSuccess = (state, action) => {
    try {
        return updateObject(state, {
            fetchingPage: false,
            fetchedPage: action.payload.body.result
        });
    } catch (e) {
        return updateObject(state, {
            fetchedPage: false
        });
    }
};

const fetchPageFailed = (state, action) => {
    return updateObject(state, {fetchingPage: false});
};

const createPage = (state, action) => {
    return updateObject(state, {posting: true});
};


const createPageSuccess = (state, action) => {
    return updateObject(state, {posting: false});
};

const createPageFailed = (state, action) => {
    let error = "";
    try{
        error = parseError(action.payload);
    } catch (e) {
       error = "An error occurred"
    }
    return updateObject(state, {
        posting: false,
        responseMessage: error
    });
};

const updatePage = (state, action) => {
    return updateObject(state, {updating: true});
};

const updatePageSuccess = (state, action) => {
    return updateObject(state, {
        updating: false,
        responseMessage: "Updated Successfully"
    });
};

const updatePageFailed = (state, action) => {
    try{
        return updateObject(state, {
            updating: false,
            responseMessage: parseError(action.payload)
        });
    } catch (e) {
        return updateObject(state, {updating: false});
    }
};

const deletePage = (state, action) => {
    return updateObject(state, {deleting: true});
};

const deletePageSuccess = (state, action) => {
    return updateObject(state, {deleting: false,});
};

const deletePageFailed = (state, action) => {
    let message = '';
    try {
        message = parseError(action.payload);
        if(!message){
            message = 'Failed to delete page.'
        }
    } catch (e) {
        message = 'Failed to delete page.'
    }
    return updateObject(state, {
        deleting: false,
        deleteResponseMessage: message
    });
};

export const pages = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PAGES: return fetchPages(state,action);
        case actionTypes.FETCH_PAGES_SUCCESS: return fetchPagesSuccess(state,action);
        case actionTypes.FETCH_PAGES_FAILED: return fetchPagesFailed(state,action);
        case actionTypes.CHANGE_PAGES_STATE: return changePagesState(state,action);
        case actionTypes.FETCH_PAGE: return fetchPage(state,action);
        case actionTypes.FETCH_PAGE_SUCCESS: return fetchPageSuccess(state,action);
        case actionTypes.FETCH_PAGE_FAILED: return fetchPageFailed(state,action);
        case actionTypes.CREATE_PAGE: return createPage(state,action);
        case actionTypes.CREATE_PAGE_SUCCESS: return createPageSuccess(state,action);
        case actionTypes.CREATE_PAGE_FAILED: return createPageFailed(state,action);
        case actionTypes.UPDATE_PAGE: return updatePage(state,action);
        case actionTypes.UPDATE_PAGE_SUCCESS: return updatePageSuccess(state,action);
        case actionTypes.UPDATE_PAGE_FAILED: return updatePageFailed(state,action);

        case actionTypes.DELETE_PAGE: return deletePage(state,action);
        case actionTypes.DELETE_PAGE_SUCCESS: return deletePageSuccess(state,action);
        case actionTypes.DELETE_PAGE_FAILED: return deletePageFailed(state,action);
        default:
            return state;

    }
};
