import React from 'react';
import {Route, Switch} from "react-router";
import InfoHOC from "../containers/InfoHOC/InfoHOC";
import InfoActiveStatsHOC from "../containers/InfoActiveStatsHOC/InfoActiveStatsHOC";

const SidebarRouter = (props) => {

    return (
        <Switch>
            <Route path='/chat/:chatId' render={() => <InfoActiveStatsHOC userInfo={{}}/>}/>
            <Route path="/"  render={InfoHOC}/>
        </Switch>
    );

};

export default SidebarRouter;
