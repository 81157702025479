import React, {useEffect, useState} from "react";
import { makeStyles} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import MySnackbarContentWrapper from "../SnackbarContentWrapper/snackbarContentWrapper";
import Snackbar from "@material-ui/core/Snackbar";
import {colors} from "../../helpers/colors";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles(theme => ({
    form: {
        display: "flex",
        flexDirection: "column",
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {},
    formControlSelect: {
        margin: theme.spacing(2),
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    buttonPrimary: {
        margin: theme.spacing(1),
        backgroundColor: colors.BLUE,
        "&:hover": {
            backgroundColor: colors.BLUE,
        }
    },
    buttonSecondary: {
        margin: theme.spacing(1),
        backgroundColor: colors.ORANGE,
        "&:hover": {
            backgroundColor: colors.ORANGE,
        }
    }
}));

const ApplicationUserDetailsForm = (props) => {
    const {
        createApplicationUser,
        roles,
        loading,
        updateApplicationUser,
        initialDetails,
        isEditing,
        onCancel,
        responseMessage,
        onResponseMessageDismiss
    } = props;
    const classes = useStyles();
    const [details, setDetails] = useState({
        id: "",
        userName: "",
        email: "",
        firstname: "",
        lastname: "",
        password: "",
        roleId: "",
    });
    const [hasError, setHasError] = useState({});

    useEffect(()=>{
        setDetails({
            id:  initialDetails && initialDetails.id ? initialDetails.id : "",
            userName: initialDetails && initialDetails.userName ? initialDetails.userName : "",
            email: initialDetails && initialDetails.email ? initialDetails.email : "",
            firstname: initialDetails && initialDetails.firstname ? initialDetails.firstname : "",
            lastname: initialDetails && initialDetails.lastname ? initialDetails.lastname : "",
            password: "",
            roleId: initialDetails && initialDetails.roles && initialDetails.roles.length > 0 ?
                initialDetails.roles[0].id : "",
        })
    }, [initialDetails]);

    const onSubmit = (evt) => {
        evt.preventDefault();
        if(!details.roleId){
            setHasError({...hasError, ['role']: true});
            return;
        }

        if(isEditing){
            let sendBody = {
                firstname: details.firstname,
                lastname: details.lastname
            };
            if(details.password){
                sendBody.password = details.password;
            }

            updateApplicationUser({sendBody, applicationUserId: details.id});
        } else {
            const sendBody = {
                userName: details.userName,
                email: details.email,
                firstname: details.firstname,
                lastname: details.lastname,
                password: details.password,
                roleId: details.roleId,
            };
            createApplicationUser({sendBody});
        }

    };

    return (
        <form className={classes.form} id={"userDetails"} onSubmit={onSubmit}>
            <TextField
                required
                disabled={isEditing}
                label="Username"
                fullWidth
                placeholder="User Name"
                className={classes.textField}
                margin="normal"
                value={details.userName}
                onChange={(evt) => setDetails({...details, "userName": evt.target.value})}
            />
            <TextField
                required
                disabled={isEditing}
                label="Email"
                fullWidth
                placeholder="Email"
                className={classes.textField}
                margin="normal"
                value={details.email}
                onChange={(evt) => setDetails({...details, "email": evt.target.value})}
            />
            <TextField
                label="First Name"
                fullWidth
                placeholder="First Name"
                className={classes.textField}
                margin="normal"
                value={details.firstname}
                onChange={(evt) => setDetails({...details, "firstname": evt.target.value})}
            />
            <TextField
                label="Last Name"
                fullWidth
                placeholder="Last Name"
                className={classes.textField}
                margin="normal"
                value={details.lastname}
                onChange={(evt) => setDetails({...details, "lastname": evt.target.value})}
            />
            <TextField
                required={!isEditing}
                label="Password"
                fullWidth
                placeholder="Password"
                className={classes.textField}
                margin="normal"
                type={"password"}
                value={details.password}
                onChange={(evt) => setDetails({...details, "password": evt.target.value})}
            />
            <FormControl
                error={!!hasError['role']}
                disabled={isEditing}
                fullwidth="true"
                margin={"normal"}
                required
            >
                <InputLabel htmlFor="select-multiple-chip">Role</InputLabel>
                <Select
                    value={details.roleId}
                    onChange={(evt) => {
                        setHasError({});
                        setDetails({...details, roleId: evt.target.value})}
                    }
                >
                    {roles && roles.filter(e => e.code !== "child").map(role => (
                        <MenuItem key={role.id} value={role.id}>
                            {role.name}
                        </MenuItem>

                    ))}
                </Select>
                {hasError['role'] && <FormHelperText>Role is required!</FormHelperText>}
            </FormControl>
            <div>
                <Button
                    form={"userDetails"}
                    color="primary"
                    type={"submit"}
                    variant="contained"
                    className={classes.buttonPrimary}
                >
                    {isEditing? "Save Changes" : "Create"}
                </Button>
                <Button onClick={onCancel} color="secondary" variant="contained" className={classes.buttonSecondary}>
                    {isEditing? "Go back" : "Cancel"}
                </Button>
                {loading && (
                    <CircularProgress className='spinner' size={30}/>
                )}
            </div>
            {responseMessage && (
                <Snackbar
                    open={true}
                    autoHideDuration={6000}
                    onClose={onResponseMessageDismiss}
                >
                    <MySnackbarContentWrapper
                        onClose={onResponseMessageDismiss}
                        variant="info"
                        message={responseMessage}
                    />
                </Snackbar>
            )}
        </form>
    )
};

export default ApplicationUserDetailsForm;
