import React, {Component} from 'react';
import './AvatarInitials.css';
import Avatar from "@material-ui/core/Avatar";

class AvatarInitials extends Component{

    getInitials() {
        const name = this.props.name;
        const maxInitials = this.props.maxInitials;
        const parts = name.split(' ');
        let initials = '';
        for (let i = 0; i < parts.length; i++) {
            initials += parts[i].substr(0, 1).toUpperCase();
        }
        return maxInitials ? initials.slice(0, maxInitials) : initials;
    }

    render() {
        return (
            <Avatar className='avatarInitials' style={{backgroundColor:this.props.bgColor}}>
               {this.getInitials()}
            </Avatar>
        )

    }
}

export default AvatarInitials;