import React, {Component} from 'react';
import './ChatsHistoryNotes.css';
import _ from "lodash";
import ChatHistoryNote from "./ChatHistoryNote/ChatHistoryNote";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import Close from '@material-ui/icons/Close';
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import {Box} from "@material-ui/core";

class ChatsHistoryNotes extends Component{
    constructor(props) {
        super(props);
        this.state = { drawerVisibility: false };
    }

    handleDrawerVisibility= () => {
      this.setState({drawerVisibility : !this.state.drawerVisibility })
    };

    render() {
        const {chats, chatId} = this.props;
        return(
            <div className='historyNotes'>
                    <Typography className='titleHistoryNotes' variant="caption" onClick={this.handleDrawerVisibility}>
                    Σημειώσεις Chat: {moment(chats[chatId].createdDate).format('DD/MM/YY, HH:mm')}
                    </Typography>
                <Drawer
                    anchor="right"
                    open={this.state.drawerVisibility}
                    onClose={this.handleDrawerVisibility}
                    className="drawerNotes"
                >
                    <div className='drawerHeader'>
                        <Box display="flex" alignItems="center">
                            <Box p={2} flexGrow={1}>
                                <Typography variant="h5" className='titleDrawer'>
                                    Chat Notes, {moment(chats[chatId].createdDate).format('DD/MM/YY')}
                                </Typography>
                            </Box>
                            <Box p={1}>
                                <IconButton color="secondary" aria-label="close" onClick={this.handleDrawerVisibility}>
                                    <Close />
                                </IconButton>
                            </Box>
                        </Box>
                    </div>
                    <div className='singleChatHistoryNotes'>
                        {_.orderBy(this.props.notes, 'createdDate', 'asc').map((note,index) => (
                            <ChatHistoryNote
                                key={note.id}
                                index={index+1}
                                createdDate={note.createdDate}
                                text={note.text}
                            />))}
                    </div>
                </Drawer>
            </div>

        )
    }
}

export default ChatsHistoryNotes;
