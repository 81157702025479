import React, {Component} from 'react';
import {connect} from "react-redux";
import * as actions from '../../actions';
import ApplicationUsers from "../../components/ApplicationUsers/ApplicationUsers";

class ApplicationUsersHOC extends Component{
    constructor(props){
        super(props);
        this.state = {
            isModalOpen: false,
            isDeleteModalOpen: false,
            selectedUser: {}
        }
    }

    componentDidMount() {

        this.props.fetchApplicationUsers({
            page: this.props.page,
            limit: this.props.rowsPerPage,
            sort: `${this.props.orderBy}:${this.props.order}`
        });
        this.props.fetchRoles();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(
            prevProps.page !== this.props.page
            || prevProps.rowsPerPage !== this.props.rowsPerPage
            || prevProps.orderBy !== this.props.orderBy
            || prevProps.order !== this.props.order
        ) {
            this.props.fetchApplicationUsers({
                page: this.props.page,
                limit: this.props.rowsPerPage,
                sort: `${this.props.orderBy}:${this.props.order}`
            });
        }

        //to be executed after user update
        if(
            prevProps.posting && !this.props.posting
            && !this.props.responseMessage
        ){
            this.setIsModalOpen(false);
            this.props.fetchApplicationUsers({
                page: this.props.page,
                limit: this.props.rowsPerPage,
                sort: `${this.props.orderBy}:${this.props.order}`
            });
        }

        //to be executed after user delete
        if(
            prevProps.deleting && !this.props.deleting
            && !this.props.deleteResponseMessage
        ){
            this.setIsDeleteModalOpen(false);
            this.props.fetchApplicationUsers({
                page: this.props.page,
                limit: this.props.rowsPerPage,
                sort: `${this.props.orderBy}:${this.props.order}`
            });
        }

    }

    setIsDeleteModalOpen = (isOpen, applicationUser) => {
        this.setState({
            ...this.state,
            isDeleteModalOpen: isOpen,
            selectedUser: applicationUser && isOpen ? applicationUser : {}
        });
    };


    setIsModalOpen = (isOpen) => {
        this.setState({...this.state, isModalOpen: isOpen});
    };


    onChangePage = (evt, page) => {
        this.props.changeApplicationUsersState({key: "page", value: page})
    };

    onChangeRowsPerPage = (evt) => {
        this.props.changeApplicationUsersState({key: "page", value: 0});
        this.props.changeApplicationUsersState({key: "rowsPerPage", value: evt.target.value})
    };

    onMessageResponseDismiss = () => {
        this.props.changeApplicationUsersState({key: "responseMessage", value: null});
    };

    onSort = (columnId) =>{
        this.props.changeApplicationUsersState({key: "orderBy", value: columnId});
        if(columnId === this.props.orderBy){
            const order = this.props.order === 'desc' ? 'asc' : 'desc';
            this.props.changeApplicationUsersState({key: "order", value: order});
        } else {
            this.props.changeApplicationUsersState({key: "order", value: "desc"});
        }
    };

    onDeleteResponseMessageDismiss = () => {
        this.props.changeApplicationUsersState({key: "deleteResponseMessage", value: null});
    };

    render() {
        return(
            <ApplicationUsers
                {...this.props}
                onSort={this.onSort}
                onChangePage={this.onChangePage}
                onChangeRowsPerPage={this.onChangeRowsPerPage}
                setIsModalOpen={this.setIsModalOpen}
                isModalOpen={this.state.isModalOpen}
                onMessageResponseDismiss={this.onMessageResponseDismiss}
                setIsDeleteModalOpen={this.setIsDeleteModalOpen}
                isDeleteModalOpen={this.state.isDeleteModalOpen}
                onDeleteResponseMessageDismiss={this.onDeleteResponseMessageDismiss}
                selectedUser={this.state.selectedUser}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        applicationUsersById: state.applicationUsers.byId,
        loadingApplicationUsers: state.applicationUsers.loading,
        responseMessage: state.applicationUsers.responseMessage,
        rolesById: state.roles.byId,
        page: state.applicationUsers.page,
        totalRecords: state.applicationUsers.totalRecords,
        rowsPerPage: state.applicationUsers.rowsPerPage,
        posting: state.applicationUsers.posting,
        orderBy: state.applicationUsers.orderBy,
        order: state.applicationUsers.order,
        deleting: state.applicationUsers.deleting,
        deleteResponseMessage: state.applicationUsers.deleteResponseMessage,
        currentUserInfo: state.user.info
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetchApplicationUsers: (payload) => {
            dispatch(actions.fetchApplicationUsers(payload))
        },
        changeApplicationUsersState: (payload) => {
            dispatch(actions.changeApplicationUsersState(payload))
        },
        createApplicationUser: (payload) => {
            dispatch(actions.createApplicationUser(payload))
        },
        fetchRoles: (payload) => {
            dispatch(actions.fetchRoles(payload))
        },
        deleteApplicationUser: (payload) => {
            dispatch(actions.deleteApplicationUser(payload))
        }
    }
};
export default connect(mapStateToProps,mapDispatchToProps)(ApplicationUsersHOC);