import {headersWithTokenJson} from './headers';
import xs from 'xstream';
import {BASE_URL} from "../helpers/constants";
import * as actions from "../actions";
import sampleCombine from 'xstream/extra/sampleCombine';
import * as actionTypes from "../actions/types";

// FEELINGS
export function fetchFeelings(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.user.token);
    const request$ = sources.ACTION
        .filter(action => action.type === actionTypes.FETCH_FEELINGS)
        .compose(sampleCombine(token$))
        .map(([action, token]) =>  ({
                url: BASE_URL + 'feelings',
                category: 'fetchFeelings',
                method: 'GET',
                headers: headersWithTokenJson(token),
                withCredentials: true
            })
        );

    let httpResponse$ = sources.HTTP
        .select('fetchFeelings')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.storeFeelings(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    };
}

export function fetchFeelingsFailed(sources) {
    const response$ = sources.HTTP
        .select('fetchFeelings')
        .map(response =>
            response.replaceError(err => xs.of(err)))
        .flatten()
        .filter(response => response.status !== 200);

    const action$ = xs.combine(response$)
        .map(response => actions.fetchFeelingsFailure(response));

    return {
        ACTION: action$
    };
}

// AGES
export function fetchAges(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.user.token);
    const request$ = sources.ACTION
        .filter(action => action.type === actionTypes.FETCH_AGES)
        .compose(sampleCombine(token$))
        .map(([action, token]) =>  ({
                url: BASE_URL + 'ages',
                category: 'fetchAges',
                method: 'GET',
                headers: headersWithTokenJson(token),
                withCredentials: true
            })
        );

    let httpResponse$ = sources.HTTP
        .select('fetchAges')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.storeAges(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    };
}

export function fetchAgesFailed(sources) {
    const response$ = sources.HTTP
        .select('fetchAges')
        .map(response =>
            response.replaceError(err => xs.of(err)))
        .flatten()
        .filter(response => response.status !== 200);

    const action$ = xs.combine(response$)
        .map(response => actions.fetchAgesFailure(response));

    return {
        ACTION: action$
    };
}
