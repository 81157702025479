import React, {Component} from 'react';
import {connect} from "react-redux";
import * as actions from '../../actions';
import ApplicationUserDetails from "../../components/ApplicationUserDetails/ApplicationUserDetails";
import {withRouter} from "react-router";

class ApplicationUserDetailsHOC extends Component{

    componentDidMount() {
        const id = this.props.match.params.id;
        this.props.fetchApplicationUser({applicationUserId: id});
        this.props.fetchRoles();
    }


    onMessageResponseDismiss = () => {
        this.props.changeApplicationUsersState({key: "responseMessage", value: null});
    };

    render() {
        return(
            <ApplicationUserDetails
                {...this.props}
                onMessageResponseDismiss={this.onMessageResponseDismiss}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        applicationUser: state.applicationUsers.fetchedUser,
        responseMessage: state.applicationUsers.responseMessage,
        updating: state.applicationUsers.updating,
        rolesById: state.roles.byId,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetchApplicationUser: (payload) => {
            dispatch(actions.fetchApplicationUser(payload))
        },
        changeApplicationUsersState: (payload) => {
            dispatch(actions.changeApplicationUsersState(payload))
        },
        fetchRoles: (payload) => {
            dispatch(actions.fetchRoles(payload))
        },
        updateApplicationUser: (payload) => {
            dispatch(actions.updateApplicationUser(payload))
        }
    }
};
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(ApplicationUserDetailsHOC));