import React from "react";
import ApplicationUserDetailsForm from "./ApplicationUserDetailsForm";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
    }),
}));

const ApplicationUserDetails = (props) => {
    const {
        applicationUser,
        rolesById,
        history,
        updateApplicationUser,
        updating,
        onMessageResponseDismiss,
        responseMessage
    } = props;
    const roles = _.map(rolesById, e => e);
    const classes = useStyles();

    return (
        <Grid
            container spacing={1}
            direction="row"
        >
            <Grid item md={4} sm={12}>
                <Paper className={classes.root} elevation={4}>
                    <ApplicationUserDetailsForm
                        onResponseMessageDismiss={onMessageResponseDismiss}
                        responseMessage={responseMessage}
                        loading={updating}
                        updateApplicationUser={updateApplicationUser}
                        isEditing
                        initialDetails={applicationUser}
                        roles={roles}
                        onCancel={() => history.goBack()}
                    />
                </Paper>
            </Grid>

        </Grid>
    )
};

export default ApplicationUserDetails;