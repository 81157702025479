import parseChat from "./parseChat";

const parseChats = (stateChats, stateChatsIds, stateActiveChatsIds, stateHistoryChatsIds, statePendingChatsIds,  data, status) =>{
    let newChats = stateChats || {};
    let chatsIds = stateChatsIds.slice();
    let activeChatsIds = stateActiveChatsIds.slice();
    let historyChatsIds = stateHistoryChatsIds.slice();
    let pendingChatsIds = statePendingChatsIds.slice();
    data.forEach(item => {
        if (!item) return;
        newChats = {
            ...newChats,
            ...parseChat(newChats, item),
        };
        if (!chatsIds.includes(item.id)) {
            chatsIds.push(item.id);
        }

        //active chat list
        if (status === "active"  && !activeChatsIds.includes(item.id)) {
            activeChatsIds.push(item.id);
        }
        //history chat list
        if (status === "history"  && !historyChatsIds.includes(item.id)) {
            historyChatsIds.push(item.id);
        }
        //waiting chat list
        if (status === "pending"  && !pendingChatsIds.includes(item.id)) {
            pendingChatsIds.push(item.id);
        }
    });

    return{
        newChats,
        chatsIds,
        activeChatsIds,
        historyChatsIds,
        pendingChatsIds
    }
};

export default parseChats;
