import React, {Component} from 'react';
import moment from 'moment';

import Typography from "@material-ui/core/Typography";
import {Grid} from "@material-ui/core";

class TotalChatDuration extends Component{
    render() {
        return (
            <div>
                <Typography variant='body2' component='div' className='statsTitle'>
                    {this.props.title}
                </Typography>
                <Grid spacing={1} container>
                    <Grid item xs>
                        <Typography variant='h3' component='div' className='coloredValue'>
                            {/*{this.props.hours}h {this.props.minutes}m*/}
                            {moment.duration({"seconds": this.props.time}).humanize()}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        )
    }
};

export default TotalChatDuration;