import React from "react"
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import FaceIcon from '@material-ui/icons/Face';
import ListItemText from "@material-ui/core/ListItemText";
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';
import PagesIcon from '@material-ui/icons/ChromeReaderMode';

const useStyles = makeStyles(theme => ({
    listDivider: {
        height: "calc(100vh - 64px)",
    },
    menuList: {
        height: "inherit"
    },
    link: {
        textDecoration: "none",
        color: "inherit"
    },
    configuration: {
        position: "absolute",
        bottom: 10
    }
}));


const ListDivider = (props) => {
    const {variant, onClose} = props;
    const classes = useStyles();

    const onLinkClick = () => variant === "mobile" ? onClose() : null;
    return (
        <div className={classes.listDivider}>
            <List className={classes.menuList}>
                <NavLink className={classes.link} to="/users" onClick={onLinkClick}>
                    <ListItem button>
                        <ListItemIcon>
                            <FaceIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Users"/>
                    </ListItem>
                </NavLink>
                <NavLink className={classes.link} to="/pages" onClick={onLinkClick}>
                    <ListItem button>
                        <ListItemIcon>
                            <PagesIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Pages"/>
                    </ListItem>
                </NavLink>
            </List>
        </div>

    )

};

export default ListDivider;


ListDivider.propTypes = {
    dividerList: PropTypes.array,
    noDividerList: PropTypes.array,
};
