import React, { Component } from 'react';
import './StatusToggleButton.css';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import GreenSwitchAgent from '../GreenSwitch/GreenSwitch';
class StatusToggleButton extends Component {
    handleChange = () => {
        this.props.onOnlineToggle({isAvailable: !this.props.isAvailable})
    };
    render() {
        return(
            <FormControlLabel
                className='toggleBtn'
                control={
                    <GreenSwitchAgent
                        checked={this.props.isAvailable}
                        onChange={this.handleChange}
                        value={this.props.isAvailable}
                    />
                  }
                labelPlacement="end"
                label={this.props.isAvailable ? this.props.labelOn : this.props.labelOff}
            />
        );
    }
}


export default StatusToggleButton;

