import React from "react";
import {countTotalUnreadChatsOfChatList} from "../../helpers/chats";
import Badge from "@material-ui/core/Badge";
import {withStyles} from "@material-ui/core";

const StyledBadge = withStyles(theme => ({
    badge: {
        top: '22%',
        right: -5,
    },
}))(Badge);

const TabLabelContent = (props) => {
    const {chatListIds, unreadChatsIds, label} = props;
    const totalUnread = countTotalUnreadChatsOfChatList(chatListIds, unreadChatsIds);
    return (
        <StyledBadge
            color="error"
            badgeContent={totalUnread}
        >
            {label}
        </StyledBadge>
    )
};

export default TabLabelContent;