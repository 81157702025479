import React from "react";
import {makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PageDetailsForm from "./PageDetailsForm";
import {withRouter} from "react-router";
import _ from "lodash";

const useStyles = makeStyles(theme => ({
    root: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
    }),
}));

const PageDetails = (props) => {
    const {
        history,
        updatePage,
        updating,
        onMessageResponseDismiss,
        responseMessage,
        fetchedPage,
        pageTypesById
    } = props;
    const classes = useStyles();
    const pageTypes = _.map(pageTypesById, e => e);

    return (
        <Grid
            container spacing={1}
            direction="row"
        >

            <Grid item  sm={12}>
                <Paper className={classes.root} elevation={4}>
                    <PageDetailsForm
                        onResponseMessageDismiss={onMessageResponseDismiss}
                        responseMessage={responseMessage}
                        loading={updating}
                        updatePage={updatePage}
                        isEditing
                        initialDetails={fetchedPage}
                        onCancel={() => history.goBack()}
                        pageTypes={pageTypes}
                    />
                </Paper>
            </Grid>

        </Grid>
    )
};

export default withRouter(PageDetails);
