import React from "react"
import {Drawer} from "@material-ui/core";
import clsx from 'clsx';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {DRAWER_WIDTH} from "../../helpers/constants";
import ListDivider from "../listDivider/listDivider";

const useStyles = makeStyles(theme => ({
    drawer: {
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: DRAWER_WIDTH,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
}));


const SidebarMenu = (props) => {
    const {isMenuOpen, sidebarHandler} = props;
    const classes = useStyles();
    const theme = useTheme();
    const drawerVariant = "permanent";

    return (
        <React.Fragment>
            {
                <Drawer
                    variant={drawerVariant}
                    open={isMenuOpen}
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: isMenuOpen,
                        [classes.drawerClose]: !isMenuOpen,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: isMenuOpen,
                            [classes.drawerClose]: !isMenuOpen,
                        }),
                    }}
                >
                    <div className={classes.toolbar}>
                        <IconButton onClick={()=>sidebarHandler()}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                        </IconButton>
                    </div>
                    <ListDivider />
                </Drawer>
            }
        </React.Fragment>
    );
};

export default SidebarMenu
