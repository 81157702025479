import {headersWithTokenJson} from './headers';
import xs from 'xstream';
import {BASE_URL} from "../helpers/constants";
import * as actions from "../actions";
import sampleCombine from 'xstream/extra/sampleCombine';
import * as actionTypes from "../actions/types";

export function sendMessage(sources) {
  const state$ = sources.STATE;
  const token$ = state$.map(state => state.user.token);
  const request$ = sources.ACTION
    .filter(action => action.type === actionTypes.SEND_MESSAGE)
    .compose(sampleCombine(token$))
    .map(([action, token]) =>  ({
        url: BASE_URL + 'messages',
        category: 'sendMessage',
        method: 'POST',
        send: {
          chatId: action.payload.chatId,
          body: action.payload.body,
        },
        headers: headersWithTokenJson(token),
        withCredentials: true
      })
    );

  let httpResponse$ = sources.HTTP
    .select('sendMessage')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 201)
    .map(response => actions.sendMessageSuccess(response));

  return {
    ACTION: httpResponse$,
    HTTP: request$
  };
}

export function sendMessageFailure(sources) {
  const response$ = sources.HTTP
    .select('sendMessage')
    .map(response =>
      response.replaceError(err => xs.of(err)))
    .flatten()
    .filter(response => response.status !== 201);

  const action$ = xs.combine(response$)
    .map(response => actions.sendMessageFailure(response));

  return {
    ACTION: action$
  };
}
