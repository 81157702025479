import moment from "moment";

const parseChat = (chats, item) =>{
  return {
    [item.id]: {
      ...chats[item.id], ...{
        id: item.id,
        createdDate: item.createdDate,
        expiryTimestamp: moment.utc(item.expiryTimestamp, 'YYYY-MM-DD HH:mm:ss.SS').unix().valueOf(),
        endFeeling: item.endFeeling,
        endTimestamp: item.endTimestamp,
        startFeeling: item.startFeeling,
        startTimestamp: item.startTimestamp,
        subject: item.subject,
        childId: item.createdBy && item.createdBy.id ? item.createdBy.id : null,
        messages: chats[item.id] && chats[item.id].messages ? chats[item.id].messages : item.messages,
        notes: chats[item.id] && chats[item.id].notes ? chats[item.id].notes : item.notes
      },
    },
  }
};

export default parseChat;
