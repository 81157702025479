import React, {Component} from 'react';
import './RightInfoStats.css';

import Typography from "@material-ui/core/Typography";
import InfoBlock from "../../components/InfoBlock/InfoBlock";
import {Container} from "@material-ui/core";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import {connect} from "react-redux";
import AvgTime from "../Statistics/AvgTime/AvgTime";
import TotalChatDuration from "../Statistics/TotalChatDuration/TotalChatDuration";
import * as actions from "../../actions";

class RightInfoStats extends Component {
    componentDidMount(){
        this.props.fetchUserStatistics(this.props.token);
    }

    render() {
        const stats = this.props.stats;
        const chatStats = this.props.chatStats;
        return(
            <div className='agentStats'>
                {/* title */}
                <SectionTitle capitalized centered title={this.props.title}/>

                {/* colored infoBlock */}
                {chatStats ?
                    <InfoBlock styleNumber='h2' number={chatStats.onHold} text='Chat σε αναμονή' coloredNumber/>
                :null }

                {/* text to select chat for details */}
                <Container>
                    <div className='chatInfo'>
                        <Typography variant='caption' component='div'>
                            Παρακαλώ επιλέξτε chat για να δείτε λεπτομέρειες
                        </Typography>
                    </div>
                </Container>

                {/* agentStats */}
                {stats ?
                    <div className='yourStats'>
                        {/*{stats.userTotalChatTime  || stats.userAvgChatTime ?*/}
                            <Container>
                                <SectionTitle capitalized title='Your Stats'/>
                                {stats.totalChatDuration ?
                                    <TotalChatDuration title='Your total chat duration' time={stats.totalChatDuration} />
                                :null}
                                {stats.averageChatTime ?
                                    <AvgTime class="chat" title='Your Avg Chat Time' time={stats.averageChatTime} formatTime='mm' previousTime='05:53'/>
                                :null}
                            </Container>
                        {/*:null}*/}
                    </div>
                :null}
            </div>
        )
    }
};

const mapStateToProps = state => {
    return {
        stats: state.user.stats,
        chatStats: state.statistics.stats,
        token: state.user.token,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetchUserStatistics: (payload) => dispatch(actions.fetchUserStatistics(payload))
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(RightInfoStats);
