import React from 'react';
import './MainGrid.css';

import Grid from "@material-ui/core/Grid";

const MainGrid = (props) => {
    return (
        <div className="mainGridAgent">
            <Grid
                container spacing={0}
                direction="row"
                justify="center"
                alignItems="center"
                className='mainGridContainer'
            >
                <Grid item xs={props.selectedChatId || props.showStats ? 3 : 9 } className='leftContent'>
                    {props.leftContent}
                </Grid>

                {props.selectedChatId || props.showStats  ?
                    <Grid item xs={6} className='centerContent'>
                        {props.centerContent}
                    </Grid>
                :null}

                <Grid item xs={3} className='rightContent'>
                    {props.rightContent}
                </Grid>
            </Grid>
        </div>
    );
};

export default MainGrid;