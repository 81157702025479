import React from "react";
import ChatDetails from "../ChatDetails/ChatDetails";
import ChatNotesHOC from "../../containers/ChatNotesHOC/ChatNotesHOC";
import ReportChats from "../ReportChats/ReportChats";

const ActiveChat = (props) =>{
    // console.log(props);
    return (
        <React.Fragment>
            <ChatDetails {...props}/>
            <ChatNotesHOC {...props} showOldNotes/>
            {props.chatInfo.endTimestamp ?
                <ReportChats {...props} title='Print or Send'/>
            :null}
        </React.Fragment>
    )
};

export default ActiveChat;