import React, {useState} from "react";
import {makeStyles} from "@material-ui/core";
import {colors} from "../../helpers/colors";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Input from "@material-ui/core/Input";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../SnackbarContentWrapper/snackbarContentWrapper";

const useStyles = makeStyles(theme => ({
    form: {
        display: "flex",
        flexDirection: "column",
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {},
    formControlSelect: {
        margin: theme.spacing(2),
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    buttonPrimary: {
        margin: theme.spacing(1),
        backgroundColor: colors.BLUE,
        "&:hover": {
            backgroundColor: colors.BLUE,
        },
        zIndex: 11
    },
    buttonSecondary: {
        margin: theme.spacing(1),
        backgroundColor: colors.ORANGE,
        "&:hover": {
            backgroundColor: colors.ORANGE,
        },
        zIndex: 11
    }
}));

const AdminInfoForm = (props) => {
    const {onCancel, updateAdminInfo, updatingAdminInfo, responseMessage, onResponseMessageDismiss} = props;
    const classes = useStyles();
    const [details, setDetails] = useState({
        oldPassword: '',
        newPassword: '',
        retypeNewPassword: ''
    });
    const [error, setHasError] = useState({});

    const onSubmit = (evt) => {
        evt.preventDefault();
        if(details.newPassword !== details.retypeNewPassword){
            setHasError({...error, ['notSamePassword']: true});
            return;
        }

        updateAdminInfo({
            sendBody: {
                oldPassword: details.oldPassword,
                password: details.newPassword
            }
        })
    };

    return (
        <form className={classes.form} onSubmit={onSubmit} id={"adminInfoDetails"}>
            <TextField
                required
                label="Old password"
                fullWidth
                placeholder="Old password"
                className={classes.textField}
                margin="normal"
                type={"password"}
                value={details.oldPassword}
                onChange={(evt) => setDetails({...details, oldPassword: evt.target.value})}
            />
            <FormControl
                error={!!error['notSamePassword']}
                fullwidth="true"
                margin={"normal"}
                required
            >
                <InputLabel htmlFor="component-helper">New password</InputLabel>
                <Input
                    required
                    label="New password"
                    fullWidth
                    placeholder="New password"
                    className={classes.textField}
                    margin="normal"
                    type={"password"}
                    value={details.newPassword}
                    onChange={(evt) => {
                        setHasError({});
                        setDetails({...details, newPassword: evt.target.value})}
                    }
                />
                {error['notSamePassword'] && <FormHelperText>Passwords does not match</FormHelperText>}

            </FormControl>
            <FormControl
                error={!!error['notSamePassword']}
                fullwidth="true"
                margin={"normal"}
                required
            >
                <InputLabel htmlFor="component-helper">Retype new password</InputLabel>
                <Input
                    required
                    fullWidth
                    placeholder="Retype new password"
                    className={classes.textField}
                    margin="normal"
                    type={"password"}
                    value={details.retypeNewPassword}
                    onChange={(evt) => {
                        setHasError({});
                        setDetails({...details, retypeNewPassword: evt.target.value})
                    }}
                />
                {error['notSamePassword'] && <FormHelperText>Passwords does not match</FormHelperText>}

            </FormControl>
            <div>
                <Button
                    form={"adminInfoDetails"}
                    color="primary"
                    type={"submit"}
                    variant="contained"
                    className={classes.buttonPrimary}
                >
                    {"Save changes"}
                </Button>
                <Button onClick={onCancel} color="secondary" variant="contained" className={classes.buttonSecondary}>
                    {"Cancel"}
                </Button>
                {updatingAdminInfo && (
                    <CircularProgress className='spinner' size={30}/>
                )}
            </div>
            {responseMessage && (
                <Snackbar
                    open={true}
                    autoHideDuration={6000}
                    onClose={onResponseMessageDismiss}
                >
                    <MySnackbarContentWrapper
                        onClose={onResponseMessageDismiss}
                        variant="info"
                        message={responseMessage}
                    />
                </Snackbar>
            )}
        </form>
    )
};

export default AdminInfoForm;